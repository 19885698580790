import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { PageStatus404 } from '@dop-ui/react/entities/page-status';

export function NotFoundErrorTemplate() {
  const { t } = useTranslation('common');
  return (
    <PageStatus404
      className="py-12 mt-8"
      message={t('search.result.error.pages.404.message')}
    />
  );
}

export default NotFoundErrorTemplate;
