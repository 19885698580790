'use client';

import { useRouter } from 'next/navigation';
import { SelectPrimitives } from '../../../shared/ui/select';
import { useSessionContext } from '../../../features/authenticate/ui/client/session-context';
import { getMultiCompanySSOToken } from '../../../features/multicompany-sso-token/api/multicompany-sso-token';

import styles from './styles.module.css';
import { IconChevronDown } from '@tabler/icons-react';

export function MultiCompanySelector() {
  const router = useRouter();
  const { me } = useSessionContext();

  const otherCompanyList =
    me?.integrationInfoList?.filter(
      (info) => info.companyUuid !== me.companyUuid,
    ) ?? [];

  const currentCompany = me?.integrationInfoList?.find(
    (info) => info.companyUuid === me.companyUuid,
  );

  const companyInfoList = currentCompany
    ? [currentCompany, ...otherCompanyList]
    : [...otherCompanyList];

  const handleOnClickCompany = async (companyId: string) => {
    if (!companyInfoList) return;

    const selectedCompany = companyInfoList.find(
      (info) => info.companyId.toString() === companyId,
    );

    if (!selectedCompany) {
      console.error('Failed to get selected company');
      return;
    }
    try {
      const tempToken = await getMultiCompanySSOToken(
        selectedCompany.companyUuid,
      );
      if (!tempToken) {
        console.error('Failed to get multi company sso token');
        return;
      }

      const protocol = window.location.protocol;
      const port = window.location.port;

      router.push(
        `${protocol}//${selectedCompany.siteUrl}${port ? ':' + port : ''}/multi-com-sso?token=${tempToken}`,
      );
    } catch {
      console.error('Failed to get multi company sso token');
    }
  };

  if (companyInfoList && companyInfoList.length > 1) {
    return (
      <SelectPrimitives.Root
        defaultLabel={
          companyInfoList.find(
            (info) => info.companyId.toString() === me?.companyId,
          )?.siteUrl ?? ''
        }
        value={me?.companyId.toString() || ''}
        onChange={(value) => void handleOnClickCompany(value)}
      >
        <SelectPrimitives.Trigger>
          <div className={styles.CompanySelector}>
            <IconChevronDown size={12} />
          </div>
        </SelectPrimitives.Trigger>
        <SelectPrimitives.Content className="z-[10] max-h-[400px] overflow-auto">
          {companyInfoList.map((info) => (
            <SelectPrimitives.Option
              key={info.companyId.toString()}
              value={info.companyId.toString()}
            >
              {info.companyName}
            </SelectPrimitives.Option>
          ))}
        </SelectPrimitives.Content>
      </SelectPrimitives.Root>
    );
  }

  return <></>;
}
