import { SEARCHABLE_APP_CODES } from '../../../../model/constants';
import type { AppSearchResultProps } from '../types';

// 앱별 간단검색 결과 컴포넌트
import { SearchResultMail } from './mail';
import { SearchSummaryContact } from './contact';
import { SearchSummaryCalendar } from './calendar';
import { SearchSummaryApproval } from './approval';
import { SearchSummaryApprCompanyFolder } from './appr-company-folder';
import { SearchResultBoard } from './board';
import { SearchResultCommunity } from './community';
import { SearchSummaryDocs } from './docs';
import { SearchResultReport } from './report';
import { SearchSummaryWorks } from './works';
import { SearchResultTodo } from './todo';
import { SearchSummaryWebFolder } from './webfolder';
import { SearchResultHR } from './hr';
import { SearchResultEContract } from './econtract';
import { SearchResultLegalEducation } from './legal-education';
import { SearchResultExpenses } from './expenses';
import { SearchResultVehicleLog } from './vehicle-log';

export function getSearchResultElement(
  code: string,
): (props: AppSearchResultProps) => React.ReactElement {
  switch (code) {
    case SEARCHABLE_APP_CODES.MAIL:
      return SearchResultMail;
    case SEARCHABLE_APP_CODES.CONTACT:
      return SearchSummaryContact;
    case SEARCHABLE_APP_CODES.CALENDAR:
      return SearchSummaryCalendar;
    case SEARCHABLE_APP_CODES.APPROVAL:
      return SearchSummaryApproval;
    case SEARCHABLE_APP_CODES.APPR_COMPANY_FOLDER:
      return SearchSummaryApprCompanyFolder;
    case SEARCHABLE_APP_CODES.BOARD:
      return SearchResultBoard;
    case SEARCHABLE_APP_CODES.DOCS:
      return SearchSummaryDocs;
    case SEARCHABLE_APP_CODES.COMMUNITY:
      return SearchResultCommunity;
    case SEARCHABLE_APP_CODES.REPORT:
      return SearchResultReport;
    case SEARCHABLE_APP_CODES.WORKS:
      return SearchSummaryWorks;
    case SEARCHABLE_APP_CODES.TODO:
      return SearchResultTodo;
    case SEARCHABLE_APP_CODES.WEBFOLDER:
      return SearchSummaryWebFolder;
    case SEARCHABLE_APP_CODES.HR:
      return SearchResultHR;
    case SEARCHABLE_APP_CODES.ECONTRACT:
      return SearchResultEContract;
    case SEARCHABLE_APP_CODES.LEGAL_EDUCATION:
      return SearchResultLegalEducation;
    case SEARCHABLE_APP_CODES.EXPENSES:
      return SearchResultExpenses;
    case SEARCHABLE_APP_CODES.VEHICLE_LOG:
      return SearchResultVehicleLog;
    default:
      return null;
  }
}
