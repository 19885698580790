import * as React from 'react';
import { formatDate, DateType } from '@dop-ui/react/shared/lib/date';
import { FooterItem } from './footer-item';

export interface Props {
  date: DateType;
  label?: string;
  format?: string;
}

export function FormatDate({
  date,
  label,
  format = 'YYYY-MM-DD(ddd) HH:mm',
}: Props) {
  return (
    <FooterItem>
      {label && <span>{label}</span>}
      <span>{formatDate({ date, format })}</span>
    </FooterItem>
  );
}

export default FormatDate;
