import * as React from 'react';
import { clsx } from 'clsx';
import Image from 'next/image';
import image404 from '../../../../shared/assets/images/404.png';
import styles from './styles.module.css';

export interface Props {
  className?: string;
  message?: string | React.ReactElement;
}

export function PageStatus404({ className, message }: Props) {
  return (
    <div className={clsx(styles.PageStatus404, className)}>
      <Image width={132} height={174} src={image404} alt="404 - Not Found" />
      <p className={styles.message}>{message}</p>
    </div>
  );
}

export default PageStatus404;
