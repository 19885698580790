import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';
import { fetchAppletList } from '@/components/search/api/fetch-applet-list';

interface AppletInfo {
  id: number;
  name: string;
}

export interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export function SelectAppletList({ value = '', onChange }: Props) {
  const { t } = useTranslation('common');
  const [valueState, setValueState] = React.useState<string>(value);
  const { data, isError, isLoading } = useQuery({
    queryKey: ['applet-list'],
    queryFn: async () => fetchAppletList(),
  });

  React.useEffect(() => {
    setValueState(value);
  }, [value]);

  if (isError) return null;
  if (!data) return null;

  const { data: options } = data;

  const defaultLabel = () => {
    if (isLoading) return 'Loading...';

    const deptOptions = options as AppletInfo[];

    const selectedOption = deptOptions.find(
      (item) => item.id === Number(value),
    );

    if (selectedOption) return selectedOption.name;

    return t('search.common.words.ALL');
  };

  const changeHandler = (value: string) => {
    onChange && onChange(value);
    setValueState(value);
  };

  return (
    <SelectPrimitives.Root
      value={valueState}
      defaultLabel={defaultLabel()}
      onChange={changeHandler}
    >
      <SelectPrimitives.Trigger />
      <SelectPrimitives.Content className="z-[999]">
        <SelectPrimitives.Option key={-1} value={''}>
          {t('search.common.words.ALL')}
        </SelectPrimitives.Option>
        {(options as AppletInfo[]).map((item) => (
          <SelectPrimitives.Option key={item.id} value={`${item.id}`}>
            {item.name}
          </SelectPrimitives.Option>
        ))}
      </SelectPrimitives.Content>
    </SelectPrimitives.Root>
  );
}
