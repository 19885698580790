import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectField } from '../../../filter-templates';

export function SelectFuelCostTypeField() {
  const { t } = useTranslation('common');
  const options = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.filter.eacc.field.PURPOSE.option.WORK'),
      value: 'WORK',
    },
    {
      label: t('search.filter.eacc.field.PURPOSE.option.NON_WORK'),
      value: 'NON_WORK',
    },
    {
      label: t('search.filter.eacc.field.PURPOSE.option.COMMUTE'),
      value: 'COMMUTE',
    },
  ];

  return (
    <SelectField.Simple
      label={t('search.filter.eacc.field.PURPOSE.label')}
      name="TYPE"
      options={options}
    />
  );
}

export default SelectFuelCostTypeField;
