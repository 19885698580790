import { useEffect, useState, useDeferredValue } from 'react';
import { type RelativeFormatProps, formatRelativeTime } from './with-locale';

function useRelativeTime({ date, locale }: RelativeFormatProps) {
  const getRelativeTime = async () => {
    return formatRelativeTime({ date, locale });
  };

  const [relativeTime, setRelativeTime] = useState('');

  useEffect(() => {
    const fetchRelativeTime = async () => {
      const time = await getRelativeTime();
      setRelativeTime(time);
    };

    void fetchRelativeTime();
  }, [date]);

  const deferredRelativeTime = useDeferredValue(relativeTime);

  return { relativeTime: deferredRelativeTime };
}
export default useRelativeTime;
