// import { clsx } from 'clsx';
import * as Template from '../../result-templates/primitives';
import { SearchResultMailItem } from './item';
import type { AppSearchResultProps, IMailSearchResultItem } from '../../types';

import styles from './styles.module.css';

export function SearchResultMail({ data, onClickLink }: AppSearchResultProps) {
  console.log('SearchResultMail: ', data);
  const { data: resData, error } = data;

  if (error || !resData) {
    return;
  }

  const { list = [] } = resData;

  return (
    <Template.Container className={styles.SearchResult__Mail}>
      {list.map((item: IMailSearchResultItem) => (
        <SearchResultMailItem
          key={item.id}
          data={item}
          onClickLink={onClickLink}
        />
      ))}
    </Template.Container>
  );
}

export default SearchResultMail;
