import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { IncludedKeywordField } from '../../../filter-templates';

export function HRIncludedKeywordField() {
  const { t } = useTranslation('common');
  const options = [
    {
      value: 'name',
      label: t('search.filter.hr.field.INCLUDED_KEYWORD.option.NAME'),
    },
    {
      value: 'purpose',
      label: t('search.filter.hr.field.INCLUDED_KEYWORD.option.PURPOSE'),
    },
    {
      value: 'remarks',
      label: t('search.filter.hr.field.INCLUDED_KEYWORD.option.REMARKS'),
    },
  ];

  return <IncludedKeywordField.Primitive options={options} />;
}

export default HRIncludedKeywordField;
