import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';

export interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export const SELECT_METHOD = {
  SELECT: 'SELECT',
  INPUT: 'INPUT',
} as const;
export type TSelectMethod = (typeof SELECT_METHOD)[keyof typeof SELECT_METHOD];

export function SelectMethod({ value = '', onChange }: Props) {
  const { t } = useTranslation('common');
  const [valueState, setValueState] = React.useState<string>(value);

  React.useEffect(() => {
    setValueState(value);
  }, [value]);

  const changeHandler = (value: string) => {
    onChange && onChange(value);
    setValueState(value);
  };

  const options = [
    // 앱 선택
    {
      label: t('search.filter.works.field.SELECT_METHOD.option.SELECT'),
      value: SELECT_METHOD.SELECT,
    },
    // 직접 입력
    {
      label: t('search.filter.works.field.SELECT_METHOD.option.INPUT'),
      value: SELECT_METHOD.INPUT,
    },
  ];

  const defaultValue = options.find((item) => item.value === valueState);

  return (
    <SelectPrimitives.Root
      value={valueState}
      defaultLabel={defaultValue.label}
      onChange={changeHandler}
    >
      <SelectPrimitives.Trigger />
      <SelectPrimitives.Content className="z-[999]">
        {options.map((item, idx) => (
          <SelectPrimitives.Option key={idx} value={`${item.value}`}>
            {item.label}
          </SelectPrimitives.Option>
        ))}
      </SelectPrimitives.Content>
    </SelectPrimitives.Root>
  );
}

export default SelectMethod;
