'use client';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { highlightText } from '@dop-ui/react/shared/lib/utils';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import { useSearchKeyword } from '../../../../model/store';
import {
  ApprovalItemTypeTemplate,
  Primitives as TemplatePrimitives,
} from '../result-templates';
import { type StatusTagType } from '../result-templates/primitives/status-tag';
import type { AppSearchResultProps, IEContractSearchResultItem } from '../types';

export function SearchResultEContract({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const [searchKeyword] = useSearchKeyword();
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <TemplatePrimitives.Container>
      {list.map((item: IEContractSearchResultItem) => {
        const status: StatusTagType = convertStatus(item.status);

        return (
          <ApprovalItemTypeTemplate.Container
            key={item.id}
            status={status}
            statusText={t(`search.result.econtract.status.${item.status}`)}
            onClickLink={onClickLink}
          >
            <ApprovalItemTypeTemplate.Title
              href={``}
              title={highlightText(item.name, searchKeyword)}
              onClick={onClickLink}
            />

            <ApprovalItemTypeTemplate.Footer>
              {/* 요청자 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.econtract.requester')}
                value={highlightText(item.requester, searchKeyword)}
              />

              {/* 서명요청일 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.econtract.requestDate')}
                value={formatDate({
                  date: item.requestDate,
                  format: 'YYYY-MM-DD',
                })}
              />

              {/* 서명방법 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.econtract.signType')}
                value={highlightText(item.signType, searchKeyword)}
              />

              {/* 서명요청기한 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.econtract.dueDate')}
                value={formatDate({
                  date: item.dueDate,
                  format: 'YYYY-MM-DD',
                })}
              />

              {item.completedDate && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={
                    item.signType === 'CANCELED'
                      ? t('search.result.econtract.canceledDate')
                      : t('search.result.econtract.completedDate')
                  }
                  value={formatDate({
                    date: item.completedDate,
                    format: 'YYYY-MM-DD',
                  })}
                />
              )}

              {/* 비고 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.econtract.remarks')}
                value={highlightText(item.remarks, searchKeyword)}
              />
            </ApprovalItemTypeTemplate.Footer>
          </ApprovalItemTypeTemplate.Container>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

function convertStatus(status: string): StatusTagType {
  return {
    COMPLETED: 'COMPLETE',
    WAITING: 'DRAFT',
    CANCELED: 'RETURN',
  }[status] as StatusTagType;
}

export default SearchResultEContract;
