import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import { PopoverTriggerProps } from '@radix-ui/react-popover';
import { Trigger as PopoverTrigger } from '../../popover';
import { useSelectContextValue } from './context';

import styles from './style.module.css';

interface Props extends PopoverTriggerProps {
  className?: string;
}

export function Trigger({
  className,
  children,
  ...props
}: PropsWithChildren<Props>) {
  const { disabled, currentLabel } = useSelectContextValue();

  if (children)
    return (
      <PopoverTrigger className={className} disabled={disabled} {...props}>
        {children}
      </PopoverTrigger>
    );

  return (
    <PopoverTrigger
      className={clsx(
        styles.Trigger,
        {
          [`${styles.disabled}`]: disabled,
        },
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {currentLabel} <div className={styles.Spacer} />
      <IconChevronDown className="flex-shrink-0" size={16} />
    </PopoverTrigger>
  );
}
