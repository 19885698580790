import { clsx } from 'clsx';
import styles from './styles.module.css';

export function SearchResultContentLoader() {
  return (
    <div className={styles.SearchResultContentLoader}>
      <div className={styles.title}></div>

      <div className={styles.body}>
        <div className={clsx(styles.paragraph, styles.pNormal)}></div>
        <div className={clsx(styles.paragraph, styles.pEnd)}></div>

        <div className={styles.space} />

        <div className={clsx(styles.paragraph, styles.pNormal)}></div>
        <div className={clsx(styles.paragraph, styles.pNormal)}></div>

        <div className={styles.space} />

        <div className={clsx(styles.paragraph, styles.pNormal)}></div>
        <div className={clsx(styles.paragraph, styles.pEnd)}></div>
      </div>
    </div>
  );
}

export default SearchResultContentLoader;
