// import type { AccessibleAppCodeData } from '@dop-ui/react/features/authenticate';
import { SEARCHABLE_APP_CODES } from './constants';
import * as GroupwareResolver from './resolvers/groupware-resolver';
import * as EaccResolver from './resolvers/eacc-resolvers';
import * as MailResolver from './resolvers/mail-resolver';
import * as ExpensesResolver from './resolvers/expenses-resolver';
import * as WorksResolver from './resolvers/works-resolver';

import type {
  TSearchType,
  TPathResolverFn,
  TSearchableAppType,
  IRequestFilter,
} from './types';

function createPathResolver(path: string, resolver?: TPathResolverFn) {
  return (type: TSearchType, filter: IRequestFilter) => {
    if (resolver) {
      return resolver(path, type, filter);
    }

    return GroupwareResolver.resolvePath(path, type, filter);
  };
}

export const SEARCHAGLE_APPS: TSearchableAppType[] = [
  {
    code: SEARCHABLE_APP_CODES.MAIL,
    name: '메일',
    url: createPathResolver(
      '/api/mail/message/list/search',
      MailResolver.resolvePath,
    ),
    result: MailResolver.resolveResponse,
    fetchOptions: MailResolver.resolveFetchOption,
  },
  {
    code: SEARCHABLE_APP_CODES.WEBFOLDER,
    name: '자료실',
    url: createPathResolver('/api/mail/webfolder/folder/list/search'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.CONTACT,
    name: '주소록',
    url: createPathResolver('/gw/api/contact/search'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.CALENDAR,
    name: '캘린더',
    url: createPathResolver('/gw/api/search/calendar'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.APPROVAL,
    name: '결재',
    url: createPathResolver('/gw/api/search/approval'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.APPR_COMPANY_FOLDER,
    name: '전사문서함',
    url: createPathResolver('/gw/api/search/docfolder'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.BOARD,
    name: '게시판',
    url: createPathResolver('/gw/api/search/board'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.DOCS,
    name: '문서',
    url: createPathResolver('/gw/api/search/docs'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.COMMUNITY,
    name: '커뮤니티',
    url: createPathResolver('/gw/api/search/community'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.REPORT,
    name: '보고',
    url: createPathResolver('/gw/api/search/report'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.WORKS,
    name: 'Works',
    url: createPathResolver('/gw/api/search/works'),
    result: WorksResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.TODO,
    name: 'ToDO+',
    url: createPathResolver('/gw/api/search/todo'),
    result: GroupwareResolver.resolveResponse,
  },
  {
    code: SEARCHABLE_APP_CODES.HR,
    name: '인사',
    url: createPathResolver(
      '/eacc/api/ess/hr/search',
      EaccResolver.resolvePath,
    ),
    result: EaccResolver.resolveReponse,
  },
  {
    code: SEARCHABLE_APP_CODES.ECONTRACT,
    name: '전자계약',
    url: createPathResolver(
      '/eacc/api/ess/econtract/search',
      EaccResolver.resolvePath,
    ),
    result: EaccResolver.resolveReponse,
  },
  {
    code: SEARCHABLE_APP_CODES.LEGAL_EDUCATION,
    name: '법정의무교육',
    url: createPathResolver(
      '/eacc/api/ess/legal-education/search',
      EaccResolver.resolvePath,
    ),
    result: EaccResolver.resolveReponse,
  },
  {
    code: SEARCHABLE_APP_CODES.EXPENSES,
    name: '경비',
    url: createPathResolver(
      '/eacc/api/ess/expenses/search',
      ExpensesResolver.resolvePath,
    ),
    result: EaccResolver.resolveReponse,
  },
  {
    code: SEARCHABLE_APP_CODES.VEHICLE_LOG,
    name: '차량운행일지',
    url: createPathResolver(
      '/eacc/api/ess/vehicle-log/search',
      EaccResolver.resolvePath,
    ),
    result: EaccResolver.resolveReponse,
  },
].filter((item) =>
  [
    SEARCHABLE_APP_CODES.MAIL,
    SEARCHABLE_APP_CODES.APPROVAL,
    SEARCHABLE_APP_CODES.BOARD,
    SEARCHABLE_APP_CODES.CALENDAR,
    SEARCHABLE_APP_CODES.COMMUNITY,
    SEARCHABLE_APP_CODES.CONTACT,
    SEARCHABLE_APP_CODES.DOCS,
    SEARCHABLE_APP_CODES.REPORT,
    SEARCHABLE_APP_CODES.TODO,
    SEARCHABLE_APP_CODES.WORKS,
    SEARCHABLE_APP_CODES.HR,
    SEARCHABLE_APP_CODES.ECONTRACT,
    SEARCHABLE_APP_CODES.LEGAL_EDUCATION,
    SEARCHABLE_APP_CODES.EXPENSES,
    SEARCHABLE_APP_CODES.VEHICLE_LOG,
  ].includes(item.code),
);
