import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { clsx } from 'clsx';
import { PropsWithChildren, useState } from 'react';
import { GlobalConfigPrimitives } from './parts';

import '@daouoffice/design/dist/template/preferences.css';
import { ScreenLock } from '@dop-ui/react/widgets/screen-lock';

export interface Props {
  asChild?: boolean;
  configPath?: string;
}

export function GlobalConfigDialog({
  asChild = false,
  configPath,
  children,
}: PropsWithChildren<Props>) {
  const [openState, setOpenState] = useState(false);

  return (
    <Dialog.Root
      modal={false}
      open={openState}
      onOpenChange={(isOpened: boolean) => {
        setOpenState(isOpened);
      }}
    >
      {openState && (
        <div
          className={'dim open'}
          aria-hidden
          onClick={() => setOpenState(false)}
        />
      )}
      <Dialog.Trigger asChild={asChild}>{children}</Dialog.Trigger>
      <Dialog.Content
        useAutoClose={false}
        className={clsx('bottom', { open: openState })}
        dim={true}
        size="full"
      >
        <Dialog.Title className="hidden"></Dialog.Title>
        <GlobalConfigPrimitives.Root
          configPath={configPath}
          onClosed={() => setOpenState(false)}
        >
          <ScreenLock lockType="globalconfig">
            <GlobalConfigPrimitives.ServiceMap />
            <GlobalConfigPrimitives.Menu />
            <GlobalConfigPrimitives.Contents />
          </ScreenLock>
        </GlobalConfigPrimitives.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
}
