import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { IncludedKeywordFieldParser, PeriodFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type {
  TFilterValue,
  TSearchParams,
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface HRFilterValueType extends TFilterValue {
  STATUS?: string;
  SUBMIT_PLACE?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
  VALID_DATE?: IPeriodTemplateFieldValues;
  REQUEST_DATE?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface HRSearchParams extends TSearchParams {
  /** @property 전자결재 상태 (기본값: 'ALL') */
  status: string;
  /** @property 이름 */
  'submit-place'?: string;
  /** @property 증명서 종류 */
  name?: string;
  /** @property 이메일 */
  purpose?: string;
  /** @property 비고 */
  remarks?: string;
  'valid-start-date'?: string;
  'valid-end-date'?: string;
  'request-start-date'?: string;
  'request-end-date'?: string;
}

export class HRFilterCreator extends SearchFilterCreator<
  HRFilterValueType,
  HRSearchParams
> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const status = this.source['STATUS'] || 'ALL';
    const inKeywordFields = new IncludedKeywordFieldParser(
      this.source['INCLUDED_KEYWORD'],
    ).parse();
    const validDateFields = new PeriodFieldParser(
      this.source['VALID_DATE'],
    ).parse();
    const requestDateFields = new PeriodFieldParser(
      this.source['REQUEST_DATE'],
    ).parse();

    return {
      status,
      'submit-place': this.source['SUBMIT_PLACE'],
      ...inKeywordFields,
      'valid-start-date': validDateFields.fromDate,
      'valid-end-date': validDateFields.endDate,
      'request-start-date': requestDateFields.fromDate,
      'request-end-date': requestDateFields.toDate,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('SUBMIT_PLACE', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('SUBMIT_PLACE', this.source['SUBMIT_PLACE'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useHRFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new HRFilterCreator(searchKeyword, value);
}
