import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams,
  IPeriodTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface CalendarFilterValueType extends TFilterValue {
  SCHEDULE_NAME?: string;
  ATTENDEES?: string;
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface CalendarSearchParams extends TSearchParams {
  /** @property 일정명 */
  summary?: string;
  /** @property 참석자명 */
  attendees?: string;
  fromDate?: string;
  toDate?: string;
}

export class CalendarFilterCreator 
  extends SearchFilterCreator<CalendarFilterValueType, CalendarSearchParams> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const summary = this.source['SCHEDULE_NAME'];
    const attendees = this.source['ATTENDEES'];
    const periodFiels = new PeriodFieldParser(this.source['PERIOD']).parse();
    
    return {
      summary,
      attendees,
      ...periodFiels,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('SCHEDULE_NAME', 2, 200)
      .addLengthRule('ATTENDEES', 2, 200)
      .setFieldValue('SCHEDULE_NAME', this.source['SCHEDULE_NAME'])
      .setFieldValue('ATTENDEES', this.source['ATTENDEES'])
      .validate();
  }
}

export function useCalendarFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new CalendarFilterCreator(searchKeyword, value);
}
