import * as React from 'react';

interface IContextValue {
  currentValue: string;
  currentLabel: string;
  disabled: boolean;
  open: boolean;
  valueLabelMap: Map<string, string>;
}

interface IContextAction {
  onChangeValue: (value: string) => void;
  setCurrentLabel: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setValueLabelMap: React.Dispatch<React.SetStateAction<Map<string, string>>>;
}

export const SelectContextValue = React.createContext<IContextValue>({
  currentValue: '',
  currentLabel: '',
  disabled: false,
  open: false,
  valueLabelMap: new Map(),
});

export const SelectContextAction = React.createContext<IContextAction>({
  onChangeValue: () => undefined,
  setCurrentLabel: () => undefined,
  setOpen: () => undefined,
  setValueLabelMap: () => undefined,
});

interface Props {
  value?: string;
  disabled?: boolean;
  defaultLabel: string;
  onChange?: (value: string) => void;
}

export function Provider({
  value,
  disabled = false,
  defaultLabel,
  onChange,
  children,
}: React.PropsWithChildren<Props>) {
  const [currentValue, setCurrentValue] = React.useState(value ?? '');
  const [currentLabel, setCurrentLabel] = React.useState(defaultLabel);
  const [valueLabelMap, setValueLabelMap] = React.useState<Map<string, string>>(
    new Map(),
  );
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setCurrentLabel(defaultLabel);
  }, [defaultLabel]);

  React.useEffect(() => {
    setCurrentValue(value ?? '');
    if (value && value !== '') {
      setCurrentLabel(valueLabelMap.get(value) ?? defaultLabel);
    } else if (value === '') {
      setCurrentLabel(defaultLabel);
    }
  }, [value, valueLabelMap, defaultLabel]);

  const onChangeValue = (changeValue: string) => {
    setCurrentValue(changeValue);
    setCurrentLabel(valueLabelMap.get(changeValue) ?? '');
    onChange && onChange(changeValue);
  };

  const contextValue = {
    currentValue,
    currentLabel,
    disabled,
    open,
    valueLabelMap,
  };

  const contextAction = {
    onChangeValue,
    setCurrentLabel,
    setOpen,
    setValueLabelMap,
  };

  return (
    <SelectContextAction value={contextAction}>
      <SelectContextValue value={contextValue}>{children}</SelectContextValue>
    </SelectContextAction>
  );
}

export const useSelectContextValue = () => React.useContext(SelectContextValue);
export const useSelectContextAction = () =>
  React.useContext(SelectContextAction);
export const useSelectContext = () => ({
  ...useSelectContextValue(),
  ...useSelectContextAction(),
});
