import * as React from 'react';
import { clsx } from 'clsx';
import {
  DopDatePicker,
  PREDEFINED_SIZE,
  type DatePickerSizeType,
} from '@dop-ui/react/shared/ui/date-picker/dop-date-picker';
import * as DateUtil from '@dop-ui/react/shared/lib/date';
import { SearchFilterCommonFields } from '../../types';

import styles from './styles.module.css';

export const PERIOD_FIELD_TYPES = {
  CALENDAR: 'calendar',
  RADIO: 'radio',
} as const;

export type PeriodFieldTypes =
  (typeof PERIOD_FIELD_TYPES)[keyof typeof PERIOD_FIELD_TYPES];

export interface Props {
  className?: string;
  size?: DatePickerSizeType;
  disabled?: boolean;
  startDate?: Date;
  endDate?: Date;
  onChange?: (value: SearchFilterCommonFields) => void;
}

export function CustomPeriodField({
  size = PREDEFINED_SIZE.MEDIUM,
  disabled = false,
  startDate,
  endDate,
  onChange,
  className,
}: Props) {
  const [customStartDate, setCustomStartDate] = React.useState<
    Date | undefined
  >(startDate);
  const [customEndDate, setCustomEndDate] = React.useState<Date | undefined>(
    endDate,
  );

  const changeStartDateHandler = React.useCallback(
    (date: Date) => {
      onChange && onChange(createPeriodParams(date, customEndDate));
      setCustomStartDate(date);
    },
    [onChange, customEndDate],
  );

  const changeEndDateHandler = React.useCallback(
    (date: Date) => {
      onChange && onChange(createPeriodParams(customStartDate, date));
      setCustomEndDate(date);
    },
    [onChange, customStartDate],
  );

  return (
    <div className={clsx(styles.PeriodField_CustomPeriod, className)}>
      <DopDatePicker
        size={size}
        defaultDate={customStartDate}
        onDateSelect={changeStartDateHandler}
        maxDate={customEndDate}
        disabled={disabled}
      />
      {`~`}
      <DopDatePicker
        size={size}
        defaultDate={customEndDate}
        minDate={customStartDate}
        maxDate={new Date()}
        onDateSelect={changeEndDateHandler}
        disabled={disabled}
      />
    </div>
  );
}

function createPeriodParams(startDate: Date, endDate: Date) {
  return {
    fromDate: DateUtil.date(startDate).startOf('day').format(),
    toDate: DateUtil.date(endDate).startOf('day').format(),
  };
}

export default CustomPeriodField;
