import { BoardTypeSearchFilter } from './board-type-filter';
import type { SearchFilterCommonProps } from '../../types';

export function BoardSearchFilter(
  props: Omit<SearchFilterCommonProps, 'type'>,
) {
  return <BoardTypeSearchFilter {...props} type="board" />;
}

export default BoardSearchFilter;
