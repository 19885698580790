import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import type {
  TSearchType,
  TSearchableAppType,
  IRequestFilter,
  ISearchResultData,
} from '../model/types';

interface Params {
  type: TSearchType;
  app: TSearchableAppType;
  filter?: IRequestFilter | null;
}

export async function fetchAppSearch({ type, app, filter }: Params) {
  const fetchError: ISearchResultData = {
    code: app.code,
    appName: app.name,
    error: {
      message: 'Data Fetch Error (API)',
    },
  };
  try {
    const fetchOptions = app.fetchOptions ? app.fetchOptions(type, filter) : {};

    const path = app.url(type, filter);
    const response = await fetch(path, fetchOptions);

    if (response.ok) {
      const resp = (await response.json()) as unknown;
      return app.result(app, resp);
    } else {
      return fetchError;
    }
  } catch (e) {
    console.error(`Error Occur: type: ${type}, app: ${app.code}}`);
    // throw e;
    return fetchError;
  }
}
