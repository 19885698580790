import { SEARCH_TYPES } from '@/components/search/model/constants';
import { useIntegratedSearchRequest } from '@/components/search/hooks/use-integrated-search-request';
import { IntegratedAppSearchResult } from './app-search-result';
import { NotFoundErrorTemplate } from '../result-templates';

export interface Props {
  onChangeView?: (code: string) => void;
  onClickLink?: (url: string) => void;
}

export function IntegratedSearchResult({ onClickLink }: Props) {
  const { data, error } = useIntegratedSearchRequest(SEARCH_TYPES.INTEGRATED);

  if (error) {
    return <div>Error</div>;
  }

  const resultSuccessResp = data.filter((result) => {
    if (result.error) return false;
  });
  const totalCnt = resultSuccessResp.reduce((cnt, cur) => {
    return cnt + cur.data.list.length;
  }, 0);

  if (totalCnt === 0) {
    return <NotFoundErrorTemplate />;
  }

  return resultSuccessResp.map((result) => (
    <IntegratedAppSearchResult
      key={result.code}
      data={result}
      onClickLink={onClickLink}
    />
  ));
}

export default IntegratedSearchResult;
