'use client';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import type {
  AppSearchResultProps,
  ICalendarSearchResultItem,
} from '../../types';

import styles from './styles.module.css';

export function SearchSummaryCalendar({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <div className={styles.SearchResult__Calendar}>
      {list.map((item: ICalendarSearchResultItem) => {
        const linkUrl = `/gw/app/calendar/${item.calendarId}/event/${item.id}`;
        const clickHandler = () => {
          onClickLink && onClickLink(linkUrl);
        };

        const timeInfo =
          item.timeType === 'allday'
            ? t('search.result.calendar.allday')
            : `${formatDate({
                date: item.startTime,
                format: 'HH:mm',
              })} - ${formatDate({
                date: item.endTime,
                format: 'HH:mm',
              })}`;

        return (
          <div className={styles.item} key={item.id}>
            <div className={styles.date}>
              {formatDate({
                date: item.endTime,
                format: 'YYYY-MM-DD(ddd)',
              })}
            </div>
            <div className={styles.time}>{timeInfo}</div>
            <div
              data-url={linkUrl}
              onClick={clickHandler}
              onKeyDown={clickHandler}
              role="button"
              tabIndex={0}
              className={styles.summary}
              dangerouslySetInnerHTML={{ __html: item.summary }}
            />
            <div
              className={styles.attendees}
              dangerouslySetInnerHTML={{ __html: item.attendees }}
            ></div>
          </div>
        );
      })}
    </div>
  );
}

export default SearchSummaryCalendar;
