import { I18nText } from '@dop-ui/react/shared/lib/i18n/client';
import type {
  IRequestFilterError,
  IIncludedKeywordTemplateFieldValues,
} from '../../types';

type ErrorPrintType = 'inline' | 'toast';

interface Rule {
  type?: ErrorPrintType;
  ruleFn: (value: unknown) => boolean;
  errorMessage: string | React.ReactElement;
}

export class FieldValidator {
  protected fields: Map<string, unknown>;
  protected rules: Map<string, Rule[]>;

  constructor() {
    this.fields = new Map(); // 필드 이름과 값을 저장
    this.rules = new Map(); // 필드 이름과 검증 규칙을 저장
  }

  /**
   * 필드 별 규칙 등록
   * @param fieldName
   * @param ruleDefinitions
   * @chainable
   */
  addRules(fieldName: string, ruleDefinitions: Rule[]): FieldValidator {
    this.rules.set(fieldName, ruleDefinitions);
    return this;
  }

  /**
   * 사용자가 입력한 필드 값 추가
   * @param fieldName
   * @param value
   * @chainable
   */
  setFieldValue(fieldName: string, value: unknown): FieldValidator {
    this.fields.set(fieldName, value);
    return this;
  }

  // 기본 검증 실행 - 하위 클래스에서 확장 가능
  validate(): IRequestFilterError | null {
    // 모든 필드 값이 비어있는지 확인
    const allFieldsEmpty = Array.from(this.fields.values()).every(
      (value) => !value,
    );
    if (allFieldsEmpty) {
      return {
        type: 'toast',
        field: 'ALL',
        message: <I18nText i18nKey="search.filter.error.common.required" />,
      };
    }

    for (const [fieldName, value] of this.fields) {
      const rules = this.rules.get(fieldName) || [];

      for (const { ruleFn, errorMessage, type } of rules) {
        const isValid = ruleFn(value);
        if (!isValid) {
          return {
            type,
            field: fieldName,
            message: errorMessage,
          };
        }
      }
    }

    return null; // 모든 검증이 성공했을 경우
  }

  /**
   * 길이 검증 규칙 템플릿 (자주 사용하는 일부 규칙을 템플릿화)
   * @param fieldName
   * @param min
   * @param max
   * @chainable
   * @returns
   */
  addLengthRule(fieldName: string, min: number, max: number) {
    this.addRules(fieldName, [
      {
        ruleFn: (value: string) =>
          !(value && (value.length < min || value.length > max)),
        errorMessage: (
          <I18nText
            i18nKey="search.filter.error.common.lengthError"
            options={{ min, max }}
          />
        ),
      },
    ]);

    return this;
  }

  /**
   * 포함하는 단어 검증 규칙 템플릿
   * @param fieldName
   * @param min
   * @param max
   * @returns
   */
  addIncludedKeywordRule(fieldName: string, min: number, max: number) {
    this.addRules(fieldName, [
      // 포함하는 단어 필드 값이 없을 경우
      {
        ruleFn: (value: IIncludedKeywordTemplateFieldValues) =>
          !(
            value &&
            value.checked.length > 0 &&
            (value.keyword.length < min || value.keyword.length > max)
          ),
        errorMessage: (
          <I18nText
            i18nKey="search.filter.error.common.lengthError"
            options={{ min, max }}
          />
        ),
      },
      // 키워드는 있으나, 체크 항목이 없을 경우
      {
        ruleFn: (value: IIncludedKeywordTemplateFieldValues) =>
          !(value && value.checked.length < 1 && !!value.keyword),
        errorMessage: (
          <I18nText i18nKey="search.filter.error.includedKeyword.rangeError" />
        ),
      },
    ]);

    return this;
  }
}
