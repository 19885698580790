import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useEffect, useRef, useState } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Button } from '@dop-ui/react/shared/ui/button';
import { TabItem } from './tab-item';
import { useSearchableAppList } from '../../../hooks/use-search-app-list';
import { useCurrentAppCode, useSearchFilter } from '../../../model/store';
import { SEARCHABLE_APP_CODES } from '../../../model/constants';

import styles from '../styles.module.css';

export function CategoryTabs() {
  const { t } = useTranslation('common');
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const { clear: clearFilter } = useSearchFilter();
  const [isLeftOver, setIsLeftOver] = useState(false);
  const [isRightOver, setIsRightOver] = useState(false);

  const appCodeList = useSearchableAppList();
  const [currentAppCode, setCurrentAppCode] = useCurrentAppCode();

  const overflowCheck = () => {
    if (!tabContainerRef.current) {
      return;
    }

    const { scrollWidth, clientWidth, scrollLeft } = tabContainerRef.current;
    const isLeftOver = scrollLeft > 0;
    const isRightOver = scrollWidth > clientWidth + scrollLeft;

    setIsLeftOver(isLeftOver);
    setIsRightOver(isRightOver);
  };

  const scrollLeft = () => {
    if (!tabContainerRef.current) {
      return;
    }

    tabContainerRef.current.scrollBy({
      left: -200,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    if (!tabContainerRef.current) {
      return;
    }

    tabContainerRef.current.scrollBy({
      left: 200,
      behavior: 'smooth',
    });
  };

  const selectAppHandler = (code: string) => {
    clearFilter();
    setCurrentAppCode(code);
  };

  useEffect(() => {
    overflowCheck();
    window.addEventListener('resize', overflowCheck);

    return () => {
      window.removeEventListener('resize', overflowCheck);
    };
  }, []);

  return (
    <div className={styles.CategoryContainer}>
      <TabItem
        label={t('search.common.words.ALL')}
        isActive={currentAppCode === SEARCHABLE_APP_CODES.INTEGRATED}
        onSelect={selectAppHandler}
      />
      <div className={styles.CateScrollButtonContainer}>
        <Button
          className={clsx(styles.ScrollButton, styles.left, {
            [styles.disabled]: !isLeftOver,
            [styles.active]: isLeftOver,
          })}
          disabled={!isLeftOver}
          onClick={scrollLeft}
        >
          <IconChevronLeft size={14} />
        </Button>
        <Button
          className={clsx(styles.ScrollButton, styles.right, {
            [styles.disabled]: !isRightOver,
            [styles.active]: isRightOver,
          })}
          disabled={!isRightOver}
          onClick={scrollRight}
        >
          <IconChevronRight size={14} />
        </Button>
        <div
          className={styles.CateTabContainer}
          ref={tabContainerRef}
          onScroll={overflowCheck}
        >
          {appCodeList.map((appCode, idx) => {
            return (
              <TabItem
                key={idx}
                code={appCode.code}
                label={appCode.appName}
                isActive={currentAppCode === appCode.code}
                onSelect={selectAppHandler}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryTabs;
