import * as React from 'react';
import styles from './styles.module.css';

export interface Props {
  href: string;
  content: string | React.ReactElement;
  onClick?: (href: string) => void;
  useHtml?: boolean;
}

export function Content({ href, content, onClick, useHtml = true }: Props) {
  const clickHandler = () => {
    onClick && onClick(href);
  };

  const isHtml = useHtml && typeof content === 'string';

  const props = {
    'data-href': href,
    onClick: clickHandler,
    onKeyDown: clickHandler,
    role: 'button',
    tabIndex: 0,
    className: styles.SearchResult__Article__Content
  }
  if (isHtml) {
    return (
      <div
        {...props}
        dangerouslySetInnerHTML={isHtml ? { __html: content } : undefined}
      />
    );
  }

  return (
    <div {...props}>
      {!isHtml && content}
    </div>
  );
}

export default Content;
