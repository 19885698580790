'use client';

import * as React from 'react';
import { useRouter } from 'next/navigation';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { ErrorBoundary } from '@dop-ui/react/shared/ui/error-boundary';
import {
  useSearchDialogOpenState,
  useCurrentAppCode,
  useSearchFilter,
} from '../../model/store';
import { SEARCHABLE_APP_CODES } from '../../model/constants';
import { SearchTextField } from '../search-field';
import { CategoryTabs } from './category-tabs';
import { SearchFilter } from './search-filter';
import { IntegratedSearchResult, AppSearchResult } from './search-result';
import { SearchResultContentLoader } from './content-loader';
import { SystemErrorTemplate } from './search-result/result-templates';

import styles from './styles.module.css';

export function SearchDialog() {
  const [, startTransition] = React.useTransition();
  const router = useRouter();
  const [openState, setOpenState] = useSearchDialogOpenState();
  const [appCode, setAppCode] = useCurrentAppCode();
  const { setFilter: setSearchFilter, clear: clearFilter } = useSearchFilter();

  const clickLinkHandler = (url: string) => {
    startTransition(() => {
      setOpenState(false);
    });
    // console.log('clickLinkHandler:', url);
    router.push(url);
  };
  const changeOpenState = (open: boolean) => {
    // 선택된 앱 코드 초기화
    setAppCode(SEARCHABLE_APP_CODES.INTEGRATED);
    // 필터 초기화
    clearFilter();
    setOpenState(open);
  };

  return (
    <Dialog.Root open={openState} onOpenChange={changeOpenState}>
      <Dialog.Content
        className={styles.SearchDialog}
        useOverlay
        usePortal
        animation="popup"
      >
        <div className={styles.container}>
          {/* 그외 통합 설정 Error 처리 */}
          <ErrorBoundary
            resetKeys={[appCode]}
            FallbackComponent={ErrorFallback}
          >
            <div className={styles.header}>
              <SearchTextField shape="rounded-full" size="large" />
              <CategoryTabs />
            </div>

            <SearchFilter onSubmit={(filter) => setSearchFilter(filter)} />

            <div className={styles.SearchResultContainer}>
              {/* 검색 결과에 Error 처리 */}
              <ErrorBoundary
                resetKeys={[appCode]}
                FallbackComponent={ErrorFallback}
              >
                <React.Suspense fallback={<SearchResultContentLoader />}>
                  {isIntegratedAppCode(appCode) ? (
                    <IntegratedSearchResult onClickLink={clickLinkHandler} />
                  ) : (
                    <AppSearchResult onClickLink={clickLinkHandler} />
                  )}
                </React.Suspense>
              </ErrorBoundary>
            </div>
          </ErrorBoundary>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function isIntegratedAppCode(code: string): boolean {
  return code === SEARCHABLE_APP_CODES.INTEGRATED;
}

/**
 * 추후 공통화 필요
 * @param param0
 * @returns
 */
function ErrorFallback({ error }: { error: Error }) {
  console.error(error);
  return (
    <div>
      <SystemErrorTemplate />
      {process.env.NODE_ENV === 'development' && (
        <div
          role="alert"
          className="border border-red-50 border-t-4 p-4 overflow-auto"
        >
          {error.stack && <pre style={{ color: 'red' }}>{error.stack}</pre>}
        </div>
      )}
    </div>
  );
}

export default SearchDialog;
