import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type {
  TFilterValue,
  TSearchParams,
  IPeriodTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface LegalEducationFilterValueType extends TFilterValue {
  STATUS?: string;
  GRADE?: string;
  NAME?: string;
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface LegalEducationSearchParams extends TSearchParams {
  /** @property 학습 상태 (기본값: 'ALL') */
  status: string;
  /** @property 수료 여부 */
  grade: string;
  /** @property 증명서 종류 */
  name?: string;
  /** @property 이메일 */
  startDate?: string;
  endDate?: string;
}

export class LegalEducationFilterCreator extends SearchFilterCreator<
  LegalEducationFilterValueType,
  LegalEducationSearchParams
> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const status = this.source['STATUS'] || 'ALL';
    const grade = this.source['GRADE'] || 'ALL';
    const name = this.source['NAME'];
    const { fromDate: startDate, toDate: endDate } = new PeriodFieldParser(
      this.source['PERIOD'],
    ).parse();

    console.log('fromDate: ', startDate);
    console.log('toDate: ', endDate);

    return {
      status,
      grade,
      name,
      startDate,
      endDate,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('NAME', 2, 64)
      .setFieldValue('NAME', this.source['NAME'])
      .validate();
  }
}

export function useLegalEducationFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) =>
    new LegalEducationFilterCreator(searchKeyword, value);
}
