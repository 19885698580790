import { BoardTypeSearchFilter } from './board-type-filter';
import type { SearchFilterCommonProps } from '../../types';

export function CommunitySearchFilter(
  props: Omit<SearchFilterCommonProps, 'type'>,
) {
  return <BoardTypeSearchFilter {...props} type="community" />;
}

export default CommunitySearchFilter;
