import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser } from './field-parser';
import { useSearchKeyword } from '../store';
import type {
  TFilterValue,
  TSearchParams,
  IPeriodTemplateFieldValues,
} from '../types';

export interface IntegratedFilterValueType extends TFilterValue {
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface IntegratedSearchParams
  extends IPeriodTemplateFieldValues,
    TSearchParams {}

export class IntegratedFilterCreator extends SearchFilterCreator<
  IntegratedFilterValueType,
  IntegratedSearchParams
> {
  /**
   * @override
   */
  _createParams() {
    const { fromDate, toDate } = new PeriodFieldParser(
      this.source['PERIOD'],
    ).parse();
    console.log('fromDate: ', fromDate);
    console.log('toDate: ', toDate);

    return {
      fromDate,
      toDate,
    };
  }
}

export function useIntegratedFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) =>
    new IntegratedFilterCreator(searchKeyword, value);
}
