import { FieldParser } from './field-parser';
import type { IIncludedKeywordTemplateFieldValues, TSearchParams } from '../../types';

export const PREDIFINED_FIELD_NAMES = {
  SUBJECT: 'SUBJECT',
  TEXT: 'TEXT',
  CONTENT: 'CONTENT',
  COMMENT: 'COMMENT',
  FILE_NAME: 'FILE_NAME',
  FILE_CONTENT: 'FILE_CONTENT',
} as const;

export type PredefinedFiledNames = typeof PREDIFINED_FIELD_NAMES[keyof typeof PREDIFINED_FIELD_NAMES];

export class IncludedKeywordFieldParser 
  extends FieldParser<IIncludedKeywordTemplateFieldValues> {

  protected _fieldMap: Map<string, string> = new Map();

  constructor(
    source: IIncludedKeywordTemplateFieldValues, 
    customFieldMap?: Record<string, string>
  ) {
    super(source);
    this.__createPredefinedFieldMap();

    if (customFieldMap) {
      Object.keys(customFieldMap).forEach((key: string) => {
        this.setFieldMap(key, customFieldMap[key]);
      });
    }
  }

  setFieldMap(predefinedName: string, customName: string) {
    this._fieldMap.set(predefinedName, customName);
  }

  parse() {
    if (!this._source) return {};
    
    return this._source.checked.reduce((acc, cur: string) => {
      const key = this._fieldMap.has(cur) ? this._fieldMap.get(cur) : cur;
      acc[key] = this._source.keyword;

      return acc;
    }, {} as TSearchParams);
  }

  private __createPredefinedFieldMap() {
    this._fieldMap = new Map<string, string>();

    this._fieldMap.set(PREDIFINED_FIELD_NAMES.SUBJECT, 'title');
    this._fieldMap.set(PREDIFINED_FIELD_NAMES.TEXT, 'text');
    this._fieldMap.set(PREDIFINED_FIELD_NAMES.CONTENT, 'content');
    this._fieldMap.set(PREDIFINED_FIELD_NAMES.COMMENT, 'comment');
    this._fieldMap.set(PREDIFINED_FIELD_NAMES.FILE_NAME, 'attachFileNames');
    this._fieldMap.set(PREDIFINED_FIELD_NAMES.FILE_CONTENT, 'attachFileContents');
  }
}
