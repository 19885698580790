'use client';

import * as React from 'react';
import { TextField } from '@dop-ui/react/shared/ui/text-field';
import {
  Field as SearchFilterField,
  useSearchFilterContext,
} from '../primitives';

// import styles from './styles.module.css';

export interface Props {
  className?: string;
  label: string;
  /** @property 필드명 */
  name: string;
  placeholder?: string;
  description?: string;
  onChange?: (value: string) => void;
}
export type ValueType = {
  checked: string[];
  keyword?: string;
};

export function SearchFilterTextField({
  label,
  name,
  placeholder,
  description,
  className,
}: Props) {
  const { filterValue, setFilterValue, error, submit } =
    useSearchFilterContext();
  const currentFilterValue = (filterValue[name] as string) || '';
  const errorOnThis = error && error.field === name ? error.message : undefined;

  const changeKeywordHandler = (value: string) => {
    setFilterValue({ [name]: value });
  };

  return (
    <SearchFilterField label={label} className={className}>
      <TextField
        placeholder={placeholder}
        value={currentFilterValue}
        onChangeValue={changeKeywordHandler}
        onEnter={() => submit()}
        description={description}
        errorMessage={errorOnThis}
      />
    </SearchFilterField>
  );
}

export default SearchFilterTextField;
