import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { IncludedKeywordFieldParser, PeriodFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams, 
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface EContractFilterValueType extends TFilterValue {
  STATUS?: string;
  NAME?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
  REQUEST_DATE?: IPeriodTemplateFieldValues;
  DUE_DATE?: IPeriodTemplateFieldValues;
  COMPLETED_DATE?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface EContractSearchParams extends TSearchParams {
  /** @property 전자결재 상태 (기본값: 'ALL') */
  status: string;
  /** @property 계약서명 */
  name?: string;
  /** @property 서명요청자 */
  requester?: string;
  /** @property 서명방법 */
  signType?: string;
  /** @property 취소사유 */
  remarks?: string;
  'request-start-date'?: string;
  'request-end-date'?: string;
  'valid-start-date'?: string;
  'valid-end-date'?: string;
  'completed-start-date'?: string;
  'completed-end-date'?: string;
}

export class EContractFilterCreator 
  extends SearchFilterCreator<EContractFilterValueType, EContractSearchParams> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const status = this.source['STATUS'] || 'ALL';
    const inKeywordFields = new IncludedKeywordFieldParser(this.source['INCLUDED_KEYWORD']).parse();
    const requestDateFields = new PeriodFieldParser(this.source['REQUEST_DATE']).parse();
    const dueDateFields = new PeriodFieldParser(this.source['DUE_DATE']).parse();
    const completedDateFields = new PeriodFieldParser(this.source['COMPLETED_DATE']).parse();

    return {
      status,
      ...inKeywordFields,
      'request-start-date': requestDateFields.fromDate,
      'request-end-date': requestDateFields.toDate,
      'due-start-date': dueDateFields.fromDate,
      'due-end-date': dueDateFields.toDate,
      'completed-start-date': completedDateFields.fromDate,
      'completed-end-date': completedDateFields.toDate,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('NAME', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('NAME', this.source['NAME'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useEContractFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new EContractFilterCreator(searchKeyword, value);
}
