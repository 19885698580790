import * as React from 'react';
import { clsx } from 'clsx';
import styles from './styles.module.css';

export function Footer({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <footer className={clsx(styles.SearchResult__Article__Footer, className)}>
      {children}
    </footer>
  );
}

export default Footer;
