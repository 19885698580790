import { SEARCH_TYPES } from '../constants';
import type { TSearchType, IRequestFilter } from '../types';

/**
 * 임직원 포털 > 경영서비스 > 경비 path resolver
 * @param path
 * @param type
 * @param params
 * @returns
 */
export function resolvePath(
  path: string,
  type: TSearchType,
  filter: IRequestFilter,
) {
  const { params } = filter;
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  const offset = type === SEARCH_TYPES.INTEGRATED ? '5' : '15';
  const paramTypePath = getTypePath(type, filter);

  const queryString = new URLSearchParams({
    offset,
    ...params,
  }).toString();
  return `${normalizedPath}${paramTypePath}?${queryString}`;
}

function getTypePath(type: TSearchType, filter: IRequestFilter) {
  const { source } = filter;
  const searchType = source['SEARCH_TYPE'];

  // 통합 검색이거나, 상세검색에 "전체"검색일 경우에는 통합 검색으로 보낸다.
  if (type === SEARCH_TYPES.INTEGRATED || searchType === '') {
    return '';
  }

  // 그 외에는 모두 상세 검색으로 처리하며, 구분 별로 path를 분기한다.
  if (searchType === 'CORPORATE_CARD') {
    return '/detail/corporate-card';
  }

  if (searchType === 'PERSONAL') {
    return '/detail/personal-expenses';
  }

  if (searchType === 'FUEL_COST') {
    return '/detail/fuel-cost';
  }

  return '';
}
