import { PropsWithChildren, useEffect, useRef } from 'react';
import { CheckIcon } from '@dop-ui/icons/react/dop/16';
import { useSelectContext } from './context';

import styles from './style.module.css';

interface Props {
  value: string;
}

export function Option({ value, children }: PropsWithChildren<Props>) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { currentValue, onChangeValue, setOpen, setValueLabelMap } =
    useSelectContext();

  const onClickOption = () => {
    onChangeValue(value);
    setOpen(false);
  };

  useEffect(() => {
    setValueLabelMap((prev) => {
      const newMap = new Map(prev);
      newMap.set(value, buttonRef.current?.innerText ?? '');
      return newMap;
    });
  }, [value, setValueLabelMap]);

  return (
    <button
      ref={buttonRef}
      className={styles.Option}
      onClick={() => {
        onClickOption();
      }}
      data-value={value}
    >
      {children}
      <div className={styles.Spacer} />
      {currentValue === value && <CheckIcon size={20} />}
    </button>
  );
}
