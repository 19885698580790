import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';
import { fetchDepartmentList } from '@/components/search/api/fetch-department-list';

interface DepartmentInfo {
  id: number;
  name: string;
}

export interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export function SelectDepartmentList({ value = '', onChange }: Props) {
  const { t } = useTranslation('common');
  const [valueState, setValueState] = React.useState<string>(value);
  const { data, isError, isLoading } = useQuery({
    queryKey: ['department-list'],
    queryFn: async () => fetchDepartmentList(),
  });

  React.useEffect(() => {
    setValueState(value);
  }, [value]);

  React.useEffect(() => {
    if (!data) return;
    const options = data.data as DepartmentInfo[];

    if (
      valueState !== '' &&
      options.find((item) => item.id === Number(valueState))
    )
      return;

    const firstOption = options[0];

    if (firstOption) {
      onChange && onChange(firstOption.id.toString());
    }
  }, [valueState, data, onChange]);

  if (isError) return null;
  if (!data) return null;

  const { data: options } = data;

  const defaultLabel = () => {
    if (isLoading) return 'Loading...';

    const deptOptions = options as DepartmentInfo[];

    const selectedOption = deptOptions.find(
      (item) => item.id === Number(value),
    );

    if (selectedOption) return selectedOption.name;

    return t('search.filter.contact.field.DEPT_ID.placeholder');
  };

  const changeHandler = (value: string) => {
    onChange && onChange(value);
    setValueState(value);
  };

  return (
    <SelectPrimitives.Root
      value={valueState}
      defaultLabel={defaultLabel()}
      onChange={changeHandler}
    >
      <SelectPrimitives.Trigger />
      <SelectPrimitives.Content className="z-[999]">
        {(options as DepartmentInfo[]).map((item) => (
          <SelectPrimitives.Option key={item.id} value={`${item.id}`}>
            {item.name}
          </SelectPrimitives.Option>
        ))}
      </SelectPrimitives.Content>
    </SelectPrimitives.Root>
  );
}
