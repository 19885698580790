'use client';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import {
  ApprovalItemTypeTemplate,
  Primitives as TemplatePrimitives,
} from '../../result-templates';
import { type StatusTagType } from '../../result-templates/primitives/status-tag';
import type {
  AppSearchResultProps,
  IApprovalSearchResultItem,
} from '../../types';

export function SearchSummaryApproval({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <TemplatePrimitives.Container>
      {list.map((item: IApprovalSearchResultItem) => {
        return (
          <ApprovalItemTypeTemplate.Container
            key={item.id}
            status={item.docStatus as StatusTagType}
            statusText={t(`search.result.approval.docStatus.${item.docStatus}`)}
            onClickLink={onClickLink}
          >
            <ApprovalItemTypeTemplate.Title
              href={`/gw/app/approval/document/${item.id}`}
              title={item.title}
              onClick={onClickLink}
            />
            <ApprovalItemTypeTemplate.Footer>
              {/* 기안일 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.approval.draftedAt')}
                value={formatDate({
                  date: item.draftedAt,
                  format: 'YYYY-MM-DD',
                })}
              />

              {/* 기안자 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.approval.drafterName')}
                value={item.drafterName}
              />

              {/* 기안부서 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.approval.drafterDeptName')}
                value={item.drafterDeptName}
              />

              {/* 결재 양식 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.approval.formName')}
                value={item.formName}
              />

              {/* 문서번호 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.approval.docNum')}
                value={item.docNum}
              />

              {/* 결재일 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.approval.completedAt')}
                value={item.docNum}
              />
            </ApprovalItemTypeTemplate.Footer>
          </ApprovalItemTypeTemplate.Container>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

export default SearchSummaryApproval;
