'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import styles from './styles.module.css';

export interface Props {
  href: string;
  title: string;
  onClick?: (href: string) => void;
  className?: string;
}

export function Title({ href, title, onClick, className }: Props) {
  const clickHandler = () => {
    onClick && onClick(href);
  };

  return (
    <div
      data-url={href}
      onClick={clickHandler}
      onKeyDown={clickHandler}
      role="button"
      tabIndex={0}
      className={clsx(styles.SearchResult__Article__Title, className)}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
}

export default Title;
