const BASIC_MANAGEMENT_PREFIX = '/api/portal/setting/basic';

/**
 * @description 기본 관리 서비스 정보 API
 */
export const BASIC_SERVICE_INFO_API = '/api/portal/setting/basic-service';
/**
 * @description 사용중인 앱 정보 API
 */
export const IN_USE_APPS_INFO_API = (portalType: 'employee' | 'business') =>
  BASIC_MANAGEMENT_PREFIX + '/' + portalType + '/app/usage-status';

/**
 * @description 포털별 설정 GNB 메뉴 API
 */
export const PORTAL_GNB_MENU_API = (portalType: 'EMPLOYEE' | 'BUSINESS') =>
  BASIC_MANAGEMENT_PREFIX + '/' + portalType + '/gnb/menu';

/**
 * @description 서비스맵 설정 API
 */
export const SERVICE_MAP_CONFIG_API =
  BASIC_MANAGEMENT_PREFIX + '/app/display-config';

/**
 * @description 임직원포털 앱 관리 API
 */
export const EMP_PORTAL_APP_MANAGEMENT_API =
  BASIC_MANAGEMENT_PREFIX + '/employee/app/usage-status';

/**
 * @description 임직원포털 링크 관리 API
 */
export const EMP_PORTAR_LINK_MANAGEMENT_API =
  BASIC_MANAGEMENT_PREFIX + '/employee/link';

/**
 * @description 임직원포털 GNB 사용자 수정 허용 API
 */
export const EMP_PORTAL_GNB_USER_MODIFY_ALLOW_API =
  BASIC_MANAGEMENT_PREFIX + '/employee-portal/gnb/menu/usage';

/**
 * @description 경영업무포털 앱 관리 API
 */
export const BUSINESS_PORTAL_APP_MANAGEMENT_API =
  BASIC_MANAGEMENT_PREFIX + '/business/app/usage-status';

/**
 * @description 프로필 카드 관리 설정 API
 */
export const PROFILE_CARD_CONFIG_API =
  BASIC_MANAGEMENT_PREFIX + '/profile-card-config';

/**
 * @description 프로필 카드 관리 개인 정보 수정 허용 API
 */
export const PROFILE_CARD_PERSONAL_INFO_API =
  PROFILE_CARD_CONFIG_API + '/profile-edit';

/**
 * @description 프로필 카드 관리 노출 항목 변경 API
 */
export const PROFILE_CARD_DISPLAY_ITEM_API =
  PROFILE_CARD_CONFIG_API + '/visible-item';

/**
 * @description 커스텀 프로필 카드 리스트 조회 API
 */
export const CUSTOM_PROFILE_CARD_LIST_API =
  BASIC_MANAGEMENT_PREFIX + '/custom-profile-card/list';

  /**
 * @description 조직도 노출항목 API
 */
export const ORG_DISPLAY_ITEMS_API =
BASIC_MANAGEMENT_PREFIX + '/organization-chart/extra-field';

/**
 * @description 커스텀 프로필 카드 API
 */
export const CUSTOM_PROFILE_CARD_API =
  BASIC_MANAGEMENT_PREFIX + '/profile-card/custom';

/**
 * @description 전사 일정 관리 조회 API
 */
export const COMPANY_SCHEDULE_MANAGEMENT_API =
  BASIC_MANAGEMENT_PREFIX + '/company-calendar';

export const LOGO_THEME_CONFIG_API =
  BASIC_MANAGEMENT_PREFIX + '/logo-theme-config';
