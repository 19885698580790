import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { highlightText } from '@dop-ui/react/shared/lib/utils';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import { useSearchKeyword } from '../../../../model/store';
import {
  ApprovalItemTypeTemplate,
  Primitives as TemplatePrimitives,
} from '../result-templates';
import { type StatusTagType } from '../result-templates/primitives/status-tag';
import type {
  AppSearchResultProps,
  IVehicleLogSearchResultItem,
} from '../types';

export function SearchResultVehicleLog({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const [searchKeyword] = useSearchKeyword();
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <TemplatePrimitives.Container>
      {list.map((item: IVehicleLogSearchResultItem) => {
        return (
          <ApprovalItemTypeTemplate.Container
            key={item.id}
            // title={highlightText(item.number, searchKeyword)}
            // href={``}
            contentDir="row"
            status={convertStatus(item.status)}
            statusText={t(`search.result.vihicleLog.status.${item.status}`)}
            onClickLink={onClickLink}
          >
            <ApprovalItemTypeTemplate.Title
              href={``}
              title={highlightText(item.number, searchKeyword)}
              onClick={onClickLink}
              className="w-[100px] shrink-0"
            />

            <ApprovalItemTypeTemplate.Footer>
              {/* 구분 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.vihicleLog.type')}
                value={highlightText(item.type, searchKeyword)}
              />

              {/* 차량명 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.vihicleLog.name')}
                value={highlightText(item.type, searchKeyword)}
              />

              {/* 운행목적 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.vihicleLog.purpose')}
                value={highlightText(item.purpose, searchKeyword)}
              />

              {/* 운행일시 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.vihicleLog.logDateTime')}
                value={formatDate({
                  date: item.logDateTime,
                  format: 'YYYY-MM-DD',
                })}
              />

              {/* 운행거리 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.vihicleLog.distance')}
                value={highlightText(`${item.distance}km`, searchKeyword)}
              />

              {/* 출발지 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.vihicleLog.starting')}
                value={highlightText(item.starting, searchKeyword)}
              />

              {/* 도착지 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.vihicleLog.destination')}
                value={highlightText(item.destination, searchKeyword)}
              />

              {/* 비고 */}
              {item.remarks && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t('search.result.vihicleLog.remarks')}
                  value={highlightText(item.remarks, searchKeyword)}
                />
              )}
            </ApprovalItemTypeTemplate.Footer>
          </ApprovalItemTypeTemplate.Container>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

function convertStatus(status: string): StatusTagType {
  return {
    IN_APPROVAL: 'INPROGRESS',
    WRITING: 'DRAFT',
    APPROVED: 'COMPLETE',
  }[status] as StatusTagType;
}

export default SearchResultVehicleLog;
