import * as React from 'react';
import type {
  AppSearchResultProps,
  IBoardTypeSearchResultItem,
} from '../types';
import * as Template from './primitives';

export interface Props extends AppSearchResultProps {
  linkResolver: (item: unknown) => string;
}

export function SearchResultBoardTemplate({
  data,
  linkResolver,
  onClickLink,
}: Props) {
  const {
    data: { list = [] },
  } = data;

  return (
    <Template.Container>
      {list.map((item: IBoardTypeSearchResultItem) => {
        const attachFileName = item.attachFileNames ? (
          <Template.AttachFileInfo filename={item.attachFileNames} />
        ) : null;

        return (
          <Template.Article key={item.id}>
            <Template.Title
              href={linkResolver(item)}
              title={item.title}
              onClick={onClickLink}
            />
            <Template.Content
              href={linkResolver(item)}
              content={item.content}
              onClick={onClickLink}
            />
            {attachFileName}

            <Template.Footer>
              <Template.UserInfo
                uid={item.writer.id}
                name={item.writer.name}
                profileUrl={item.writer.thumbSmall}
              />

              <Template.FormatDate date={item.createdAt} />

              <Template.ContentPath paths={[item.ownerName, item.boardName]} />
            </Template.Footer>
          </Template.Article>
        );
      })}
    </Template.Container>
  );
}

export default SearchResultBoardTemplate;
