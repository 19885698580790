'use client';

import type { JSX } from 'react';
import { type IUserAppInfo } from '@daouoffice/ui/lib/service/AppsDock';
import { useScreenLock } from '@dop-ui/react/widgets/screen-lock';
import { clsx } from 'clsx';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import * as React from 'react';
import { IUserMenuInfo } from '../../types';
import { BadgeInfo } from '@dop-ui/react/entities/badge';

export interface Props {
  data: IUserMenuInfo;
  activated?: boolean;
  as?: JSX.ElementType;
  badge?: BadgeInfo;
}

export function ItemButton({ data, activated, as = 'div', badge }: Props) {
  const Container = as;
  const { locked } = useScreenLock();
  const params = useSearchParams();

  const renderBadge = React.useCallback(() => {
    if (!badge) return <></>;
    if (badge.badgeType === 'NONE') return <></>;
    if (badge.badgeType === 'NUMBER' && badge.badgeValue === '0') return <></>;
    if (badge.badgeType === 'NEW' && !badge.badgeValue) return <></>;

    const content =
      badge.badgeType === 'DOT'
        ? ''
        : badge.badgeType === 'NEW'
          ? 'N'
          : badge.badgeValue;

    return <span className="badge">{content}</span>;
  }, [badge]);

  const el = (
    <Container
      className={clsx('menu_list', {
        active: activated || params.get('appCode') === data.appCode,
      })}
    >
      <Link
        href={data.url}
        className="menu"
        onClick={() => {
          locked({
            appCode: data.appCode,
            appName: data.name,
            appUrl: data.url,
            useLock: data.useLock,
          });
        }}
      >
        <i
          className="icon ic_medium"
          dangerouslySetInnerHTML={{ __html: getAppIcon(data) }}
        />
        <span className="txt">{data.name}</span>
        {renderBadge()}
      </Link>
    </Container>
  );

  return React.isValidElement(el) ? el : null;
}

/**
 * (임시) 추후 관리앱 (Managed App)의 아이콘 관리방식이 결정되면 로직이 변경될 수 있음.
 * @param item
 * @returns
 */
function getAppIcon(item: IUserAppInfo): string {
  return item.icon as string;
}

export default ItemButton;
