import * as React from 'react';
import { clsx } from 'clsx';
import styles from './styles.module.css';

export interface Props {
  as?: 'div' | 'span';
  className?: string;
  dangerouslySetInnerHTML?: { __html: string | TrustedHTML };
}

export function FooterItem({
  as = 'div',
  children,
  dangerouslySetInnerHTML,
  className,
}: React.PropsWithChildren<Props>) {
  const Component = as;

  return (
    <Component
      className={clsx(styles.footerItem, className)}
      dangerouslySetInnerHTML={
        dangerouslySetInnerHTML ? dangerouslySetInnerHTML : undefined
      }
    >
      {children}
    </Component>
  );
}

export default FooterItem;
