import * as React from 'react';
import { clsx } from 'clsx';
import { XMarkIcon } from '@dop-ui/icons/react/dop/24/XMark';
import { useSearchKeyword } from '../../model/store';
import { SearchButton } from './search-button';
import styles from './styles.module.css';

export interface Props {
  className?: string;
  onEnter?: () => void;
  onClear?: () => void;
}

export function SearchIndicator({ className, onClear, onEnter }: Props) {
  const [searchKeyword, setSearchKeyword] = useSearchKeyword();
  const enterHandler = () => {
    onEnter && onEnter();
  };
  const clearHandler = () => {
    onClear && onClear();
    setSearchKeyword('');
  };

  return (
    <div className={clsx(styles.SearchIndicator, className)}>
      <SearchButton />
      <div className={styles.chip}>
        {/* button을 사용하면 말줄임표 스타일이 먹히지 않으니 주의 */}
        <span
          role="button"
          tabIndex={-1}
          className={styles.keyword}
          onClick={enterHandler}
          onKeyUp={enterHandler}
        >
          {searchKeyword}
        </span>
        <button onClick={clearHandler}>
          <XMarkIcon size={16} />
        </button>
      </div>
    </div>
  );
}

export default SearchIndicator;
