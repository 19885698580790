import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useApprovalFilterCreator } from '@/components/search/model/filters/approval-filter-creator';
import { useCurrentAppCode } from '../../../../model/store';
import { useSearchableAppInfo } from '../../../../hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  IncludedKeywordField,
  TextField,
  RadioGroupField,
} from '../filter-templates';
import type { SearchFilterCommonProps, TFilterValue } from '../types';

export interface Props extends SearchFilterCommonProps {}

const PERIOD_FIELD_NAME = 'PERIOD';

export const ReportSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useApprovalFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };
  const defaultValue = { DATE_TYPE: 'DRAFT', SERACH_CONDITION: 'OR' };

  return (
    <SearchFilterPrimitives.Root
      defaultValue={defaultValue}
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        <RadioGroupField
          name="DATE_TYPE"
          // defaultValue="DRAFT"
          label={t('search.filter.approval.field.DATE_TYPE.label')}
          options={[
            {
              label: t('search.filter.approval.field.DATE_TYPE.option.DRAFT'),
              value: 'DRAFT',
            },
            {
              label: t(
                'search.filter.approval.field.DATE_TYPE.option.COMPLETED',
              ),
              value: 'COMPLETED',
            },
          ]}
        />
        {/** 연도 선택 옵션 추가해야 함 */}
        <PeriodField.RadioType name={PERIOD_FIELD_NAME} />

        {/** 구분선 */}
        <div className="h-px bg-[var(--dop-color-border-basic-level1,#EAECEF)]"></div>

        <RadioGroupField
          name="SERACH_CONDITION"
          // defaultValue="OR"
          label={t('search.filter.approval.field.SERACH_CONDITION.label')}
          options={[
            {
              label: t(
                'search.filter.approval.field.SERACH_CONDITION.option.OR',
              ),
              value: 'OR',
            },
            {
              label: t(
                'search.filter.approval.field.SERACH_CONDITION.option.AND',
              ),
              value: 'AND',
            },
          ]}
        />

        <TextField
          label={t('search.filter.approval.field.TITLE.label')}
          placeholder={t('search.filter.approval.field.TITLE.placeholder')}
          name="TITLE"
        />
        <TextField
          label={t('search.filter.approval.field.DRAFTER.label')}
          placeholder={t('search.filter.approval.field.DRAFTER.placeholder')}
          name="DRAFTER"
        />
        <TextField
          label={t('search.filter.approval.field.DRAFTER_DEPT.label')}
          placeholder={t(
            'search.filter.approval.field.DRAFTER_DEPT.placeholder',
          )}
          name="DRAFTER_DEPT"
        />
        <TextField
          label={t('search.filter.approval.field.ACTIVITY_USERS.label')}
          placeholder={t(
            'search.filter.approval.field.ACTIVITY_USERS.placeholder',
          )}
          name="ACTIVITY_USERS"
        />
        <TextField
          label={t('search.filter.approval.field.DOC_NUM.label')}
          placeholder={t('search.filter.approval.field.DOC_NUM.placeholder')}
          name="DOC_NUM"
        />
        <IncludedKeywordField.ContentType />

        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

export default ReportSearchFilter;
