import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useIntegratedFilterCreator } from '@/components/search/model/filters/integrated-filter-creator';
import {
  Primitives as SearchFieldPrimitives,
  PeriodField,
} from '../filter-templates';
import type { SearchFilterCommonProps, TFilterValue } from '../types';

export interface Props extends SearchFilterCommonProps {}

export const IntegratedSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useIntegratedFilterCreator();
  const submitHandler = (value: TFilterValue) => {
    // console.log('submitHandler: ', value);
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };

  return (
    <SearchFieldPrimitives.Root
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFieldPrimitives.Header
        title={t('search.filter.integratedSearch.label')}
      />
      <SearchFieldPrimitives.Container>
        <PeriodField.RadioType />
        <SearchFieldPrimitives.Footer useFilterReset={false} />
      </SearchFieldPrimitives.Container>
    </SearchFieldPrimitives.Root>
  );
};

export default IntegratedSearchFilter;
