/**
 * Works 컴포넌트 타입
 */
export const WORKS_FIELD_TYPES = {
  // 텍스트
  TEXT: 'text',
  // 멀티 텍스트
  TEXTAREA: 'textarea',
  // 숫자
  NUMBER: 'number',
  // 드롭박스
  SELECT: 'select',
  // 체크박스
  CHECKBOX: 'checkbox',
  // 단일선택
  RADIO: 'radio',
  // 리스트박스
  LISTBOX: 'listbox',
  // 날짜
  DATE: 'date',
  // 시간
  TIME: 'time',
  // 날짜와시간
  DATETIME: 'datetime',
  // 파일 첨부
  FILE: 'file',
  // 사용자 선택
  ORG: 'org',
  // 부서 선택
  ORG_DEPT: 'org_dept',
  // 테이블
  TABLE: 'table',

  // 특수 컴포넌트
  // 등록자
  CREATOR: 'creator',
  // 등록일
  CREATE_DATE: 'create_date',
  // 변경자
  UPDATER: 'updater',
  // 수정일
  UPDATE_DATE: 'update_date',

  //디자인 컴포넌트
  // 라벨
  LABEL: 'label',
  // 라인
  HR: 'hr',
  // 공백
  BLANK: 'blank',
  // 칼럼(다단)
  COLUMNS: 'columns',

  // 자동 계산
  FORMULA: 'formula',

  // 사용자가 핸들링할 수 없는 컴포넌트
  // 캔버스
  CANVAS: 'canvas',
  // 칼럼
  COLUMN: 'column',

  // 앱 연동
  APPLET_DOCS: 'applet_docs',
  // 필드 매핑
  FIELD_MAPPING: 'field_mapping',

  STATUS: 'status',
  DOCNO: 'docno',
} as const;

export const WORKS_VALUE_TYPES = {
  STEXT: 'STEXT',
  STEXTS: 'STEXTS',
  TEXT: 'TEXT',
  CONTENT: 'CONTENT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  SELECT: 'SELECT',
  SELECTS: 'SELECTS',
  FILES: 'FILES',
  USER: 'USER',
  USERS: 'USERS',
  APPLETDOCS: 'APPLETDOCS',
  MAPPED: 'MAPPED',
  DEPTS: 'DEPTS',
} as const;

/**
 * 필터에 사용 할 수 있는 필드. valueType 으로 대체 불가. (상태, 변경자, 변경일)
 */
export const FILTER_FIELD_TYPES = [
  WORKS_FIELD_TYPES.TEXT, // STEXT
  WORKS_FIELD_TYPES.DATE, // DATE
  WORKS_FIELD_TYPES.TIME, // TIME
  WORKS_FIELD_TYPES.DATETIME, // DATETIME
  WORKS_FIELD_TYPES.NUMBER, // NUMBER
  WORKS_FIELD_TYPES.TEXTAREA, // TEXT
  WORKS_FIELD_TYPES.FILE, // FILES
  WORKS_FIELD_TYPES.CREATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.LISTBOX, // SELECTS
  WORKS_FIELD_TYPES.RADIO, // SELECT
  WORKS_FIELD_TYPES.SELECT, // SELECT
  WORKS_FIELD_TYPES.CHECKBOX, // SELECTS
  WORKS_FIELD_TYPES.ORG, // USERS
  WORKS_FIELD_TYPES.ORG_DEPT, // DEPTS
  WORKS_FIELD_TYPES.CREATOR, // USER
  WORKS_FIELD_TYPES.STATUS, // SELECT
  WORKS_FIELD_TYPES.APPLET_DOCS, // APPLETDOCS
  WORKS_FIELD_TYPES.FIELD_MAPPING,
  WORKS_FIELD_TYPES.FORMULA, // NUMBER
  WORKS_FIELD_TYPES.DOCNO, // STEXT
] as string[];

/**
 * 목록에 사용 할 수 있는 필드. valueType 으로 대체 불가. (상태)
 */
export const COLUMN_FIELD_TYPES = [
  WORKS_FIELD_TYPES.TEXT, // STEXT
  WORKS_FIELD_TYPES.DATE, // DATE
  WORKS_FIELD_TYPES.TIME, // TIME
  WORKS_FIELD_TYPES.DATETIME, // DATETIME
  WORKS_FIELD_TYPES.NUMBER, // NUMBER
  WORKS_FIELD_TYPES.LISTBOX, // SELECTS
  WORKS_FIELD_TYPES.RADIO, // SELECT
  WORKS_FIELD_TYPES.SELECT, // SELECT
  WORKS_FIELD_TYPES.CHECKBOX, // SELECTS
  WORKS_FIELD_TYPES.ORG, // USERS
  WORKS_FIELD_TYPES.ORG_DEPT, // DEPTS
  WORKS_FIELD_TYPES.CREATOR, // USER
  WORKS_FIELD_TYPES.UPDATER, // USER
  WORKS_FIELD_TYPES.CREATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.UPDATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.STATUS, // SELECT // valueType 으로 대체 불가
  WORKS_FIELD_TYPES.APPLET_DOCS, // APPLETDOCS
  WORKS_FIELD_TYPES.FIELD_MAPPING,
  WORKS_FIELD_TYPES.FORMULA, // NUMBER
  WORKS_FIELD_TYPES.DOCNO, // 문서번호
];

/**
 * 데이터 연동 컴포넌트에서 데이터 연결시, 검색 노출 가능한 항목
 */
export const APPLETDOC_DISPLAY_FIELD_TYPES = [
  WORKS_FIELD_TYPES.TEXT, // STEXT
  WORKS_FIELD_TYPES.DATE, // DATE
  WORKS_FIELD_TYPES.TIME, // TIME
  WORKS_FIELD_TYPES.DATETIME, // DATETIME
  WORKS_FIELD_TYPES.NUMBER, // NUMBER
  WORKS_FIELD_TYPES.LISTBOX, // SELECTS
  WORKS_FIELD_TYPES.RADIO, // SELECT
  WORKS_FIELD_TYPES.SELECT, // SELECT
  WORKS_FIELD_TYPES.CHECKBOX, // SELECTS
  WORKS_FIELD_TYPES.ORG, // USERS
  WORKS_FIELD_TYPES.ORG_DEPT, // DEPTS
  WORKS_FIELD_TYPES.CREATOR, // USER
  WORKS_FIELD_TYPES.UPDATER, // USER
  WORKS_FIELD_TYPES.CREATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.UPDATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.STATUS, // SELECT // valueType 으로 대체 불가
  WORKS_FIELD_TYPES.APPLET_DOCS, // APPLETDOCS
  WORKS_FIELD_TYPES.FIELD_MAPPING,
  WORKS_FIELD_TYPES.FORMULA, // NUMBER
];

/**
 * 차트에 사용 할 수 있는 필드. valueType 으로 대체 불가. (상태, 변경자)
 */
export const CHART_FIELD_TYPES = [
  WORKS_FIELD_TYPES.TEXT, // STEXT
  WORKS_FIELD_TYPES.RADIO, // SELECT
  WORKS_FIELD_TYPES.SELECT, // SELECT
  WORKS_FIELD_TYPES.LISTBOX, // SELECTS
  WORKS_FIELD_TYPES.CHECKBOX, // SELECTS
  WORKS_FIELD_TYPES.NUMBER, // NUMBER
  WORKS_FIELD_TYPES.DATE, // DATE
  WORKS_FIELD_TYPES.TIME, // TIME
  WORKS_FIELD_TYPES.DATETIME, // DATETIME
  WORKS_FIELD_TYPES.ORG, // USERS
  WORKS_FIELD_TYPES.ORG_DEPT, // DEPTS
  WORKS_FIELD_TYPES.CREATOR, // USER
  WORKS_FIELD_TYPES.CREATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.STATUS, // SELECT // valueType 으로 대체 불가
  WORKS_FIELD_TYPES.APPLET_DOCS, // APPLETDOCS
  WORKS_FIELD_TYPES.FIELD_MAPPING,
  WORKS_FIELD_TYPES.FORMULA, // NUMBER
];

/**
 * 기본으로 정의된 필드 타입들. valueType 으로 대체 불가.
 */
export const PREDEFINED_TYPES = [
  WORKS_FIELD_TYPES.CREATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.UPDATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.CREATOR, // USER
  WORKS_FIELD_TYPES.UPDATER, // USER
];

/**
 * 연동항목 매핑이 가능한 필드 타입들. valueType 으로 대체 불가. (상태)
 */
export const MAPPABLE_TYPES = [
  WORKS_FIELD_TYPES.TEXT, // STEXT
  WORKS_FIELD_TYPES.TEXTAREA, // TEXT
  WORKS_FIELD_TYPES.NUMBER, // NUMBER
  WORKS_FIELD_TYPES.SELECT, // SELECT
  WORKS_FIELD_TYPES.RADIO, // SELECT
  WORKS_FIELD_TYPES.CHECKBOX, // SELECTS
  WORKS_FIELD_TYPES.LISTBOX, // SELECTS
  WORKS_FIELD_TYPES.DATE, // DATE
  WORKS_FIELD_TYPES.TIME, // TIME
  WORKS_FIELD_TYPES.DATETIME, // DATETIME
  WORKS_FIELD_TYPES.ORG, // USERS
  WORKS_FIELD_TYPES.ORG_DEPT, // DEPTS
  WORKS_FIELD_TYPES.CREATOR, // USER
  WORKS_FIELD_TYPES.UPDATER, // USER
  WORKS_FIELD_TYPES.CREATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.UPDATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.FORMULA,
];

/**
 * 자동계산이 가능한 필드 타입들
 * NUMBER, DATE, TIME, DATETIME, FORMULA
 */
export const FORMULABLE_TYPES = [
  WORKS_FIELD_TYPES.NUMBER, // NUMBER
  WORKS_FIELD_TYPES.DATE, // DATE
  WORKS_FIELD_TYPES.TIME, // TIME
  WORKS_FIELD_TYPES.DATETIME, // DATETIME
  WORKS_FIELD_TYPES.FORMULA,
];

/**
 * value type 으로 대체 불가능한.
 */
export const IRREPLACEABLE_IN_VALUE_TYPES = [
  WORKS_FIELD_TYPES.CREATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.UPDATE_DATE, // DATETIME
  WORKS_FIELD_TYPES.CREATOR, // USER
  WORKS_FIELD_TYPES.UPDATER, // USER
  WORKS_FIELD_TYPES.STATUS, // SELECT
  WORKS_FIELD_TYPES.DOCNO, // DOCNO
];

export const SELETABLE_TYPES = [
  // 선택형 UI 필드
  WORKS_FIELD_TYPES.CHECKBOX,
  WORKS_FIELD_TYPES.LISTBOX,
  WORKS_FIELD_TYPES.RADIO,
  WORKS_FIELD_TYPES.SELECT,
];

export const LISTENABLE_TYPES = [
  WORKS_FIELD_TYPES.CHECKBOX,
  WORKS_FIELD_TYPES.LISTBOX, // SELECTS
  WORKS_FIELD_TYPES.RADIO,
  WORKS_FIELD_TYPES.SELECT,
  WORKS_FIELD_TYPES.APPLET_DOCS,
];
