'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import * as RadioPrimitives from '@dop-ui/react/shared/ui/radio';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import * as DateUtil from '@dop-ui/react/shared/lib/date';
import {
  Field as SearchFilterField,
  useSearchFilterContext,
} from '../primitives';
import { PERIOD_TYPES } from '../../../../../model/constants';
import { CustomPeriodField } from './custom-period';

import styles from './styles.module.css';

export type FilterType = (typeof PERIOD_TYPES)[keyof typeof PERIOD_TYPES];

export const PERIOD_FIELD_TYPES = {
  CALENDAR: 'calendar',
  RADIO: 'radio',
} as const;

export type PeriodFieldTypes =
  (typeof PERIOD_FIELD_TYPES)[keyof typeof PERIOD_FIELD_TYPES];

export interface PeriodFieldValues {
  /** @property @private 현재 선택된 기간 타입 */
  __type: FilterType;
  /** @property 검색 기간 - 시작일자 */
  fromDate?: string;
  /** @property 검색 기간 - 종료일자 */
  toDate?: string;
}

export interface Props {
  className?: string;
  type?: FilterType;
  /** @property 필드명 */
  name?: string;
}

export const DEFAULT_FILED_NAME = 'PERIOD';
export function SearchFilterRadioPeriodField({
  name = DEFAULT_FILED_NAME,
}: Props) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } = useSearchFilterContext();
  const currentPeriod = filterValue[name] as PeriodFieldValues;
  const currentPeriodType = currentPeriod
    ? currentPeriod.__type
    : PERIOD_TYPES.NONE;

  const changeHandler = (type: FilterType) => {
    setFilterValue({ [name]: createPredefinedPeriodParams(type) });
  };

  return (
    <SearchFilterField
      className={clsx(styles.PeriodField__RadioType)}
      label={t('search.filter.period.label')}
    >
      <div className={styles.radiogroup}>
        <RadioPrimitives.Root
          name={name}
          value={currentPeriodType}
          onChange={changeHandler}
        >
          <RadioPrimitives.Option value={PERIOD_TYPES.NONE}>
            {t('search.filter.period.value.NONE')}
          </RadioPrimitives.Option>
          <RadioPrimitives.Option value={PERIOD_TYPES.LAST_WEEK}>
            {t('search.filter.period.value.LAST_WEEK')}
          </RadioPrimitives.Option>
          <RadioPrimitives.Option value={PERIOD_TYPES.LAST_2_WEEKS}>
            {t('search.filter.period.value.LAST_2_WEEKS')}
          </RadioPrimitives.Option>
          <RadioPrimitives.Option value={PERIOD_TYPES.LAST_MONTH}>
            {t('search.filter.period.value.LAST_MONTH')}
          </RadioPrimitives.Option>
          <RadioPrimitives.Option value={PERIOD_TYPES.CUSTOM}>
            {t('search.filter.period.value.CUSTOM')}
          </RadioPrimitives.Option>
        </RadioPrimitives.Root>

        {currentPeriodType === PERIOD_TYPES.CUSTOM && (
          <CustomPeriodField
            startDate={new Date(currentPeriod.fromDate)}
            endDate={new Date(currentPeriod.toDate)}
            onChange={(value) => setFilterValue({ [name]: value })}
          />
        )}
      </div>
    </SearchFilterField>
  );
}

function createPredefinedPeriodParams(value: FilterType): PeriodFieldValues {
  const today = DateUtil.date();
  const lastWeek = DateUtil.subtract({
    date: today,
    value: 1,
    unit: 'weeks',
  });
  const last2Weeks = DateUtil.subtract({
    date: today,
    value: 2,
    unit: 'weeks',
  });
  const last2Months = DateUtil.subtract({
    date: today,
    value: 1,
    unit: 'month',
  });

  switch (value) {
    case PERIOD_TYPES.LAST_WEEK:
      return {
        __type: PERIOD_TYPES.LAST_WEEK,
        fromDate: lastWeek.startOf('day').format(),
        toDate: today.endOf('day').format(),
      };
    case PERIOD_TYPES.LAST_2_WEEKS:
      return {
        __type: PERIOD_TYPES.LAST_2_WEEKS,
        fromDate: last2Weeks.startOf('day').format(),
        toDate: today.endOf('day').format(),
      };
    case PERIOD_TYPES.LAST_MONTH:
      return {
        __type: PERIOD_TYPES.LAST_MONTH,
        fromDate: last2Months.startOf('day').format(),
        toDate: today.endOf('day').format(),
      };
    case PERIOD_TYPES.CUSTOM:
      // 직접 등록은 선택하는 순간 오늘 날짜로 만듬.
      return {
        __type: PERIOD_TYPES.CUSTOM,
        fromDate: today.startOf('day').format(),
        toDate: today.endOf('day').format(),
      };
    case PERIOD_TYPES.NONE:
    default:
      return null;
  }
}

export default SearchFilterRadioPeriodField;
