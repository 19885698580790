import * as React from 'react';
import { UserAvatar } from '@dop-ui/react/entities/user/ui/user-avatar';
import { FooterItem } from './footer-item';
import styles from './styles.module.css';

export interface Props {
  uid?: number;
  name: string;
  profileUrl?: string;
  useAvatar?: boolean;
}

export function UserInfo({ uid, name, profileUrl, useAvatar = true }: Props) {
  return (
    <FooterItem className={styles.SearchResult__Article__Userinfo}>
      {useAvatar && (
        <UserAvatar
          user={{
            id: uid,
            name,
            profileUrl,
          }}
          size={20}
        />
      )}
      <span
        dangerouslySetInnerHTML={{ __html: name }}
        className={styles.username}
      />
    </FooterItem>
  );
}

export default UserInfo;
