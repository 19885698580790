import { fetchAppSearch } from './fetch-app-search';
import type { TSearchableAppType, IRequestFilter } from '../model/types';

export async function fetch(
  apps: TSearchableAppType[], 
  filter: IRequestFilter
) {
  // console.log('filter: ', filter);
  const fetchPromises = apps.map(app => {
    return fetchAppSearch({ type: 'integrated', app, filter });
  });

  const results = await Promise.all(fetchPromises);
  return results;
}
