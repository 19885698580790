'use client';

import { clsx } from 'clsx';
import * as TemplatePrimitives from '../primitives';
import type { StatusTagType } from '../primitives/status-tag';
import styles from './styles.module.css';

export interface Props {
  status: StatusTagType;
  statusText: string;
  title?: string;
  href?: string;
  contentDir?: 'row' | 'column';
  onClickLink?: (url: string) => void;
}

export function ApprovalItemContainer({
  children,
  status,
  statusText,
  // title,
  // href,
  contentDir = 'column',
  // onClickLink,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={styles.SearchResult__ApprovalItemType}>
      <section className={styles.prefix}>
        <TemplatePrimitives.StatusTag status={status}>
          {statusText}
        </TemplatePrimitives.StatusTag>
      </section>
      <section
        className={clsx(styles.content, {
          [`${styles.row}`]: contentDir === 'row',
          [`${styles.column}`]: contentDir === 'column',
        })}
      >
        {children}
        {/* <TemplatePrimitives.Title
          href={href}
          title={title}
          onClick={onClickLink}
        />
        <TemplatePrimitives.Footer
          className={styles.SearchResult__ApprovalItemType_Footer}
        >
          {children}
        </TemplatePrimitives.Footer> */}
      </section>
    </div>
  );
}

export default ApprovalItemContainer;
