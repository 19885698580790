import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';

import { SelectField } from '../../../filter-templates';

export function SelectApprovalStatusField() {
  const { t } = useTranslation('common');
  const options = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.result.expenses.status.WRITING'),
      value: 'WRITING',
    },
    {
      label: t('search.result.expenses.status.WAITING'),
      value: 'WAITING',
    },
    {
      label: t('search.result.expenses.status.IN_APPROVAL'),
      value: 'IN_APPROVAL',
    },
    {
      label: t('search.result.expenses.status.APPROVED'),
      value: 'APPROVED',
    },
  ];

  return (
    <SelectField.Simple
      label={t('search.filter.expenses.field.STATUS.label')}
      name="STATUS"
      options={options}
    />
  );
}

export default SelectApprovalStatusField;
