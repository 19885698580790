import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser, IncludedKeywordFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams, 
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface ExpensesFilterValueType extends TFilterValue {
  /** @property 검색 구분 (COPORATE_CARD: 법인 카드, PERSONAL: 개인경비, FUEL_COST: 유류비) */
  SEARCH_TYPE?: string;
  STATUS?: string;
  TYPE?: string;
  NUMBER?: string;
  NAME?: string;
  REQUESTER?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
  CAR_NUMBER?: string;
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface ExpensesSearchParams extends TSearchParams {
  /** @property 전자결재 상태 (기본값: 'ALL') */
  status?: string;
  /** @property 개인 경비 > 영수증 종류 */
  receiptType?: string;
  /** @property 개인 경비 > 사용처, 회사법인카드 > 가맹점명 */
  usage?: string;
  /** @property 개인 경비 > 사용용도, 회사법인카드 > 사용용도 */
  purpose?: string;
  /** @property 개인 경비 > 상세사용용도, 회사법인카드 > 상세 사용용도 */
  'detail-purpose'?: string;
  /** @property 개인 경비 > 프로젝트, 회사법인카드 > 프로젝트 */
  project?: string;
  /** @property 개인 경비 > 첨부파일명 */
  'file-name'?: string;
  /** @property 유류비 > 운행목적 (기본값: 'ALL') */
  type?: string;
  /** @property 유류비 > 차량등록번호, 회사 법인카드 > 카드명 */
  name?: string;
  /** @property 유류비 > 유종 */
  fuel?: string;
  /** @property 유류비 > 출발지 */
  starting?: string;
  /** @property 유류비 > 도착지 */
  destination?: string;
  /** @property 회사 법인카드 > 카드번호 */
  number?: string;
  /** @property 회사 법인카드 > 경비청구자 */
  requester?: string;
  /** @property 회사 법인카드 > 경비청구자 */
  'start-date'?: string;
  'end-date'?: string;
}

export class ExpensesFilterCreator 
  extends SearchFilterCreator<ExpensesFilterValueType, ExpensesSearchParams> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const searchType = this.source['SEARCH_TYPE'];

    if (searchType === '') {
      return this.__createAllTypeParams();
    }

    if (searchType === 'CORPORATE_CARD') {
      return this.__createCorpCardParams();
    }

    if (searchType === 'PERSONAL') {
      return this.__createPersonalParams();
    }

    if (searchType === 'FUEL_COST') {
      return this.__createFuelCostParams();
    }

    throw new Error(`Invalid search type: ${searchType}`);
  }

  private __createAllTypeParams() {
    const { fromDate, toDate } = new PeriodFieldParser(this.source['PERIOD']).parse();

    return {
      'start-date': fromDate,
      'end-date': toDate,
    };
  }

  private __createCorpCardParams() {
    const status = this.source['STATUS'] || 'ALL';
    const type = this.source['TYPE'] || 'ALL';
    const number = this.source['NUMBER'];
    const requester = this.source['REQUESTER'];
    const inKeywordFields = new IncludedKeywordFieldParser(this.source['INCLUDED_KEYWORD']).parse();
    const { fromDate, toDate } = new PeriodFieldParser(this.source['PERIOD']).parse();

    return {
      status,
      type,
      number,
      requester,
      ...inKeywordFields,
      'start-date': fromDate,
      'end-date': toDate,
    };
  }

  private __createPersonalParams() {
    const status = this.source['STATUS'] || 'ALL';

    const inKeywordFields = new IncludedKeywordFieldParser(this.source['INCLUDED_KEYWORD']).parse();
    const { fromDate, toDate } = new PeriodFieldParser(this.source['PERIOD']).parse();

    return {
      status,
      ...inKeywordFields,
      'start-date': fromDate,
      'end-date': toDate,
    };
  }

  private __createFuelCostParams() {
    const status = this.source['STATUS'] || 'ALL';
    const type = this.source['TYPE'] || 'ALL';
    const name = this.source['NAME'];
    const inKeywordFields = new IncludedKeywordFieldParser(this.source['INCLUDED_KEYWORD']).parse();
    const { fromDate, toDate } = new PeriodFieldParser(this.source['PERIOD']).parse();

    return {
      status,
      type,
      name,
      ...inKeywordFields,
      'start-date': fromDate,
      'end-date': toDate,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    const searchType = this.source['SEARCH_TYPE'];

    console.log('searchType: ', searchType);
    console.log('this.source: ', this.source);

    if (searchType === 'CORPORATE_CARD') {
      return fieldValidator
        .addLengthRule('NUMBER', 2, 64)
        .addLengthRule('REQUESTER', 2, 64)
        .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
        .setFieldValue('NUMBER', this.source['NUMBER'])
        .setFieldValue('REQUESTER', this.source['REQUESTER'])
        .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
        .validate();
    }

    if (searchType === 'PERSONAL') {
      return fieldValidator
        .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
        .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
        .validate();
    }

    if (searchType === 'FUEL_COST') {
      return fieldValidator
        .addLengthRule('NAME', 2, 64)
        .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
        .setFieldValue('NAME', this.source['NAME'])
        .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
        .validate();
    }

    // 그외에는 통과시킨다.
    return null;
  }
}

export function useExpensesFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new ExpensesFilterCreator(searchKeyword, value);
}
