import { useQuery } from '@tanstack/react-query';
import * as getBadgeListQueries from '../api/get-badge';

export function useBadgeList(appCodes: string[], enabled = true) {
  const { data: badgeList } = useQuery({
    queryKey: [getBadgeListQueries.QUERY_KEY, ...appCodes],
    queryFn: () => getBadgeListQueries.getBadgeList(appCodes),
    enabled: appCodes && appCodes.length > 0 && enabled,
  });

  const getBadgeInfo = (appCode: string) => {
    if (!badgeList) return undefined;
    return badgeList.elements.find((badge) => badge.appCode === appCode);
  };

  return { badgeList, getBadgeInfo };
}
