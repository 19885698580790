import { clsx } from 'clsx';
import { CalendarIcon } from '@dop-ui/icons/react/dop/24/Calendar';
import { enUS, ja, ko, vi, zhCN, zhTW } from 'date-fns/locale';
import { PropsWithChildren, useEffect } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerPrimitive } from './primitives';

import styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';

export const PREDEFINED_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const;
export type DatePickerSizeType =
  (typeof PREDEFINED_SIZE)[keyof typeof PREDEFINED_SIZE];

export interface Props {
  /** @property 미리 정해진 datepicker 사이즈 */
  size?: DatePickerSizeType;
  locale?: string;
  className?: string;
  dateFormat?: string;
  showYearPicker?: boolean;
  showTimeInput?: boolean;
  defaultDate?: Date | null;
  minTime?: Date;
  maxTime?: Date;
  minDate?: Date;
  maxDate?: Date;
  placeholderText?: string;
  disabled?: boolean;
  onDateSelect?: (date: Date | null) => void;
}

/**
 * DatePicker 컴포넌트
 * @returns
 */
export function DopDatePicker({
  size,
  locale = 'ko',
  className = 'w-full',
  dateFormat = 'yyyy-MM-dd',
  showYearPicker = false,
  showTimeInput = false,
  defaultDate: selectedDate,
  minTime,
  maxTime,
  minDate,
  maxDate,
  placeholderText,
  disabled,
  onDateSelect,
}: PropsWithChildren<Props>) {
  useEffect(() => {
    registerLocaleHandler(locale);
  }, [locale]);

  const onChangeHandler = (date: Date | null) => {
    onDateSelect && onDateSelect(date);
  };

  const iconSize = getIconSize(size);

  return (
    <div
      className={clsx(
        styles.DatePicker,
        {
          [`${styles.disabled}`]: disabled,
          [`${styles.small}`]: size === PREDEFINED_SIZE.SMALL,
          [`${styles.medium}`]: size === PREDEFINED_SIZE.MEDIUM,
          [`${styles.large}`]: size === PREDEFINED_SIZE.LARGE,
        },
        className,
      )}
    >
      <CalendarIcon size={iconSize} />
      <ReactDatePicker
        dateFormat={dateFormat}
        showYearPicker={showYearPicker}
        showTimeSelect={showTimeInput}
        minTime={minTime}
        maxTime={maxTime}
        placeholderText={placeholderText}
        shouldCloseOnSelect
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        selected={selectedDate}
        onChange={onChangeHandler}
        renderCustomHeader={
          showYearPicker
            ? undefined
            : ({
                date,
                changeYear,
                changeMonth,
                increaseMonth,
                decreaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <DatePickerPrimitive.Header
                  date={date}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  increaseMonth={increaseMonth}
                  decreaseMonth={decreaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )
        }
      />
    </div>
  );
}

function registerLocaleHandler(locale: string) {
  if (locale === 'ko') {
    registerLocale(locale, ko);
  } else if (locale === 'en') {
    registerLocale(locale, enUS);
  } else if (locale === 'ja') {
    registerLocale(locale, ja);
  } else if (locale === 'zh-CN') {
    registerLocale(locale, zhCN);
  } else if (locale === 'zh-TW') {
    registerLocale(locale, zhTW);
  } else if (locale === 'vi') {
    registerLocale(locale, vi);
  }
}

function getIconSize(size?: DatePickerSizeType) {
  if (size === 'large') {
    return 20;
  }

  // small과 medium은 동일한 크기로 설정
  return 16;
}

export default DopDatePicker;
