import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';

import { SelectField } from '../../../filter-templates';

export function SelectMailFlagField() {
  const { t } = useTranslation('common');
  const options = [
    {
      label: t('search.filter.mail.field.FLAG.option.NONE'),
      value: '',
    },
    // 첨부메일
    {
      label: t('search.filter.mail.field.FLAG.option.T'),
      value: 'T',
    },
    // 읽은메일
    {
      label: t('search.filter.mail.field.FLAG.option.S'),
      value: 'S',
    },
    // 안읽은메일
    {
      label: t('search.filter.mail.field.FLAG.option.U'),
      value: 'U',
    },
    // 답장한메일
    {
      label: t('search.filter.mail.field.FLAG.option.A'),
      value: 'A',
    },
    // 내게쓴메일
    {
      label: t('search.filter.mail.field.FLAG.option.L'),
      value: 'L',
    },
    // 중요메일
    {
      label: t('search.filter.mail.field.FLAG.option.F'),
      value: 'F',
    },
  ];

  return (
    <SelectField.Simple
      label={t('search.filter.mail.field.FLAG.label')}
      name="FLAG"
      options={options}
    />
  );
}

export default SelectMailFlagField;
