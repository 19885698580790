import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  TextField,
} from '../../filter-templates';
import { SelectSearchTypeField } from './fields/search-type-field';
import { SelectApprovalStatusField } from './fields/approval-status-field';
import { SelectCorpCardTypeField } from './fields/corp-card-type-field';
import { CorpCardIncludedKeywordField } from './fields/corp-card-in-keyword-field';
import { PersonalIncludedKeywordField } from './fields/personal-in-keyword-field';
import { SelectFuelCostTypeField } from './fields/fuel-cost-type-field';
import { FuelCostIncludedKeywordField } from './fields/fuel-cost-in-keyword-field';
import type { SearchFilterCommonProps } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export function ExpensesSearchFilterFields() {
  const { t } = useTranslation('common');
  const { filterValue } = SearchFilterPrimitives.useSearchFilterContext();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);
  const currentType = filterValue['SEARCH_TYPE'] as string | null;
  const isEmptyCurrentType = !currentType || currentType === '';

  if (!appInfo) {
    return null;
  }

  return (
    <SearchFilterPrimitives.Container>
      <SelectSearchTypeField />
      {/** 공통 > 전자결재 상태 */}
      {!isEmptyCurrentType && <SelectApprovalStatusField />}
      {/** 법인 카드 > 카드 소유 여부 */}
      {currentType === 'CORPORATE_CARD' && <SelectCorpCardTypeField />}
      {/** 법인 카드 > 카드 번호 */}
      {currentType === 'CORPORATE_CARD' && (
        <TextField
          label={t('search.filter.expenses.CORPORATE_CARD.field.NUMBER.label')}
          placeholder={t(
            'search.filter.expenses.CORPORATE_CARD.field.NUMBER.placeholder',
          )}
          name="NUMBER"
        />
      )}
      {/** 법인 카드 > 경비 청구자 */}
      {currentType === 'CORPORATE_CARD' && (
        <TextField
          label={t(
            'search.filter.expenses.CORPORATE_CARD.field.REQUESTER.label',
          )}
          placeholder={t(
            'search.filter.expenses.CORPORATE_CARD.field.REQUESTER.placeholder',
          )}
          name="REQUESTER"
        />
      )}
      {/** 법인 카드 > 포함하는 단어 */}
      {currentType === 'CORPORATE_CARD' && <CorpCardIncludedKeywordField />}
      {/** 개인 경비 > 포함하는 단어 */}
      {currentType === 'PERSONAL' && <PersonalIncludedKeywordField />}
      {/** 유류비 > 운행 목적 */}
      {currentType === 'FUEL_COST' && <SelectFuelCostTypeField />}
      {/** 유류비 > 차량 등록 번호 */}
      {currentType === 'FUEL_COST' && (
        <TextField
          label={t('search.filter.eacc.field.CAR_NUMBER.label')}
          placeholder={t('search.filter.eacc.field.CAR_NUMBER.placeholder')}
          name="NAME"
        />
      )}
      {/** 유류비 > 포함하는 단어 */}
      {currentType === 'FUEL_COST' && <FuelCostIncludedKeywordField />}
      {/** 공통 > 검색기간 */}
      <PeriodField.CustomType
        name="PERIOD"
        label={t('search.common.words.PERIOD')}
      />
      <SearchFilterPrimitives.Footer useFilterReset />
    </SearchFilterPrimitives.Container>
  );
}

export default ExpensesSearchFilterFields;
