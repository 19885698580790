import { DaouofficeLogo } from './DaouofficeLogo';
import type { CommonProps } from './types';

export interface Props extends CommonProps {
  /** @property 회사 정보 */
  logoImagePath?: string;
  className?: string;
}

/**
 * ServiceLogo 컴포넌트
 * @returns
 */
export function ServiceLogo({
  size = 'medium',
  logoImagePath,
  className,
  ...props
}: Props) {
  // TODO: 스토리지에서 인증없이 logo 받을 수 있도록 요청했음. 반영시 수정 필요함
  if (logoImagePath) {
    return <img className={className} src={logoImagePath} alt="logo" />;
  }
  return <DaouofficeLogo size={size} {...props} />;
}

export default ServiceLogo;
