'use client';

import * as React from 'react';
import type { AppSearchResultProps, IReportSearchResultItem } from '../types';
import * as Template from '../result-templates/primitives';

export function SearchResultReport({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const {
    data: { list = [] },
  } = data;

  return (
    <Template.Container>
      {list.map((item: IReportSearchResultItem) => {
        const attachFileName = item.attachFileNames ? (
          <Template.AttachFileInfo filename={item.attachFileNames} />
        ) : null;
        const reportUrl = `/gw/app/report/series/${item.seriesId}/report/${item.id}`;
        const clickHandler = () => {
          onClickLink && onClickLink(reportUrl);
        };

        return (
          <Template.Article key={item.id}>
            <Template.Content
              href={reportUrl}
              content={item.contents}
              onClick={clickHandler}
            />
            {attachFileName}

            <Template.Footer>
              <Template.UserInfo
                uid={item.reporter.id}
                name={item.reporter.name}
                profileUrl={item.reporter.thumbnail}
              />

              <Template.FormatDate date={item.submittedAt} />
              <Template.ContentPath
                paths={[item.folderDeptName, item.folderName]}
              />
            </Template.Footer>
          </Template.Article>
        );
      })}
    </Template.Container>
  );
}

export default SearchResultReport;
