import { useSearchDialogOpenState } from '../model/store';

export function useSearchDialogAction() {
  const [, setOpen] = useSearchDialogOpenState();

  return {
    open: () => setOpen(true),
    close: () => setOpen(false),
  };
}
