'use client';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { highlightText } from '@dop-ui/react/shared/lib/utils';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import { useSearchKeyword } from '../../../../model/store';
import {
  ApprovalItemTypeTemplate,
  Primitives as TemplatePrimitives,
} from '../result-templates';
import { type StatusTagType } from '../result-templates/primitives/status-tag';
import type {
  AppSearchResultProps,
  TIntegratedExpensesSearchResultItem,
} from '../types';

export function SearchResultExpenses({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const [searchKeyword] = useSearchKeyword();
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <TemplatePrimitives.Container>
      {list.map((item: TIntegratedExpensesSearchResultItem) => {
        const title = item.type === 'PERSONAL' ? item.receiptType : item.name;

        const getExpensesType = (item: TIntegratedExpensesSearchResultItem) => {
          if (item.type === 'FUEL_COST') {
            return t('search.result.expenses.type.FUEL_COST');
          }

          if (item.type === 'PERSONAL') {
            return item.receiptType;
          }

          if (item.type === 'CORPORATE_CARD') {
            return t('search.result.expenses.type.CORPORATE_CARD');
          }

          return null;
        };

        const expensesType = getExpensesType(item);
        const status: StatusTagType = convertStatus(item.status);

        return (
          <ApprovalItemTypeTemplate.Container
            key={item.id}
            // title={highlightText(title, searchKeyword)}
            // href={``}
            status={status}
            statusText={t(`search.result.expenses.status.${item.status}`)}
            onClickLink={onClickLink}
          >
            <ApprovalItemTypeTemplate.Title
              href={``}
              title={highlightText(title, searchKeyword)}
              onClick={onClickLink}
            />

            <ApprovalItemTypeTemplate.Footer>
              {/* 구분 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.expenses.type')}
                value={highlightText(expensesType, searchKeyword)}
              />

              {/* 사용일시/운행일 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t(`search.result.expenses.dateTime.${item.type}`)}
                value={formatDate({
                  date: item.dateTime,
                  format: 'YYYY-MM-DD',
                })}
              />

              {/* 개인경비/법인카드 > 사용처 */}
              {(item.type === 'PERSONAL' || item.type === 'CORPORATE_CARD') && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t(`search.result.expenses.usage.${item.type}`)}
                  value={highlightText(item.usage, searchKeyword)}
                />
              )}

              {/* 영수증금액,이용금액,유류비 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t(`search.result.expenses.amount.${item.type}`)}
                value={highlightText(`${item.amount}`, searchKeyword)}
              />

              {/* 개인경비 > 신청청구금액 */}
              {item.type === 'PERSONAL' && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t('search.result.expenses.PERSONAL.claimAmount')}
                  value={highlightText(`${item.claimAmount}`, searchKeyword)}
                />
              )}

              {/* 개인/법인카드 > 상세사용용도 */}
              {(item.type === 'PERSONAL' || item.type === 'CORPORATE_CARD') && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t(`search.result.expenses.${item.type}.detailPurpose`)}
                  value={highlightText(item.detailPurpose, searchKeyword)}
                />
              )}

              {/* 개인/법인카드 > 프로젝트 */}
              {(item.type === 'PERSONAL' || item.type === 'CORPORATE_CARD') && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t(`search.result.expenses.${item.type}.project`)}
                  value={highlightText(item.project, searchKeyword)}
                />
              )}

              {/* 유류비 > 운행거리 */}
              {item.type === 'FUEL_COST' && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t('search.result.expenses.FUEL_COST.distance')}
                  value={highlightText(`${item.distance}`, searchKeyword)}
                />
              )}

              {/* 유류비 > 유종 */}
              {item.type === 'FUEL_COST' && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t('search.result.expenses.FUEL_COST.fuel')}
                  value={highlightText(`${item.fuel}`, searchKeyword)}
                />
              )}

              {/* 유류비 > 출발지 */}
              {item.type === 'FUEL_COST' && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t('search.result.expenses.FUEL_COST.starting')}
                  value={highlightText(item.starting, searchKeyword)}
                />
              )}

              {/* 유류비 > 도착지 */}
              {item.type === 'FUEL_COST' && (
                <ApprovalItemTypeTemplate.FooterItem
                  label={t('search.result.expenses.FUEL_COST.destination')}
                  value={highlightText(item.destination, searchKeyword)}
                />
              )}
            </ApprovalItemTypeTemplate.Footer>
          </ApprovalItemTypeTemplate.Container>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

function convertStatus(status: string): StatusTagType {
  return {
    IN_APPROVAL: 'INPROGRESS',
    WAITING: 'HOLD',
    WRITING: 'DRAFT',
    APPROVED: 'COMPLETE',
  }[status] as StatusTagType;
}

export default SearchResultExpenses;
