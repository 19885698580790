import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { useAtom } from 'jotai';
import { selectedMainCategoryAtom } from '../../../../store/selectedMainCategoryAtom';

export interface Props {
  menuPath: string;
}

export function Item({ menuPath, children }: PropsWithChildren<Props>) {
  const [selectedMainCategory, setSelectedMainCategory] = useAtom(
    selectedMainCategoryAtom,
  );

  return (
    <button
      className={clsx(
        'relative flex items-center w-full h-[34px] px-[8px] rounded-[8px] hover:bg-[#EEF1F7] text-[#3B3B3B]',
        { 'bg-transparent': menuPath !== selectedMainCategory },
        { 'bg-[#EEF1F7]': menuPath === selectedMainCategory },
      )}
      onClick={() => setSelectedMainCategory(menuPath)}
    >
      {children}
    </button>
  );
}
