import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useWorksFilterCreator } from '@/components/search/model/filters/works-filter-creator';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import {
  Primitives as SearchFieldPrimitives,
  PeriodField,
  IncludedKeywordField,
  TextField,
} from '../../filter-templates';
import { SelectAppletField } from './fields/select-applet';
import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export const WorksSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useWorksFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFieldPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFieldPrimitives.Header title={appInfo.name} />
      <SearchFieldPrimitives.Container>
        <SelectAppletField />
        <TextField
          label={t('search.filter.common.field.CREATOR.label')}
          placeholder={t('search.filter.common.field.CREATOR.placeholder')}
          name="CREATOR"
        />
        <IncludedKeywordField.ContentType type="works" />
        <PeriodField.RadioType name="PERIOD" />
        <SearchFieldPrimitives.Footer useFilterReset />
      </SearchFieldPrimitives.Container>
    </SearchFieldPrimitives.Root>
  );
};

export default WorksSearchFilter;
