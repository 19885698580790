import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useVehicleLogFilterCreator } from '@/components/search/model/filters/vehicle-log-filter-creator';
import { useCurrentAppCode } from '../../../../../model/store';
import { useSearchableAppInfo } from '../../../../../hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  TextField,
} from '../../filter-templates';
import { SelectApprovalStatusField } from './fields/approval-status-field';
import { SelectCarTypeField } from './fields/car-type-field';
import { SelectPurposeField } from './fields/purpose-field';
import { VihicleLogIncludedKeywordField } from './fields/included-keyword-field';

import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export const ExpensesSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useVehicleLogFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        <SelectApprovalStatusField />
        <SelectCarTypeField />
        <SelectPurposeField />
        <TextField
          label={t('search.filter.eacc.field.CAR_NUMBER.label')}
          placeholder={t('search.filter.eacc.field.CAR_NUMBER.placeholder')}
          name="NUMBER"
        />
        <TextField
          label={t('search.filter.vihicleLog.field.NAME.label')}
          placeholder={t('search.filter.vihicleLog.field.NAME.placeholder')}
          name="NAME"
        />
        <VihicleLogIncludedKeywordField />
        <PeriodField.CustomType
          name="PERIOD"
          label={t('search.common.words.PERIOD')}
        />
        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

export default ExpensesSearchFilter;
