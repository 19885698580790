'use client';

import { clsx } from 'clsx';
import { ServiceLogo } from '@daouoffice/ui/lib/service/ServiceLogo';
import { ToolbarClient } from '@daouoffice/next/components/Toolbar';
import { GlobalSearchField } from '../search';
import { useSessionContext } from '@dop-ui/react/features/authenticate/ui/client/session-context';
import { MultiCompanySelector } from '@dop-ui/react/widgets/multicompany-selector';

import '@daouoffice/design/dist/pages/employee/layout/root/header.css';

export function Header({ className }: { className?: string }) {
  const { me } = useSessionContext();

  return (
    <header className={clsx('header', className)}>
      <div className="wrap_header">
        <div className="group wrap_logo">
          <h1 className="logo flex items-center gap-[16px]">
            <ServiceLogo
              className="h-[25px]"
              size={20}
              logoImagePath={
                me.displayConfig ? me.displayConfig.LOGO_IMAGE : undefined
              }
            />
            <MultiCompanySelector />
          </h1>
        </div>

        <div className="group utils">
          {/* placeholder: 검색 */}
          <GlobalSearchField />

          {/* SNB 영역 (+ 아바타) */}
          <ToolbarClient service="employee" />
        </div>
      </div>
    </header>
  );
}

export default Header;
