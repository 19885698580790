import * as React from 'react';
import { clsx } from 'clsx';
import styles from './styles.module.css';

export const STATUS_TAGS = {
  INPROGRESS: 'INPROGRESS',
  COMPLETE: 'COMPLETE',
  RETURN: 'RETURN',
  HOLD: 'HOLD',
  RECEIVED: 'RECEIVED',
  RECV_WAITING: 'RECV_WAITING',
  DRAFT: 'DRAFT',
} as const;

export type StatusTagType = (typeof STATUS_TAGS)[keyof typeof STATUS_TAGS];

export interface Props {
  status: StatusTagType;
}

export function StatusTag({
  status,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(styles.SearchResult__Article__StatusTag, {
        [`${styles.INPROGRESS}`]: status === 'INPROGRESS', // 진행 중
        [`${styles.COMPLETE}`]: status === 'COMPLETE', // 완료
        [`${styles.RETURN}`]: status === 'RETURN', // 반려
        [`${styles.HOLD}`]: status === 'HOLD', // 보류
        [`${styles.RECEIVED}`]: status === 'RECEIVED', // 접수
        [`${styles.RECV_WAITING}`]: status === 'RECV_WAITING', // 진행/접수 중
        [`${styles.DRAFT}`]: status === 'DRAFT', // 진행/접수 중
      })}
    >
      {children}
    </div>
  );
}

export default StatusTag;
