import React from 'react';
import { clsx } from 'clsx';

import styles from './styles.module.css';

export function Container({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div className={clsx(styles.SearchResult__Container, className)}>
      {children}
    </div>
  );
}

export default Container;
