import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams,
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface MailFilterValueType extends TFilterValue {
  FOLDER?: string;
  FOLDER_OPTIONS?: {
    FOLDER: string;
    SEARCH_EXT_FOLDER: boolean;
  };
  SEARCH_EXT_FOLDER?: boolean;
  FROM_ADDR?: string;
  TO_ADDR?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
  FLAG?: string;
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 메일 검색 파라메터 인터페이스
 */
export interface MailSearchParams extends TSearchParams {
  /** @property 메일함 (전체 메일함: all, 그외에 메일함별 고유 상수 ID가 있음) */
  folder?: string;
  /** 
   * @property 포함하는 단어 체크된 항목을 문자열 결합으로 표기 
   * [옵션 정보]
   * - s: 제목
   * - b: 본문
   * - af: 첨부파일명
   * - ab: 첨부파일 내용
   */
  category?: string;
  /** 
   * @property 추가 검색조건
   * [옵션 정보]
   * - T: 첨부메일
   * - S: 읽은메일
   * - U: 안읽은메일
   * - A: 답장한메일
   * - L: 내게쓴메일
   * - F: 중요메일
   */
  flag?: string;
  /** @property 보낸 사람 */
  fromaddr?: string;
  /** @property 받는 사람 */
  toaddr?: string;
  /** @property 뷰타입 (안보내도 되는 듯) */
  listType?: string;
  /** @property 검색조건 > 전체메일함 일 때, 스팸메일함과 휴지통 포함 여부 옵션 ('on'/'off') */
  searchExtFolder?: string;
  /** @property ?? */
  adv: string;
  sdate?: string;
  edate?: string;
}

export class MailFilterCreator 
  extends SearchFilterCreator<MailFilterValueType, MailSearchParams> {
  /**
   * @override
   */
  _createParams() {
    console.log('this.source: ', this.source);
    const folder = this.source['FOLDER'];
    const searchExtFolder = this.source['SEARCH_EXT_FOLDER'] ? 'on' : 'off';
    const fromaddr = this.source['FROM_ADDR'];
    const toaddr = this.source['TO_ADDR'];
    const flag = this.source['FLAG'];
    const category = this._parseIncludedKeywordField();
    const { fromDate: sdate, toDate: edate } = new PeriodFieldParser(this.source['PERIOD']).parse();
    
    return {
      folder,
      searchExtFolder,
      adv: 'on',
      category,
      flag,
      fromaddr,
      toaddr,
      sdate,
      edate,
    };
  }

  protected _parseIncludedKeywordField() {
    const sourceData = this.source['INCLUDED_KEYWORD'];
    if (!sourceData) return '';

    return sourceData.checked.reduce((acc, cur: string) => {
      if (cur === 'SUBJECT') return `${acc}s`
      if (cur === 'CONTENT') return `${acc}b`
      if (cur === 'FILE_NAME') return `${acc}af`
      if (cur === 'FILE_CONTENT') return `${acc}ab`

      return acc;
    }, '');
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('FROM_ADDR', 2, 64)
      .addLengthRule('TO_ADDR', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('FROM_ADDR', this.source['FROM_ADDR'])
      .setFieldValue('TO_ADDR', this.source['TO_ADDR'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useMailFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new MailFilterCreator(searchKeyword, value);
}
