'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import { IconReload } from '@tabler/icons-react';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useSearchFilterContext } from './context';
import type { SearchFilterCommonProps } from '../../types';

import styles from './styles.module.css';

export interface Props
  extends Omit<SearchFilterCommonProps, 'appCode' | 'onSubmit'> {
  className?: string;
  /** @property 필터 초기화 기능 사용 여부 */
  useFilterReset?: boolean;
  onSubmit?: () => void;
}

export function SearchFilterFooter({
  className,
  useFilterReset = false,
}: Props) {
  const { submit, cancel, reset } = useSearchFilterContext();
  const { t } = useTranslation('common');

  const cancelHandler = () => {
    cancel();
  };

  const submitHandler = () => {
    submit();
  };

  const resetHandler = () => {
    reset();
  };

  return (
    <div className={clsx(styles.SearchFilter__Footer, className)}>
      {useFilterReset && (
        <Button
          size="medium"
          colorset="level2"
          shape="round"
          variant="outline"
          onClick={resetHandler}
        >
          <span>{t('search.filter.button.reset')}</span>
          <IconReload size={20} stroke={1.5} />
        </Button>
      )}
      <Button
        size="medium"
        colorset="level1"
        shape="rect"
        variant="outline"
        onClick={cancelHandler}
      >
        {t('search.filter.button.cancel')}
      </Button>
      <Button
        size="medium"
        colorset="level1"
        shape="rect"
        variant="solid"
        onClick={submitHandler}
      >
        {t('search.filter.button.submit')}
      </Button>
    </div>
  );
}

export default SearchFilterFooter;
