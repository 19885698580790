import * as React from 'react';
import { useExpensesFilterCreator } from '@/components/search/model/filters/expenses-filter-creator';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import { Primitives as SearchFilterPrimitives } from '../../filter-templates';
import type { SearchFilterCommonProps, TFilterValue } from '../../types';
import { ExpensesSearchFilterFields } from './filter-fields';

export interface Props extends SearchFilterCommonProps {}

export const ExpensesSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const createFilter = useExpensesFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <ExpensesSearchFilterFields />
    </SearchFilterPrimitives.Root>
  );
};

export default ExpensesSearchFilter;
