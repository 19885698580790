'use client';

import * as React from 'react';
// import dynamic from 'next/dynamic';
import { SearchFieldSwitcher } from './search-field-switcher';
import { SearchDialog } from '../search-dialog';
// const SearchDialog = dynamic(() => import('../search-dialog'));

export function GlobalSearchField() {
  return (
    <>
      <SearchFieldSwitcher />
      <SearchDialog />
    </>
  );
}

export default GlobalSearchField;
