import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { IncludedKeywordField } from '../../../filter-templates';

export function FuelCostIncludedKeywordField() {
  const { t } = useTranslation('common');
  const options = [
    {
      value: 'starting',
      label: t('search.result.expenses.FUEL_COST.starting'),
    },
    {
      value: 'destination',
      label: t('search.result.expenses.FUEL_COST.destination'),
    },
    {
      value: 'fuel',
      label: t('search.result.expenses.FUEL_COST.fuel'),
    },
  ];

  return <IncludedKeywordField.Primitive options={options} />;
}

export default FuelCostIncludedKeywordField;
