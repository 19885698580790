import { atom, useAtom } from 'jotai';
import { SEARCHABLE_APP_CODES } from './constants';
import { IRequestFilter } from './types';


const dialogOpenStateAtom = atom(false);
const keywordAtom = atom<string>('');
const currentAppCodeAtom = atom<string>(SEARCHABLE_APP_CODES.INTEGRATED);
const searchFilter = atom<IRequestFilter>({});

export const useSearchDialogOpenState = () => useAtom(dialogOpenStateAtom);
export const useSearchKeyword = () => useAtom(keywordAtom);
export const useCurrentAppCode = () => useAtom(currentAppCodeAtom);

export const useSearchFilter = () => {
  const [filterState, setFilterState] = useAtom(searchFilter);

  return {
    get filter() { return filterState; },
    setFilter: setFilterState,
    clear: () => setFilterState({}),
    hasFilter: () => Object.keys(filterState).length > 0,
  }
};
