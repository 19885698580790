'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import {
  Primitives as FilterTemplates,
  SelectField,
} from '../../../../filter-templates';
import { SelectMyBoardMenu } from './my-board-menu';
import { SelectDeptBoardMenu } from './dept-board-menu';
import { SelectCompanyBoardMenu } from './company-board-menu';

const FIELD_NAME = 'BOARD_IDS';
export const OWNER_TYPE = {
  ALL: '',
  COMPANY: 'Company',
  DEPARTMENT: 'Department',
} as const;
export type OwnerType = (typeof OWNER_TYPE)[keyof typeof OWNER_TYPE];

interface FilterValueType {
  ownerType: OwnerType;
  ownerId: string;
  boardIds?: string[];
}

export function SelectBoardField({ className }: { className?: string }) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } =
    FilterTemplates.useSearchFilterContext();
  const currentFilterValue = filterValue[FIELD_NAME] as
    | FilterValueType
    | undefined;
  const {
    ownerType = '',
    ownerId = '',
    boardIds = [],
  } = currentFilterValue || {};

  const changeOwnerTypeHandler = (value: string) => {
    if (value === '') {
      setFilterValue({ [FIELD_NAME]: null });
    } else {
      const [tOwnerType, tOwnerId] = value.split('|');
      setFilterValue({
        [FIELD_NAME]: {
          ownerType: tOwnerType,
          ownerId: tOwnerId,
        },
      });
    }
  };
  const changeBoardIdHandler = (value: string) => {
    setFilterValue({
      [FIELD_NAME]: {
        ownerType,
        ownerId,
        boardIds: [value],
      },
    });
  };

  return (
    <SelectField.Layout
      label={t('search.filter.board.field.BOARD_IDS.label')}
      className={className}
    >
      <SelectMyBoardMenu
        onChange={changeOwnerTypeHandler}
        value={`${ownerType}|${ownerId}`}
      />
      {ownerType === OWNER_TYPE.DEPARTMENT && (
        <SelectDeptBoardMenu
          deptId={ownerId}
          onChange={changeBoardIdHandler}
          value={boardIds[0]}
        />
      )}
      {ownerType === OWNER_TYPE.COMPANY && (
        <SelectCompanyBoardMenu
          onChange={changeBoardIdHandler}
          value={boardIds[0]}
        />
      )}
    </SelectField.Layout>
  );
}

export default SelectBoardField;
