import { SEARCHABLE_APP_CODES } from '../../../model/constants';
import { useCurrentAppCode } from '../../../model/store';
import * as SearchFilterViews from './filter-views';
import type { SearchFilterCommonProps } from './types';

export function SearchFilter({
  onSubmit,
  onCancel,
  onReset,
}: SearchFilterCommonProps) {
  const [appCode] = useCurrentAppCode();
  const SearchFilterView = getSearchFilterView(appCode);

  if (!SearchFilterView) {
    return null;
  }

  return (
    <SearchFilterView
      onSubmit={onSubmit}
      onCancel={onCancel}
      onReset={onReset}
    />
  );
}

function getSearchFilterView(
  appCode: string | null,
): React.ComponentType<SearchFilterCommonProps> {
  if (appCode === SEARCHABLE_APP_CODES.INTEGRATED) {
    return SearchFilterViews.IntgratedSearchFilter;
  }

  switch (appCode) {
    case SEARCHABLE_APP_CODES.BOARD:
      return SearchFilterViews.BoardTypeSearchFilter.BoardFilter;
    case SEARCHABLE_APP_CODES.COMMUNITY:
      return SearchFilterViews.BoardTypeSearchFilter.CommunityFilter;
    case SEARCHABLE_APP_CODES.CONTACT:
      return SearchFilterViews.ContactSearchFilter;
    case SEARCHABLE_APP_CODES.CALENDAR:
      return SearchFilterViews.CalendarSearchFilter;
    case SEARCHABLE_APP_CODES.DOCS:
      return SearchFilterViews.DocsSearchFilter;
    case SEARCHABLE_APP_CODES.REPORT:
      return SearchFilterViews.ReportSearchFilter;
    case SEARCHABLE_APP_CODES.WORKS:
      return SearchFilterViews.WorksSearchFilter;
    case SEARCHABLE_APP_CODES.APPROVAL:
      return SearchFilterViews.ApprovalSearchFilter;
    case SEARCHABLE_APP_CODES.HR:
      return SearchFilterViews.HRSearchFilter;
    case SEARCHABLE_APP_CODES.ECONTRACT:
      return SearchFilterViews.EContractSearchFilter;
    case SEARCHABLE_APP_CODES.EXPENSES:
      return SearchFilterViews.ExpensesSearchFilter;
    case SEARCHABLE_APP_CODES.VEHICLE_LOG:
      return SearchFilterViews.VehicleLogSearchFilter;
    case SEARCHABLE_APP_CODES.LEGAL_EDUCATION:
      return SearchFilterViews.LegalEducationSearchFilter;
    case SEARCHABLE_APP_CODES.MAIL:
      return SearchFilterViews.MailSearchFilter;
    default:
      return null;
  }
}

export default SearchFilter;
