'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import { useContactFilterCreator } from '@/components/search/model/filters/contact-filter-creator';
import {
  Primitives as SearchFilterPrimitives,
  TextField,
} from '../../filter-templates';
import { SelectAddressBookField } from './fields/addressbook-filter';
import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export const ContactSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useContactFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        <SelectAddressBookField />
        <TextField
          label={t('search.filter.contact.field.USERNAME.label')}
          placeholder={t('search.filter.contact.field.USERNAME.placeholder')}
          name="USERNAME"
        />
        <TextField
          label={t('search.filter.contact.field.EMAIL.label')}
          placeholder={t('search.filter.contact.field.EMAIL.placeholder')}
          name="EMAIL"
        />
        <TextField
          label={t('search.filter.contact.field.PHONE.label')}
          placeholder={t('search.filter.contact.field.PHONE.placeholder')}
          name="PHONE"
        />
        <TextField
          label={t('search.filter.contact.field.COMPANY_NAME.label')}
          placeholder={t(
            'search.filter.contact.field.COMPANY_NAME.placeholder',
          )}
          name="COMPANY_NAME"
        />
        <TextField
          label={t('search.filter.contact.field.COMPANY_TEL.label')}
          placeholder={t('search.filter.contact.field.COMPANY_TEL.placeholder')}
          name="COMPANY_TEL"
        />
        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

export default ContactSearchFilter;
