import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';

import {
  SelectField,
  Primitives as FilterTemplates,
} from '../../../filter-templates';

const FIELD_NAME = 'SEARCH_TYPE';
export function SelectSearchTypeField() {
  const { t } = useTranslation('common');
  const { resetTemp, setFilterValue } =
    FilterTemplates.useSearchFilterContext();
  const typeOpts = useSelectTypeOptions();
  const changeHandler = (value: string) => {
    const isEmptyCurrentType = !value || value === '';
    if (isEmptyCurrentType) {
      resetTemp();
    } else {
      setFilterValue({ [FIELD_NAME]: value });
    }
  };

  return (
    <SelectField.Simple
      label={t('search.filter.expenses.field.SEARCH_TYPE.label')}
      name={FIELD_NAME}
      options={typeOpts}
      onChange={changeHandler}
    />
  );
}

function useSelectTypeOptions() {
  const { t } = useTranslation('common');

  return [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.result.expenses.type.CORPORATE_CARD'),
      value: 'CORPORATE_CARD',
    },
    {
      label: t('search.result.expenses.type.PERSONAL'),
      value: 'PERSONAL',
    },
    {
      label: t('search.result.expenses.type.FUEL_COST'),
      value: 'FUEL_COST',
    },
  ];
}

export default SelectSearchTypeField;
