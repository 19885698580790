import * as React from 'react';
import {
  IconMail,
  IconAddressBook,
  IconClock,
  IconChartPie,
  IconWritingSign,
  IconClipboardText,
  IconFileDescription,
  IconFolder,
  IconLayoutGrid,
  IconBriefcase,
  IconBeach,
  IconArchive,
  IconMessage2,
  IconLayoutList,
  IconInbox,
  IconId,
  IconCoin,
  IconFilePencil,
  IconScale,
  IconLuggage,
  IconCar,
  IconEmpathize,
  IconLinkPlus,
} from '@tabler/icons-react';
import { CalendarIcon } from '@dop-ui/icons/react/dop/24/Calendar';
import { INHOUSE_EMP_APP_CODES } from '../model/constants';

import {
  type IconProps as TablerIconProps,
  type Icon as TablerIcon,
} from '@tabler/icons-react';
import type { IconCommonProps } from '@dop-ui/icons/react/dop/types';

/**
 * 인하우스 앱에 대한 아이콘 반환
 * @description
 * [규칙]
 * 1. 인하우스 앱 코드 포맷은 dop-{employee|business}-{app-code} 형태로 구성
 * 2. 인하우스 앱의 아이콘은 앱 코드의 세번째 이후 부분을 이용하여 동일하게 구성됨 (이를 appType으로 정의)
 * 3. 앱 타입에 따라서 아이콘을 반환
 * @param appCode
 */
export function getInhouseAppIcon(appCode: string) {
  switch (appCode) {
    case INHOUSE_EMP_APP_CODES.MAIL:
      return wrapTablerIcon('Mail', IconMail);
    case INHOUSE_EMP_APP_CODES.CONTACT:
      return wrapTablerIcon('AddressBook', IconAddressBook);
    case INHOUSE_EMP_APP_CODES.CALENDAR:
      return wrapDopIcon('Calendar', CalendarIcon);
    case INHOUSE_EMP_APP_CODES.ASSET:
      return wrapTablerIcon('Clock', IconClock);
    case INHOUSE_EMP_APP_CODES.SURVEY:
      return wrapTablerIcon('ChartPie', IconChartPie);
    case INHOUSE_EMP_APP_CODES.APPROVAL:
      return wrapTablerIcon('WritingSign', IconWritingSign);
    case INHOUSE_EMP_APP_CODES.BOARD:
      return wrapTablerIcon('ClipboardText', IconClipboardText);
    case INHOUSE_EMP_APP_CODES.REPORT:
      return wrapTablerIcon('FileDescription', IconFileDescription);
    case INHOUSE_EMP_APP_CODES.DOCS:
      return wrapTablerIcon('Folder', IconFolder);
    case INHOUSE_EMP_APP_CODES.WORKS:
      return wrapTablerIcon('LayoutGrid', IconLayoutGrid);
    case INHOUSE_EMP_APP_CODES.ATTEND:
      return wrapTablerIcon('Briefcase', IconBriefcase);
    case INHOUSE_EMP_APP_CODES.LEAVE:
      return wrapTablerIcon('Beach', IconBeach);
    case INHOUSE_EMP_APP_CODES.APPR_COMPANY_FOLDER:
      return wrapTablerIcon('Archive', IconArchive);
    case INHOUSE_EMP_APP_CODES.COMMUNITY:
      return wrapTablerIcon('Message2', IconMessage2);
    case INHOUSE_EMP_APP_CODES.TODO:
      return wrapTablerIcon('LayoutList', IconLayoutList);
    case INHOUSE_EMP_APP_CODES.WEBFOLDER:
      return wrapTablerIcon('Inbox', IconInbox);
    case INHOUSE_EMP_APP_CODES.HR:
      return wrapTablerIcon('Id', IconId);
    case INHOUSE_EMP_APP_CODES.PAYROLL:
      return wrapTablerIcon('Coin', IconCoin);
    case INHOUSE_EMP_APP_CODES.ECONTRACT:
      return wrapTablerIcon('FilePencil', IconFilePencil);
    case INHOUSE_EMP_APP_CODES.LEGAL_EDUCATION:
      return wrapTablerIcon('Scale', IconScale);
    case INHOUSE_EMP_APP_CODES.EXPENSES:
      return wrapTablerIcon('Luggage', IconLuggage);
    case INHOUSE_EMP_APP_CODES.VEHICLE_LOG:
      return wrapTablerIcon('Car', IconCar);
    case INHOUSE_EMP_APP_CODES.WELFARE:
      return wrapTablerIcon('Empathize', IconEmpathize);
    case INHOUSE_EMP_APP_CODES.LINKPLUS:
      return wrapTablerIcon('LinkPlus', IconLinkPlus);
    default:
      return wrapTablerIcon('LayoutGrid', IconLayoutGrid);
  }
}

function wrapTablerIcon(
  displayName: string,
  Icon: React.ForwardRefExoticComponent<
    TablerIconProps & React.RefAttributes<TablerIcon>
  >,
) {
  const WrappedIcon: React.FC<{ size?: number }> = ({ size = 24 }) => {
    return <Icon size={size} stroke={1.5} />;
  };
  WrappedIcon.displayName = `TableIcon${displayName}`;

  return WrappedIcon;
}

function wrapDopIcon(
  displayName: string,
  Icon: React.ComponentType<IconCommonProps>,
) {
  const WrappedIcon: React.FC<{ size?: number }> = ({ size = 24 }) => {
    return <Icon size={size} />;
  };
  WrappedIcon.displayName = `DopIcon${displayName}`;

  return WrappedIcon;
}
