import { useSuspenseQuery } from '@tanstack/react-query';
import * as DateUtil from '@dop-ui/react/shared/lib/date';
import * as SearchDetailFetcher from '../api/app-search-fetcher';
import { useSearchKeyword, useSearchFilter } from '../model/store';
import { SEARCHAGLE_APPS } from '../model/searchable-apps';
import type { IRequestFilter } from '../model/types';

interface Params {
  code: string;
  page: number;
}

export function useAppSearchRequest({ code, page = 1 }: Params) {
  const { filter, hasFilter } = useSearchFilter();
  const [keyword] = useSearchKeyword();
  let filterIncludePage: IRequestFilter = {
    source: {},
    params: {},
  };

  if (hasFilter()) {
    const { params } = filter;
    // 페이징 처리를 위해 page를 추가
    filterIncludePage = {
      ...filter,
      params: { ...params, page: String(page) },
    };
  } else {
    // 필터가 없을 경우 simple 검색으로 처리
    filterIncludePage = {
      ...filterIncludePage,
      params: { stype: 'simple', keyword, page: String(page) },
    };

    // simple 검색에서는 fromDate, toDate는 필수이기 때문에 없으면 채워준다.
    if (!filterIncludePage.params.fromDate) {
      filterIncludePage.params.fromDate = DateUtil.date('1971-01-01')
        .startOf('day')
        .format();
    }
    if (!filterIncludePage.params.toDate) {
      filterIncludePage.params.toDate = DateUtil.date().endOf('day').format();
    }
  }

  const queryString = new URLSearchParams(
    filterIncludePage.params as Record<string, string>,
  ).toString();
  const app = SEARCHAGLE_APPS.find((app) => app.code === code);

  if (!app) {
    throw new Error(`Invalid app code: ${code}`);
  }

  return useSuspenseQuery({
    queryKey: ['search-detail', code, queryString],
    queryFn: async () => {
      const result = await SearchDetailFetcher.fetch(app, filterIncludePage);
      return result;
    },
  });
}
