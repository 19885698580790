import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectField } from '../../../filter-templates';

export function SelectCorpCardTypeField() {
  const { t } = useTranslation('common');
  const options = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.filter.expenses.CORPORATE_CARD.field.TYPE.option.OWNER'),
      value: 'OWNER',
    },
    {
      label: t('search.filter.expenses.CORPORATE_CARD.field.TYPE.option.ADMIN'),
      value: 'ADMIN',
    },
  ];

  return (
    <SelectField.Simple
      label={t('search.filter.expenses.CORPORATE_CARD.field.TYPE.label')}
      name="TYPE"
      options={options}
    />
  );
}

export default SelectCorpCardTypeField;
