'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import { TextField } from '@dop-ui/react/shared/ui/text-field';
import {
  Field as SearchFilterField,
  useSearchFilterContext,
} from '../primitives';

import styles from './styles.module.css';

interface OptionType {
  label: string;
  value: string;
}

export interface Props {
  className?: string;
  /** @property 필드명 */
  name?: string;
  /** @property 체크박스 옵션 ({ [key:string]: [label:string] }) */
  options: OptionType[];
  onChange?: (value: Record<string, boolean>) => void;
}
export type ValueType = {
  checked: string[];
  keyword?: string;
};

export const DEFAULT_FILED_NAME = 'INCLUDED_KEYWORD';
export function SearchFilterIncludedKeyword({
  name = DEFAULT_FILED_NAME,
  options,
  className,
}: Props) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue, error, submit } =
    useSearchFilterContext();
  const currentFilterValue = filterValue[name] as ValueType;
  const currentChecked = React.useMemo(
    () => (currentFilterValue ? currentFilterValue.checked || [] : []),
    [currentFilterValue],
  );
  const currentKeyword = currentFilterValue ? currentFilterValue.keyword : '';

  // filterValue를 바로 사용할 경우, TextField에 연결된 값에서 React Input Props 관련 오류
  // (Uncontrolled Component 관련 경고)가 발생하여, 일관성을 위해, 둘다 로컬 상태를 두고,
  // filterValue와 동기화 하는 방식으로 진행.
  const [checkedState, setCheckedState] = React.useState(currentChecked);
  const [keywordLocalState, setKeywordLocalState] =
    React.useState(currentKeyword);

  React.useEffect(() => {
    setCheckedState(currentChecked);
  }, [currentChecked]);

  React.useEffect(() => {
    setKeywordLocalState(currentKeyword);
  }, [currentKeyword]);

  const updateFilterValue = (checked: string[], keyword?: string) => {
    setFilterValue({ [name]: { checked, keyword } });
  };

  const changeKeywordHandler = (value: string) => {
    updateFilterValue(checkedState, value);
  };

  const errorOnThis = error && error.field === name ? error.message : undefined;

  return (
    <SearchFilterField
      label={t('search.filter.includedKeyword.label')}
      className={clsx(styles.IncludedKeywordField, className)}
    >
      <div className={styles.row}>
        {options.map(({ value, label }, idx) => {
          const isChecked = checkedState.includes(value);
          const changeHandler = (checked: boolean) => {
            const newChecked = checkedState.filter((v) => v !== value);
            if (checked) {
              newChecked.push(value);
            }
            setCheckedState(newChecked);
            updateFilterValue(newChecked, keywordLocalState);
          };

          return (
            <CheckBox
              id={value}
              key={idx}
              defaultChecked={isChecked}
              label={label}
              onChange={changeHandler}
            />
          );
        })}
      </div>
      <TextField
        placeholder={t('search.filter.includedKeyword.keyword.placeholder')}
        value={keywordLocalState}
        onChangeValue={changeKeywordHandler}
        onEnter={() => submit()}
        errorMessage={errorOnThis}
      />
    </SearchFilterField>
  );
}

export default SearchFilterIncludedKeyword;
