// import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { UserAvatar } from '@dop-ui/react/entities/user/ui/user-avatar';
import { Primitives as TemplatePrimitives } from '../../result-templates';
import type {
  AppSearchResultProps,
  IContactSearchResultItem,
} from '../../types';

import styles from './styles.module.css';

export function SearchSummaryContact({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <TemplatePrimitives.Container className={styles.SearchResult__Contact}>
      {list.map((item: IContactSearchResultItem) => {
        const linkUrl = `/gw/app/contact/${item.id}`;
        const clickHandler = () => {
          onClickLink && onClickLink(linkUrl);
        };

        return (
          <div
            data-url={linkUrl}
            onClick={clickHandler}
            onKeyDown={clickHandler}
            role="button"
            tabIndex={0}
            className={styles.item}
            key={item.id}
          >
            <div className={styles.userinfo}>
              <UserAvatar
                user={{
                  id: item.id,
                  name: item.name,
                  profileUrl: item.photoPath,
                }}
                size={24}
              />
              <span
                dangerouslySetInnerHTML={{ __html: item.name }}
                className={styles.username}
              />
            </div>
            <div className={styles.content}>
              {item.email && (
                <AddressItemInfo
                  label={t('search.result.contact.email')}
                  value={item.email}
                />
              )}
              {item.mobileNo && (
                <AddressItemInfo
                  label={t('search.result.contact.mobileNo')}
                  value={item.mobileNo}
                />
              )}
              {item.companyName && (
                <AddressItemInfo
                  label={t('search.result.contact.companyName')}
                  value={item.companyName}
                />
              )}
              {item.office && item.office.tel && (
                <AddressItemInfo
                  label={t('search.result.contact.officeTel')}
                  value={item.office.tel}
                />
              )}
              {item.description && (
                <AddressItemInfo
                  label={t('search.result.contact.description')}
                  value={item.description}
                />
              )}
            </div>
          </div>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

function AddressItemInfo({ label, value }: { label: string; value: string }) {
  return (
    <span className={styles.bookItem}>
      <span className={styles.label}>{label}</span>
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </span>
  );
}

export default SearchSummaryContact;
