import { IconSearch } from '@tabler/icons-react';
import { clsx } from 'clsx';
import styles from './styles.module.css';

export function SearchButton({ className }: { className?: string }) {
  return (
    <span className={clsx(styles.SearchIcon, className)}>
      <IconSearch stroke={1.5} />
    </span>
  );
}

export default SearchButton;
