import * as React from 'react';
import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';
import { useSearchFilterContext } from '../primitives';
import { SelectFieldLayout } from './layout';

interface SelectOptions {
  label: string;
  value: string;
}

export interface Props {
  name: string;
  label: string;
  options?: SelectOptions[];
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
}

export const EMPTY_OPTION = '';

/**
 * Select 필드 기본 타입
 * @param param0
 * @returns
 */
export function SearchFilterSelectField({
  children,
  name,
  label,
  options = [],
  placeholder,
  className,
  onChange,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } = useSearchFilterContext();
  const currentValue = filterValue[name] as string;
  const isEmptyOption = (value: string) => value === EMPTY_OPTION;
  // select field는 기본이 자신의 값을 세팅하거나 null로 세팅한다.
  // 그러나, 간혹 외부에서 필터값을 핸들링해야 하는 경우가 있어서, onChange 이벤트 핸들러가 외부에서 주입되면, 외부에 핸들링을 맡긴다.
  const defaultChangeHandler = (value: string) => {
    if (isEmptyOption(value)) {
      setFilterValue({ [name]: null });
    } else {
      setFilterValue({ [name]: value });
    }
  };
  const changeHandler = onChange || defaultChangeHandler;
  const defaultLabel = () => {
    const selectedOption = options.find((item) => item.value === currentValue);
    if (selectedOption) return selectedOption.label;

    return placeholder ? placeholder : t('search.common.words.ALL');
  };

  return (
    <SelectFieldLayout label={label} className={className}>
      <SelectPrimitives.Root
        value={currentValue}
        defaultLabel={defaultLabel()}
        onChange={changeHandler}
      >
        <SelectPrimitives.Trigger />
        <SelectPrimitives.Content className={clsx('z-[999]')}>
          {options.map((item, idx) => (
            <SelectPrimitives.Option key={idx} value={item.value}>
              {item.label}
            </SelectPrimitives.Option>
          ))}
        </SelectPrimitives.Content>
      </SelectPrimitives.Root>
      {children}
    </SelectFieldLayout>
  );
}

export default SearchFilterSelectField;
