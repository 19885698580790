'use client';

import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';
import { fetchCompanyBoardMenus } from '@/components/search/api/fetch-board-menu-queries';

interface CompanyBoardItem {
  id: number;
  name: string;
}

export interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export function SelectCompanyBoardMenu({ value = '', onChange }: Props) {
  const { t } = useTranslation('common');
  const [valueState, setValueState] = React.useState<string>(value);
  const {
    data: options,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['company-board-menus'],
    queryFn: async () => {
      const { data } = await fetchCompanyBoardMenus();
      return (data as CompanyBoardItem[]).map((item) => {
        return {
          // 게시판 타입 | 소유자 ID 형태로 value를 설정
          value: `${item.id}`,
          label: item.name,
        };
      });
    },
  });

  React.useEffect(() => {
    setValueState(value);
  }, [value]);

  if (isError) return null;
  if (!options) return null;

  const defaultLabel = isLoading ? 'Loading...' : t('search.common.words.ALL');

  const mergedOptions = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    ...options,
  ];

  const changeHandler = (value: string) => {
    onChange && onChange(value);
    setValueState(value);
  };

  return (
    <SelectPrimitives.Root
      value={valueState}
      defaultLabel={defaultLabel}
      onChange={changeHandler}
    >
      <SelectPrimitives.Trigger />
      <SelectPrimitives.Content className="z-[999]">
        {mergedOptions.map((item, idx) => (
          <SelectPrimitives.Option key={idx} value={`${item.value}`}>
            {item.label}
          </SelectPrimitives.Option>
        ))}
      </SelectPrimitives.Content>
    </SelectPrimitives.Root>
  );
}
