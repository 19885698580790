import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { highlightText } from '@dop-ui/react/shared/lib/utils';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import { useSearchKeyword } from '../../../../model/store';
import {
  ApprovalItemTypeTemplate,
  Primitives as TemplatePrimitives,
} from '../result-templates';
import { type StatusTagType } from '../result-templates/primitives/status-tag';
import type {
  AppSearchResultProps,
  ILegalEducationSearchResultItem,
} from '../types';

export function SearchResultLegalEducation({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const [searchKeyword] = useSearchKeyword();
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <TemplatePrimitives.Container>
      {list.map((item: ILegalEducationSearchResultItem) => {
        const status: StatusTagType = convertStatus(item.status);

        const startDate = formatDate({
          date: item.startDate,
          format: 'YYYY-MM-DD',
        });
        const endDate = item.endDate
          ? formatDate({ date: item.endDate, format: 'YYYY-MM-DD' })
          : undefined;
        const periodDate = `${startDate}${endDate ? ` ~ ${endDate}` : ''}`;

        return (
          <ApprovalItemTypeTemplate.Container
            key={item.id}
            status={status}
            statusText={t(`search.result.legalEducation.status.${item.status}`)}
            onClickLink={onClickLink}
          >
            <ApprovalItemTypeTemplate.Title
              href={``}
              title={highlightText(item.name, searchKeyword)}
              onClick={onClickLink}
            />

            <ApprovalItemTypeTemplate.Footer>
              {/* 수료여부 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.legalEducation.grade')}
                value={highlightText(item.grade, searchKeyword)}
              />

              {/* 학습기간 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.legalEducation.period')}
                value={periodDate}
              />

              {/* 강의수 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.legalEducation.count')}
                value={`${item.count}`}
              />

              {/* 진도율 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.legalEducation.progressRate')}
                value={`${item.progressRate}%`}
              />
            </ApprovalItemTypeTemplate.Footer>
          </ApprovalItemTypeTemplate.Container>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

function convertStatus(status: string): StatusTagType {
  return {
    COMPLETED: 'COMPLETE',
    WAITING: 'DRAFT',
    IN_PROGRESS: 'INPROGRESS',
  }[status] as StatusTagType;
}

export default SearchResultLegalEducation;
