import * as React from 'react';
import { clsx } from 'clsx';
import {
  Field as SearchFilterField,
  useSearchFilterContext,
} from '../primitives';
import { PERIOD_TYPES } from '../../../../../model/constants';
import { CustomPeriodField } from './custom-period';

import styles from './styles.module.css';

export type FilterType = (typeof PERIOD_TYPES)[keyof typeof PERIOD_TYPES];

export const PERIOD_FIELD_TYPES = {
  CALENDAR: 'calendar',
  RADIO: 'radio',
} as const;

export type PeriodFieldTypes =
  (typeof PERIOD_FIELD_TYPES)[keyof typeof PERIOD_FIELD_TYPES];

export interface PeriodFieldValues {
  /** @property @private 현재 선택된 기간 타입 */
  __type: FilterType;
  /** @property 검색 기간 - 시작일자 */
  fromDate?: string;
  /** @property 검색 기간 - 종료일자 */
  toDate?: string;
}

export interface Props {
  className?: string;
  label: string;
  /** @property 필드명 */
  name?: string;
}

export const DEFAULT_FILED_NAME = 'PERIOD';
export function SearchFilterRadioPeriodField({
  name = DEFAULT_FILED_NAME,
  label,
  className,
}: Props) {
  const { filterValue, setFilterValue } = useSearchFilterContext();
  const currentPeriod = filterValue[name] as PeriodFieldValues;
  const startDate = currentPeriod
    ? new Date(currentPeriod.fromDate)
    : undefined;
  const endDate = currentPeriod ? new Date(currentPeriod.toDate) : undefined;

  return (
    <SearchFilterField
      className={clsx(styles.PeriodField__CustomType, className)}
      label={label}
    >
      <CustomPeriodField
        size="large"
        startDate={startDate}
        endDate={endDate}
        onChange={(value) => setFilterValue({ [name]: value })}
      />
    </SearchFilterField>
  );
}

export default SearchFilterRadioPeriodField;
