import * as React from 'react';
import type { AppSearchResultProps, IBoardTypeSearchResultItem } from '../types';
import { SearchResultBoardTemplate } from '../result-templates/board-type';

interface CommnunitySearchResultItem extends IBoardTypeSearchResultItem {
  communityId: string;
}

export function SearchResultCommunity({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const linkResolver = (item: CommnunitySearchResultItem) => {
    return [
      '/gw/app/community',
      item.communityId,
      'board',
      item.boardId,
      'post',
      item.id,
      item.boardType === 'STREAM' ? 'stream' : undefined,
    ]
      .filter(Boolean)
      .join('/');
  };
  return (
    <SearchResultBoardTemplate
      data={data}
      linkResolver={linkResolver}
      onClickLink={onClickLink}
    />
  );
}

export default SearchResultCommunity;
