// import { clsx } from 'clsx';
import * as React from 'react';
import { highlightText } from '@dop-ui/react/shared/lib/utils/string';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import useRelativeTime from '@dop-ui/react/shared/lib/date/use-relative-time';
import { UserAvatar } from '@dop-ui/react/entities/user/ui/user-avatar';
import { useSearchKeyword } from '../../../../../model/store';
import type { IMailSearchResultItem } from '../../types';

import styles from './styles.module.css';

export interface Props {
  data: IMailSearchResultItem;
  onClickLink?: (url: string) => void;
}

export function SearchResultMailItem({ data, onClickLink }: Props) {
  const [searchKeyword] = useSearchKeyword();
  const { i18n } = useTranslation();
  const { relativeTime } = useRelativeTime({
    date: data.dateUtc,
    locale: i18n.language,
  });

  const linkUrl = `/gw/app/contact/${data.id}`;
  const clickHandler = () => {
    onClickLink && onClickLink(linkUrl);
  };

  return (
    <div
      data-url={linkUrl}
      onClick={clickHandler}
      onKeyDown={clickHandler}
      role="button"
      tabIndex={0}
      className={styles.item}
    >
      <div className={styles.userinfo}>
        <UserAvatar
          user={{
            id: data.id,
            name: data.fromToSimple,
          }}
          size={32}
          altLength={3}
        />
      </div>
      <div className={styles.content}>
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(data.fromToSimple, searchKeyword),
          }}
          className={styles.username}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(data.subject, searchKeyword),
          }}
          className={styles.subject}
        />
      </div>
      <div className={styles.date}>{relativeTime}</div>
    </div>
  );
}

export default SearchResultMailItem;
