import * as DateUtil from '@dop-ui/react/shared/lib/date';
import { FieldParser } from './field-parser';
import type {
  IPeriodTemplateFieldValues,
  TSearchParams 
} from '../../types';

interface PeriodSearchParamsType extends IPeriodTemplateFieldValues, TSearchParams {}

export class PeriodFieldParser 
  extends FieldParser<IPeriodTemplateFieldValues, PeriodSearchParamsType> {
  parse() {
    const field = this._source;
    
    let periodField: PeriodSearchParamsType; 
    if (!field) {
      periodField = {
        fromDate: DateUtil.date('1971-01-01').format(),
        toDate: DateUtil.date().endOf('days').format(),
      }
    } else {
      periodField = {
        fromDate: field.fromDate,
        toDate: field.toDate,
      }
    }
    return periodField;
  }
}
