'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import { useToastMessage } from '@dop-ui/react/shared/ui/toast';
import { SearchFilterContext, type FilterValueType } from './context';
import type { PrimitivesCommonProps } from './types';
import type { IRequestFilterError } from '@/components/search/model/types';
import styles from './styles.module.css';

export interface Props extends PrimitivesCommonProps {
  open?: boolean;
  defaultValue?: FilterValueType;
  validator?: (value: FilterValueType) => IRequestFilterError | null;
  onSubmit?: (value: FilterValueType) => void;
  onCancel?: () => void;
  onReset?: () => void;
}

export function SearchFieldRoot({
  open = false,
  defaultValue,
  children,
  className,
  validator,
  onSubmit,
  onCancel,
  onReset,
}: React.PropsWithChildren<Props>) {
  const toast = useToastMessage();
  const defaultFilterValue = React.useMemo(
    () => defaultValue || {},
    [defaultValue],
  );
  const [openState, setOpenState] = React.useState<boolean>(open);
  // 검색 버튼 누르기 전 필터 값을 저장하기 위한 상태
  const [filterValue, setFilterValue] =
    React.useState<FilterValueType>(defaultFilterValue);
  // 검색 버튼 누른 후 저장된 필터 값을 별도로 저장하기 위한 상태
  const [savedFilterValue, setSavedFilterValue] =
    React.useState<FilterValueType>({});
  const [errorState, setErrorState] = React.useState<IRequestFilterError | null>(
    null,
  );
  const [isFilterSaved, setFilterSaved] = React.useState<boolean>(false);

  React.useEffect(() => {
    setOpenState(open);
  }, [open]);

  const resetFilterValue = () => {
    setFilterSaved(false);
    setFilterValue(defaultFilterValue);
    setSavedFilterValue({});
    setErrorState(null);
  };

  const hasSavedFilter = () => {
    return calcFilterCount(savedFilterValue) > 0;
  };

  const ctxValue = {
    open: openState,
    get filterValue() {
      return filterValue;
    },
    get savedFilterValue() {
      return savedFilterValue;
    },
    get error() {
      return errorState;
    },
    get isFilterSaved() {
      return isFilterSaved;
    },
    get hasSavedFilter() {
      return hasSavedFilter();
    },
    getFilterCount() {
      return calcFilterCount(savedFilterValue);
    },
    savedFilterCount() {},
    setOpen: setOpenState,
    setFilterValue: React.useCallback(
      (value: FilterValueType) =>
        setFilterValue((oldValue) => ({ ...oldValue, ...value })),
      [],
    ),
    setError: (error: IRequestFilterError | null) => setErrorState(error),
    submit: () => {
      if (validator) {
        const error = validator(filterValue);
        if (error) {
          if (error.type === 'toast') {
            toast.alert(error.message);
          }

          setErrorState(error);
          return;
        } else {
          setErrorState(null);
        }
      }

      onSubmit && onSubmit(filterValue);
      setSavedFilterValue(filterValue);
      setFilterSaved(true);
      setOpenState(false);
    },
    cancel: () => {
      onCancel && onCancel();
      if (hasSavedFilter()) {
        // 저장된 필터가 있을 경우 저장된 필터로 복원
        setFilterValue(savedFilterValue);
      } else {
        resetFilterValue();
      }

      setOpenState(false);
    },
    reset: () => {
      onReset && onReset();
      resetFilterValue();
    },
    resetTemp: () => {
      setFilterValue({});
    },
  };

  return (
    <div className={clsx(styles.SearchFilter, className)}>
      <SearchFilterContext value={ctxValue}>{children}</SearchFilterContext>
    </div>
  );
}

function calcFilterCount(filterValue: FilterValueType) {
  return (Object.values(filterValue).filter((v) => !!v) || []).length;
}

export default SearchFieldRoot;
