import * as React from 'react';
import { useSearchDialogAction } from '../../hooks/use-search-dialog-action';
import { useSearchKeyword } from '../../model/store';
import { SearchTextField, SearchIndicator } from '../search-field';

export function SearchFieldSwitcher() {
  const [searchKeyword] = useSearchKeyword();
  const searchDialogAction = useSearchDialogAction();
  const submitHandler = () => {
    searchDialogAction.open();
  };
  const clearHandler = () => searchDialogAction.close();

  return searchKeyword ? (
    <SearchIndicator onEnter={submitHandler} onClear={clearHandler} />
  ) : (
    <SearchTextField
      className="w-[300px]"
      onEnter={submitHandler}
      onClear={clearHandler}
    />
  );
}

export default SearchFieldSwitcher;
