import * as React from 'react';
import { clsx } from 'clsx';
import { IconChevronDown } from '@tabler/icons-react';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { getInhouseAppIcon } from '@dop-ui/react/entities/inhouse-apps';
import { getSearchResultElement } from '../result-views';
import { useCurrentAppCode } from '../../../../model/store';
import type { ISearchResultData } from '../../../../model/types';

import styles from '../../styles.module.css';

export interface Props {
  data: ISearchResultData;
  onClickLink?: (url: string) => void;
}

export function IntegratedAppSearchResult({ data, onClickLink }: Props) {
  const [, setAppCode] = useCurrentAppCode();
  const { t } = useTranslation('common');
  const { code, appName } = data;
  const ResultView = getSearchResultElement(data.code);
  const AppIcon = getInhouseAppIcon(code);

  if (!ResultView) {
    return;
  }

  return (
    <div className={clsx(styles.SearchResultView, styles.SearchResultSummary)}>
      <div className={styles.heading}>
        <AppIcon size={28} />
        {appName}
      </div>
      <main className={styles.contents}>
        <ResultView data={data} onClickLink={onClickLink} />
      </main>
      <footer className={styles.footer}>
        <Button
          className={styles.moreBtn}
          shape="round"
          variant="outline"
          size="sm"
          onClick={() => setAppCode(code)}
        >
          <span className={styles.label}>
            {t('search.result.summary.button.more')}
          </span>
          <IconChevronDown stroke={1.5} size={12} />
        </Button>
      </footer>
    </div>
  );
}
