import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useCalendarFilterCreator } from '@/components/search/model/filters/calendar-filter-creator';
import { useCurrentAppCode } from '../../../../model/store';
import { useSearchableAppInfo } from '../../../../hooks/use-search-app-info';
import {
  Primitives as SearchFieldPrimitives,
  TextField,
  PeriodField,
} from '../filter-templates';
import type { SearchFilterCommonProps, TFilterValue } from '../types';

export interface Props extends SearchFilterCommonProps {}

export const ContactSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useCalendarFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFieldPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFieldPrimitives.Header title={appInfo.name} />
      <SearchFieldPrimitives.Container>
        <TextField
          label={t('search.filter.calendar.field.SCHEDULE_NAME.label')}
          placeholder={t(
            'search.filter.calendar.field.SCHEDULE_NAME.placeholder',
          )}
          name="SCHEDULE_NAME"
        />
        <TextField
          label={t('search.filter.calendar.field.ATTENDEES.label')}
          placeholder={t('search.filter.calendar.field.ATTENDEES.placeholder')}
          name="ATTENDEES"
          description={t('search.filter.calendar.field.ATTENDEES.description')}
        />
        <PeriodField.CustomType
          name="PERIOD"
          label={t('search.filter.calendar.field.PERIOD.label')}
        />
        <SearchFieldPrimitives.Footer useFilterReset />
      </SearchFieldPrimitives.Container>
    </SearchFieldPrimitives.Root>
  );
};

export default ContactSearchFilter;
