'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import * as RadioPrimitives from '@dop-ui/react/shared/ui/radio';
import {
  Field as SearchFilterField,
  useSearchFilterContext,
} from '../primitives';

import styles from './styles.module.css';

interface RadioGroupOptions {
  label: string;
  value: string;
}

export interface Props {
  name: string;
  label: string;
  defaultValue?: string;
  options?: RadioGroupOptions[];
  className?: string;
}

export const EMPTY_OPTION = '';
export function SearchFilterRadioGroupField({
  name,
  label,
  defaultValue = EMPTY_OPTION,
  options = [],
  className,
  children,
}: React.PropsWithChildren<Props>) {
  const { filterValue, setFilterValue } = useSearchFilterContext();
  const currentValue = (filterValue[name] as string | null) || defaultValue;
  const isEmptyOption = (value: string) => value === EMPTY_OPTION;
  const changeHandler = (value: string) => {
    if (isEmptyOption(value)) {
      setFilterValue({ [name]: null });
    } else {
      setFilterValue({ [name]: value });
    }
  };

  return (
    <SearchFilterField
      className={clsx(styles.SearchFilter__RadioGroupType, className)}
      label={label}
    >
      <div className={styles.radiogroup}>
        <RadioPrimitives.Root
          name={name}
          value={currentValue}
          onChange={changeHandler}
        >
          {options.map((item, idx) => (
            <RadioPrimitives.Option key={idx} value={item.value}>
              {item.label}
            </RadioPrimitives.Option>
          ))}
        </RadioPrimitives.Root>

        {children}
      </div>
    </SearchFilterField>
  );
}

export default SearchFilterRadioGroupField;
