import { PropsWithChildren } from 'react';
import { Wrapper } from './wrapper';
import { Provider } from './context';

export interface Props {
  value: string;
  disabled?: boolean;
  defaultLabel: string;
  onChange: (value: string) => void;
}

export function Root({ children, ...props }: PropsWithChildren<Props>) {
  return (
    <Provider {...props}>
      <Wrapper>{children}</Wrapper>
    </Provider>
  );
}
