import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useLegalEducationFilterCreator } from '@/components/search/model/filters/legal-education-filter-creator';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  TextField,
} from '../../filter-templates';
import { SelectStatusField } from './fields/status-field';
import { SelectGradeField } from './fields/grade-field';

import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export const LegalEducationSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useLegalEducationFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        <SelectStatusField />
        <SelectGradeField />
        <TextField
          label={t('search.filter.legalEducation.field.NAME.label')}
          placeholder={t('search.filter.legalEducation.field.NAME.placeholder')}
          name="NAME"
        />
        <PeriodField.CustomType
          name="PERIOD"
          label={t('search.common.words.PERIOD')}
        />
        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

export default LegalEducationSearchFilter;
