import * as React from 'react';

interface IContextValue {
  name: string;
  currentValue: string;
  radioDisabled: boolean;
}

interface IContextAction {
  onChangeValue: (value: string) => void;
}

export const RadioContextValue = React.createContext<IContextValue>({
  name: '',
  currentValue: '',
  radioDisabled: false,
});

export const RadioContextAction = React.createContext<IContextAction>({
  onChangeValue: () => undefined,
});

interface Props {
  name: string;
  value: string;
  radioDisabled?: boolean;
  onChange: (value: string) => void;
}

export function Provider({
  name,
  value: _value,
  radioDisabled = false,
  onChange,
  children,
}: React.PropsWithChildren<Props>) {
  const [value, setValue] = React.useState(_value ?? '');

  React.useEffect(() => {
    setValue(_value ?? '');
  }, [_value]);

  const onChangeValue = (value: string) => {
    setValue(value);
    onChange(value);
  };

  const contextValue = {
    name,
    currentValue: value,
    radioDisabled,
  };

  const contextAction = {
    onChangeValue,
  };

  return (
    <RadioContextAction value={contextAction}>
      <RadioContextValue value={contextValue}>{children}</RadioContextValue>
    </RadioContextAction>
  );
}

export const useRadioContextValue = () => React.useContext(RadioContextValue);
export const useRadioContextAction = () => React.useContext(RadioContextAction);
export const useRadioContext = () => ({
  ...useRadioContextValue(),
  ...useRadioContextAction(),
});
