import * as React from 'react';
import type { TFilterValue } from '../../types';
import type { IRequestFilterError } from '@/components/search/model/types';
export type { TFilterValue as FilterValueType } from '../../types';

/**
 * @deprecated
 */
export interface ErrorType {
  /** @property 에러 대상 필드 */
  name: string;
  /** @property 에러 내용 */
  message: string;
}

export interface SearchFilterContextValue {
  open: boolean;
  /** @property 서버에 전송하기 위한 파라메터를 구성하기 위한 필터들 */
  filterValue: TFilterValue;
  savedFilterValue: TFilterValue;
  error: IRequestFilterError | null;
  /** @property 필터 저장여부 */
  isFilterSaved: boolean;
  /** @property 저장된 필터 존재 여부 */
  hasSavedFilter: boolean;
  getFilterCount: () => number;
  setOpen: (open: boolean) => void;
  setFilterValue: (value: TFilterValue) => void;
  setError: (error: IRequestFilterError | null) => void;
  submit: () => void;
  cancel: () => void;
  /** @property 저장된 필터값 초기화 */
  reset: () => void;
  /** @property 서버 전송 전 임시 저장된 필터 값을 초기화 */
  resetTemp: () => void;
}

export const SearchFilterContext =
  React.createContext<SearchFilterContextValue>({
    open: false,
    filterValue: {},
    savedFilterValue: {},
    error: null,
    isFilterSaved: false,
    hasSavedFilter: false,
    getFilterCount: () => 0,
    setOpen: () => {},
    setFilterValue: () => {},
    setError: () => {},
    submit: () => {},
    cancel: () => {},
    reset: () => {},
    resetTemp: () => {},
  });

export const useSearchFilterContext = () =>
  React.useContext(SearchFilterContext);
