import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { IncludedKeywordField } from '../../../filter-templates';

export function PersonalIncludedKeywordField() {
  const { t } = useTranslation('common');
  const options = [
    {
      value: 'usage',
      label: t(
        'search.filter.expenses.PERSONAL.field.includedKeyword.option.USAGE',
      ),
    },
    {
      value: 'receiptType',
      label: t(
        'search.filter.expenses.PERSONAL.field.includedKeyword.option.RECIPT_TYPE',
      ),
    },
    {
      value: 'purpose',
      label: t('search.filter.expenses.field.includedKeyword.option.PURPOSE'),
    },
    {
      value: 'detail-purpose',
      label: t(
        'search.filter.expenses.field.includedKeyword.option.DETAIL_PURPOSE',
      ),
    },
    {
      value: 'file-name',
      label: t(
        'search.filter.expenses.PERSONAL.field.includedKeyword.option.FILE_NAME',
      ),
    },
  ];

  return <IncludedKeywordField.Primitive options={options} />;
}

export default PersonalIncludedKeywordField;
