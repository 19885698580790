import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { TextField } from '@dop-ui/react/shared/ui/text-field';
import {
  Primitives as FilterTemplates,
  SelectField,
} from '../../../../filter-templates';
import {
  SelectMethod,
  SELECT_METHOD,
  type TSelectMethod,
} from './select-method';
import { SelectAppletList } from './select-applet';

interface IAppletFilterValue {
  type: TSelectMethod;
  value: string;
}

export function SelectAppletField({ className }: { className?: string }) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue, error } =
    FilterTemplates.useSearchFilterContext();
  const currentFilterValue = filterValue['APPLET'] as
    | IAppletFilterValue
    | null
    | undefined;
  const currentMethod = currentFilterValue ? currentFilterValue.type : 'SELECT';
  const currentValue = currentFilterValue ? currentFilterValue.value : '';
  const [selectMethodState, setSelectMethodState] =
    React.useState<TSelectMethod>(currentMethod);
  const [selectedValueState, setSelectedValueState] =
    React.useState<string>(currentValue);

  React.useEffect(() => {
    setSelectMethodState(currentMethod);
  }, [currentMethod]);

  React.useEffect(() => {
    setSelectedValueState(currentValue);
  }, [currentValue]);

  const onChangeMethod = (value: TSelectMethod) => {
    setSelectMethodState(value);
    // 선택 방법이 바뀌면 value state도 초기화해준다.
    // setSelectedValueState('');
    setFilterValue({ APPLET: { type: value, value: '' } });
  };
  const changeAppletHandler = (value: string) => {
    // setSelectedValueState(value);
    setFilterValue({ APPLET: { type: selectMethodState, value } });
  };
  const errorOnThis =
    error && error.field === 'APPLET' ? error.message : undefined;

  return (
    <SelectField.Layout
      label={t('search.filter.works.field.APPLET.label')}
      className={className}
    >
      <SelectMethod value={selectMethodState} onChange={onChangeMethod} />
      {selectMethodState === SELECT_METHOD.SELECT && (
        <SelectAppletList
          value={selectedValueState}
          onChange={changeAppletHandler}
        />
      )}
      {selectMethodState === SELECT_METHOD.INPUT && (
        <TextField
          value={selectedValueState}
          onChangeValue={changeAppletHandler}
          errorMessage={errorOnThis}
        />
      )}
    </SelectField.Layout>
  );
}

export default SelectAppletField;
