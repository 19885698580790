import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { IncludedKeywordField } from '../../../filter-templates';

export function EContractIncludedKeywordField() {
  const { t } = useTranslation('common');
  const options = [
    {
      value: 'requester',
      label: t(
        'search.filter.econtract.field.INCLUDED_KEYWORD.option.REQUESTER',
      ),
    },
    {
      value: 'signType',
      label: t(
        'search.filter.econtract.field.INCLUDED_KEYWORD.option.SIGN_TYPE',
      ),
    },
    {
      value: 'remarks',
      label: t('search.filter.econtract.field.INCLUDED_KEYWORD.option.REMARKS'),
    },
  ];

  return <IncludedKeywordField.Primitive options={options} />;
}

export default EContractIncludedKeywordField;
