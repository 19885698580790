import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { IncludedKeywordField } from '../../../filter-templates';

export function CorpCardIncludedKeywordField() {
  const { t } = useTranslation('common');
  const options = [
    {
      value: 'name',
      label: t(
        'search.filter.expenses.CORPORATE_CARD.field.includedKeyword.option.NAME',
      ),
    },
    {
      value: 'usage',
      label: t(
        'search.filter.expenses.CORPORATE_CARD.field.includedKeyword.option.USAGE',
      ),
    },
    {
      value: 'purpose',
      label: t('search.filter.expenses.field.includedKeyword.option.PURPOSE'),
    },
    {
      value: 'detail-purpose',
      label: t(
        'search.filter.expenses.field.includedKeyword.option.DETAIL_PURPOSE',
      ),
    },
    {
      value: 'project',
      label: t('search.filter.expenses.field.includedKeyword.option.PROJECT'),
    },
  ];

  return <IncludedKeywordField.Primitive options={options} />;
}

export default CorpCardIncludedKeywordField;
