import * as React from 'react';
import { clsx } from 'clsx';
import { Field as SearchFilterField } from '../primitives';

import styles from './styles.module.css';

export interface Props {
  label: string;
  className?: string;
}

/**
 * Select 필드 기본 레이아웃
 * @param param0
 * @returns
 */
export function SelectFieldLayout({
  children,
  label,
  className,
}: React.PropsWithChildren<Props>) {
  return (
    <SearchFilterField
      className={clsx(styles.SearchFilter__SelectType, className)}
      label={label}
    >
      <div className={styles.selectgroup}>{children}</div>
    </SearchFilterField>
  );
}

export default SelectFieldLayout;
