'use client';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import * as Template from '../result-templates/primitives';
import type { AppSearchResultProps, IDocsSearchResultItem } from '../types';

export function SearchSummaryDocs({ data, onClickLink }: AppSearchResultProps) {
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <Template.Container>
      {list.map((item: IDocsSearchResultItem) => {
        const linkUrl = `/gw/app/docs/detail/${item.id}`;
        const clickHandler = () => {
          onClickLink && onClickLink(linkUrl);
        };

        return (
          <Template.Article key={item.id}>
            <Template.Title
              href={linkUrl}
              title={item.title}
              onClick={clickHandler}
            />
            <Template.Content
              href={linkUrl}
              content={item.content}
              onClick={clickHandler}
            />

            <Template.Footer>
              <Template.UserInfo name={item.creatorName} useAvatar={false} />
              <Template.FooterItem>
                <span>{t('search.result.docs.version')}</span>
                <span>{item.version}</span>
              </Template.FooterItem>
              <Template.FormatDate date={item.createdAt} />
              <Template.FooterItem
                dangerouslySetInnerHTML={{ __html: item.folderPath }}
              />
            </Template.Footer>
          </Template.Article>
        );
      })}
    </Template.Container>
  );
}

export default SearchSummaryDocs;
