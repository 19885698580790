import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser, IncludedKeywordFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams, 
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface LegalEducationFilterValueType extends TFilterValue {
  STATUS?: string;
  TYPE?: string;
  PURPOSE?: string;
  NUMBER?: string;
  NAME?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface LegalEducationSearchParams extends TSearchParams {
  /** @property 전자결재 상태 (기본값: 'ALL') */
  status: string;
  /** @property 차량구분 (기본값: 'ALL') */
  type: string;
  /** @property 운행목적 (기본값: 'ALL') */
  purpose?: string;
  /** @property 차량번호 */
  number?: string;
  /** @property 증명서 종류 */
  name?: string;
  /** @property 출발지 */
  starting?: string;
  /** @property 도착지 */
  destination?: string;
  /** @property 비고 */
  remarks?: string;
  /** @property 이메일 */
  'log-start-date'?: string;
  'log-end-date'?: string;
}

export class LegalEducationFilterCreator 
  extends SearchFilterCreator<LegalEducationFilterValueType, LegalEducationSearchParams> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const status = this.source['STATUS'] || 'ALL';
    const type = this.source['TYPE'] || 'ALL';
    const purpose = this.source['PURPOSE'] || 'ALL';
    const name = this.source['NAME'];
    const number = this.source['NUMBER'];
    const inKeywordFields = new IncludedKeywordFieldParser(this.source['INCLUDED_KEYWORD']).parse();
    const { fromDate, toDate } = new PeriodFieldParser(this.source['PERIOD']).parse();

    return {
      status,
      type,
      purpose,
      name,
      number,
      ...inKeywordFields,
      'log-start-date': fromDate,
      'log-end-date': toDate,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('NAME', 2, 64)
      .addLengthRule('NUMBER', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('NAME', this.source['NAME'])
      .setFieldValue('NUMBER', this.source['NUMBER'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useVehicleLogFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new LegalEducationFilterCreator(searchKeyword, value);
}
