import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { useRadioContext } from './context';

import styles from './style.module.css';

export interface Props {
  className?: string;
  value: string;
  disabled?: boolean;
  checked?: boolean;
}

export function Option({
  className,
  value,
  disabled = false,
  checked = false,
  children,
}: PropsWithChildren<Props>) {
  const { name, currentValue, radioDisabled, onChangeValue } =
    useRadioContext();

  return (
    <div className={clsx(styles.RadioOption, className)}>
      <input
        className={styles.RadioInput}
        type="radio"
        name={name}
        id={`${name}.${value}`}
        value={value}
        checked={currentValue === value || checked}
        disabled={disabled || radioDisabled}
        onChange={(e) => {
          if (e.target.checked) {
            onChangeValue(value);
          }
        }}
      />
      <label
        htmlFor={`${name}.${value}`}
        className={clsx(styles.RadioLabel, {
          [`${styles.disabled}`]: disabled || radioDisabled,
        })}
      >
        {children}
      </label>
    </div>
  );
}
