'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import {
  Primitives as FilterTemplates,
  SelectField,
} from '../../../../filter-templates';
import { SelectDocFolderMenu } from './select-docs-folder';

type FilterValueType = string | null;
const FIELD_FOLDER_ID = 'FOLDER_ID';
const FIELD_INCLUDE_TYPE = 'INCLUDE_TYPE';

export function SelectFolderIdField({ className }: { className?: string }) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } =
    FilterTemplates.useSearchFilterContext();
  const currentFolderIdValue = filterValue[FIELD_FOLDER_ID] as
    | string
    | null
    | undefined;
  const currentIncludeTypeValue = filterValue[FIELD_INCLUDE_TYPE] as
    | FilterValueType
    | undefined;

  const changeFolderIdHandler = (value: string) => {
    if (value === '') {
      setFilterValue({ [FIELD_FOLDER_ID]: null });
    } else {
      setFilterValue({
        [FIELD_FOLDER_ID]: value,
      });
    }
  };
  const changeIncludeTypeHandler = (checked: boolean) => {
    if (checked) {
      setFilterValue({ [FIELD_INCLUDE_TYPE]: 'include' });
    } else {
      setFilterValue({
        [FIELD_INCLUDE_TYPE]: null,
      });
    }
  };

  return (
    <SelectField.Layout
      label={t('search.filter.docs.filed.FOLDER_ID.label')}
      className={className}
    >
      <SelectDocFolderMenu
        onChange={changeFolderIdHandler}
        value={currentFolderIdValue || ''}
      />
      <CheckBox
        id={FIELD_INCLUDE_TYPE}
        defaultChecked={currentIncludeTypeValue === 'include'}
        label={t('search.filter.docs.filed.INCLUDE_TYPE.label')}
        onChange={changeIncludeTypeHandler}
      />
    </SelectField.Layout>
  );
}

export default SelectFolderIdField;
