'use client';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { highlightText } from '@dop-ui/react/shared/lib/utils';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import { useSearchKeyword } from '../../../../model/store';
import {
  ApprovalItemTypeTemplate,
  Primitives as TemplatePrimitives,
} from '../result-templates';
import { type StatusTagType } from '../result-templates/primitives/status-tag';
import type { AppSearchResultProps, IHrSearchResultItem } from '../types';

export function SearchResultHR({ data, onClickLink }: AppSearchResultProps) {
  const [searchKeyword] = useSearchKeyword();
  const { t } = useTranslation('common');

  const {
    data: { list = [] },
  } = data;

  return (
    <TemplatePrimitives.Container>
      {list.map((item: IHrSearchResultItem) => {
        const status: StatusTagType = convertStatus(item.status);

        return (
          <ApprovalItemTypeTemplate.Container
            key={item.id}
            status={status}
            statusText={t(`search.result.hr.status.${item.status}`)}
            onClickLink={onClickLink}
          >
            <ApprovalItemTypeTemplate.Title
              href={``}
              title={highlightText(item.name, searchKeyword)}
              onClick={onClickLink}
            />

            <ApprovalItemTypeTemplate.Footer>
              {/* 발급요청일 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.hr.requestDate')}
                value={formatDate({
                  date: item.requestDate,
                  format: 'YYYY-MM-DD',
                })}
              />

              {/* 용도 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.hr.purpose')}
                value={highlightText(item.purpose, searchKeyword)}
              />

              {/* 제출처 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.hr.submitPlace')}
                value={highlightText(item.submitPlace, searchKeyword)}
              />

              {/* 전자결재 번호 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.hr.approvalNumber')}
                value={highlightText(item.approvalNumber, searchKeyword)}
              />

              {/* 유효일자 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.hr.validDate')}
                value={item.validDate}
              />

              {/* 비고 */}
              <ApprovalItemTypeTemplate.FooterItem
                label={t('search.result.hr.remarks')}
                value={highlightText(item.remarks, searchKeyword)}
              />
            </ApprovalItemTypeTemplate.Footer>
          </ApprovalItemTypeTemplate.Container>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

function convertStatus(status: string): StatusTagType {
  return {
    APPROVED: 'COMPLETE',
    WRITING: 'DRAFT',
    IN_APPROVAL: 'INPROGRESS',
  }[status] as StatusTagType;
}

export default SearchResultHR;
