'use client';

import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';
import { fetchDepartmentList } from '@/components/search/api/fetch-report-dept-list';
import { Primitives as FilterTemplates } from '../../../../filter-templates';

interface ReportFolderItem {
  id: number;
  name: string;
}
interface ReportDeptItem {
  id: number;
  name: string;
  folders: ReportFolderItem[];
}

interface FilterTypes {
  deptId: string;
  folderName: string;
}

export interface Props {}

const FIELD_NAME = 'FOLDER_INFO';
export function SelectReportDept() {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } =
    FilterTemplates.useSearchFilterContext();
  const currentValue = filterValue[FIELD_NAME] as
    | FilterTypes
    | null
    | undefined;
  const deptIdState = currentValue?.deptId || '';
  const folderNameState = currentValue?.folderName || '';
  const {
    data: options,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['report-department'],
    queryFn: async () => {
      const { data } = await fetchDepartmentList();
      return data as ReportDeptItem[];
    },
  });

  if (isError) return null;
  if (!options) return null;

  // const defaultLabel = isLoading ? 'Loading...' : t('search.common.words.ALL');
  const deptOptions = options.map((item) => ({
    value: `${item.id}`,
    label: item.name,
  }));
  const mergedDeptOptions = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    ...deptOptions,
  ];
  const isSelectedDept = deptIdState !== '' || deptIdState !== null;
  const reportOptions = isSelectedDept
    ? (options.find((item) => `${item.id}` === deptIdState)?.folders || []).map(
        (item) => ({
          value: `${item.name}`,
          label: item.name,
        }),
      )
    : [];

  const changeDeptIdHandler = (value: string) => {
    setFilterValue({ [FIELD_NAME]: { deptId: value, folderName: null } });
  };
  const changeFolderHandler = (value: string) => {
    setFilterValue({
      [FIELD_NAME]: { deptId: deptIdState, folderName: value },
    });
  };

  const defaultDeptIdLabel = () => {
    if (isLoading) return 'Loading...';

    const selectedOption = options.find((item) => `${item.id}` === deptIdState);

    if (selectedOption) return selectedOption.name;

    return t('search.common.words.ALL');
  };

  const defaultFolderNameLabel = () => {
    if (isLoading) return 'Loading...';

    const selectedOption = (
      options.find((item) => `${item.id}` === deptIdState)?.folders || []
    ).find((item) => item.name === folderNameState);

    if (selectedOption) return selectedOption.name;

    return t('search.common.words.ALL');
  };

  return (
    <>
      {/** 부서 선택 */}
      <SelectPrimitives.Root
        value={deptIdState}
        defaultLabel={defaultDeptIdLabel()}
        onChange={changeDeptIdHandler}
      >
        <SelectPrimitives.Trigger />
        <SelectPrimitives.Content className="z-[999]">
          {mergedDeptOptions.map((item, idx) => (
            <SelectPrimitives.Option key={idx} value={`${item.value}`}>
              {item.label}
            </SelectPrimitives.Option>
          ))}
        </SelectPrimitives.Content>
      </SelectPrimitives.Root>
      {isSelectedDept && reportOptions.length > 0 && (
        <SelectPrimitives.Root
          value={folderNameState}
          defaultLabel={defaultFolderNameLabel()}
          onChange={changeFolderHandler}
        >
          <SelectPrimitives.Trigger />
          <SelectPrimitives.Content className="z-[999]">
            {reportOptions.map((item, idx) => (
              <SelectPrimitives.Option key={idx} value={`${item.value}`}>
                {item.label}
              </SelectPrimitives.Option>
            ))}
          </SelectPrimitives.Content>
        </SelectPrimitives.Root>
      )}
    </>
  );
}
