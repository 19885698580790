'use client';

import * as React from 'react';
import { FooterItem } from './footer-item';
import styles from './styles.module.css';

export interface Props {
  paths: string[];
}

export function ContentPath({ paths }: Props) {
  return (
    <FooterItem className={styles.SearchResult__Article__ContentPath}>
      {paths.map((path, index) => (
        <span
          key={index}
          className={styles.item}
          dangerouslySetInnerHTML={{ __html: path }}
        />
      ))}
    </FooterItem>
  );
}

export default ContentPath;
