'use client';

import { clsx } from 'clsx';
import { SEARCHABLE_APP_CODES } from '../../../model/constants';
import styles from '../styles.module.css';

export interface Props {
  label: string;
  code?: string | null;
  isActive?: boolean;
  onSelect?: (code: string) => void;
}

export function TabItem({
  code = SEARCHABLE_APP_CODES.INTEGRATED,
  label,
  isActive = false,
  onSelect,
}: Props) {
  const clickHandler = () => {
    onSelect && onSelect(code);
  };
  return (
    <div
      role="button"
      className={clsx(styles.CateTab, isActive && styles.active)}
      onClick={clickHandler}
      onKeyUp={clickHandler}
      tabIndex={0}
    >
      {label}
    </div>
  );
}

export default TabItem;
