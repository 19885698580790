import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser, IncludedKeywordFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams,
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface ApprovalFilterValueType extends TFilterValue {
  DATE_TYPE?: string;
  PERIOD?: IPeriodTemplateFieldValues;
  SERACH_CONDITION?: string;
  TITLE?: string;
  DRAFTER?: string;
  DRAFTER_DEPT?: string;
  ACTIVITY_USERS?: string;
  DOC_NUM?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface ApprovalSearchParams extends TSearchParams {
  /** @property 검색 메뉴 구분 (approval 값 고정) */
  type: string;
  /** @property 검색 기준 일 (draftedAt: 기안일, completedAt: 결재일, 기본값: draftedAt) */
  dateType: string;
  /** @property 양식제목 */
  formName?: string;
  /** @property 기안자명 */
  drafterName?: string;
  /** @property 기안부서 */
  drafterDeptName?: string;
  /** @property 결재선 */
  activityUserNames?: string;
  /** @property 문서번호 */
  docNum?: string;
  /** @property 제목 */
  title?: string;
  /** @property 본문 */
  docBody?: string;
  /** @property 첨부파일명 */
  attachFileNames?: string;
  /** @property 첨부파일 본문 */
  attachFileContents?: string;
  /** @property 검색 조건 (OR: 하나만 만족, AND: 모두 만족) */
  searchOption: string;
  /** @property ?? (as-is 기본값: 'all') */
  searchTerm: string;
  fromDate: string;
  toDate: string;
}

export class ApprovalFilterCreator 
  extends SearchFilterCreator<ApprovalFilterValueType, ApprovalSearchParams> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const dateType = this.source['DATE_TYPE'] === 'COMPLETED' ? 'completedAt' : 'draftedAt';
    const formName = this.source['TITLE'];
    const periodFiels = new PeriodFieldParser(this.source['PERIOD']).parse();
    const drafterName = this.source['DRAFTER'];
    const drafterDeptName = this.source['DRAFTER_DEPT'];
    const activityUserNames = this.source['ACTIVITY_USERS'];
    const docNum = this.source['DOC_NUM'];
    const inKeywordField = new IncludedKeywordFieldParser(
      this.source['INCLUDED_KEYWORD'],
      { CONTENT: 'docBody' },
    ).parse();
    const searchOption = this.source['SERACH_CONDITION'] || 'OR';

    return {
      type: 'approval',
      dateType,
      formName,
      drafterName,
      drafterDeptName,
      activityUserNames,
      docNum,
      searchOption,
      searchTerm: 'all',
      ...inKeywordField,
      ...periodFiels,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('TITLE', 2, 64)
      .addLengthRule('DRAFTER', 2, 64)
      .addLengthRule('DRAFTER_DEPT', 2, 64)
      .addLengthRule('ACTIVITY_USERS', 2, 64)
      .addLengthRule('DOC_NUM', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('TITLE', this.source['TITLE'])
      .setFieldValue('DRAFTER', this.source['DRAFTER'])
      .setFieldValue('DRAFTER_DEPT', this.source['DRAFTER_DEPT'])
      .setFieldValue('ACTIVITY_USERS', this.source['ACTIVITY_USERS'])
      .setFieldValue('DOC_NUM', this.source['DOC_NUM'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useApprovalFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new ApprovalFilterCreator(searchKeyword, value);
}
