import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import type {
  TFilterValue,
  IRequestFilterError,
  TSearchParams,
} from '../types';
import { FieldValidator } from './field-validator';

export interface ContactFilterValueType extends TFilterValue {
  ADDRESS_BOOK?: { ownerType: string; deptId?: string };
  USERNAME?: string;
  COMPANY_NAME?: string;
  EMAIL?: string;
  PHONE?: string;
  COMPANY_TEL?: string;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface ContactSearchParams extends TSearchParams {
  /** @property 주소록 구분 */
  ownerType?: string;
  /** @property 이름 */
  name?: string;
  /** @property 회사 이름 */
  companyName?: string;
  /** @property 이메일 */
  email?: string;
  /** @property 휴대폰번호 */
  mobileNo?: string;
  /** @property 회사 전화번호 */
  officeTel?: string;
  /** @property 부서 ID, ownerType = DEPARTMENT 일 때만 해당 */
  deptId?: string;
  /** @property 정렬컬럼. 고정값: nameInitialConsonant */
  property: string;
  /** @property 정렬, 고정값: 'asc' */
  direction: string;
}

export class ContactFilterCreator extends SearchFilterCreator<
  ContactFilterValueType,
  ContactSearchParams
> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const { ownerType, deptId } = this.source['ADDRESS_BOOK'] || {
      ownerType: 'USER',
    };
    return {
      ownerType,
      name: this.source['USERNAME'],
      companyName: this.source['COMPANY_NAME'],
      email: this.source['EMAIL'],
      mobileNo: this.source['PHONE'],
      officeTel: this.source['COMPANY_TEL'],
      deptId: deptId,
      property: 'nameInitialConsonant',
      direction: 'asc',
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('USERNAME', 2, 64)
      .addLengthRule('EMAIL', 2, 255)
      .addLengthRule('PHONE', 1, 40)
      .addLengthRule('COMPANY_NAME', 1, 255)
      .addLengthRule('COMPANY_TEL', 1, 40)
      .setFieldValue('USERNAME', this.source['USERNAME'])
      .setFieldValue('EMAIL', this.source['EMAIL'])
      .setFieldValue('PHONE', this.source['PHONE'])
      .setFieldValue('COMPANY_NAME', this.source['COMPANY_NAME'])
      .setFieldValue('COMPANY_TEL', this.source['COMPANY_TEL'])
      .validate();
  }
}

export function useContactFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) =>
    new ContactFilterCreator(searchKeyword, value);
}
