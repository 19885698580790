import { fetch } from '../../../shared/lib/fetch/client';

const API_URL = '/api/portal/common/auth/multi-company/sso-token';

interface TokenResponse {
  code: string;
  message: string;
  data: { ssoToken: string };
}

export async function getMultiCompanySSOToken(targetCompanyUuid: string) {
  const query = new URLSearchParams({ companyUuid: targetCompanyUuid });

  const response = await fetch(`${API_URL}?${query.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData = (await response.json()) as TokenResponse;

  if (response.ok) {
    return responseData.data.ssoToken;
  } else {
    throw new Error(responseData.message);
  }
}
