import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useHRFilterCreator } from '@/components/search/model/filters/hr-filter-creator';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  TextField,
  SelectField,
} from '../../filter-templates';
import { HRIncludedKeywordField } from './fileds/included-keyword-field';
import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export const HRSearchFilter: React.ComponentType<SearchFilterCommonProps> = ({
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useHRFilterCreator();
  const options = useSelectStatusOptions();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        <SelectField.Simple
          label={t('search.filter.hr.field.STATUS.label')}
          name="STATUS"
          options={options}
        />
        <TextField
          label={t('search.filter.hr.field.SUBMIT_PLACE.label')}
          placeholder={t('search.filter.hr.field.SUBMIT_PLACE.placeholder')}
          name="SUBMIT_PLACE"
        />
        <HRIncludedKeywordField />
        <PeriodField.CustomType
          name="VALID_DATE"
          label={t('search.filter.hr.field.VALID_DATE.label')}
        />
        <PeriodField.CustomType
          name="REQUEST_DATE"
          label={t('search.filter.hr.field.REQUEST_DATE.label')}
        />
        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

function useSelectStatusOptions() {
  const { t } = useTranslation('common');

  return [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.result.hr.status.WRITING'),
      value: 'WRITING',
    },
    {
      label: t('search.result.hr.status.IN_APPROVAL'),
      value: 'IN_APPROVAL',
    },
    {
      label: t('search.result.hr.status.APPROVED'),
      value: 'APPROVED',
    },
  ];
}

export default HRSearchFilter;
