import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser, IncludedKeywordFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type {
  TFilterValue,
  TSearchParams,
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface DocsFilterValueType extends TFilterValue {
  FOLDER_ID?: string;
  INCLUDE_TYPE?: 'include' | null;
  CREATOR?: string;
  DOC_NO?: string;
  RESERVED_PERIOD?: string;
  PERIOD?: IPeriodTemplateFieldValues;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
}

/**
 * 문서관리 검색 파라메터 인터페이스
 */
export interface DocsSearchParams extends TSearchParams {
  /** @property 문서함 ID */
  folderId?: string;
  /** @property 하위문서 포함여부 */
  includeType?: string;
  /** @property 등록자 */
  creatorName?: string;
  /** @property 등록자 */
  docNum?: string;
  /** @property 제목 */
  title?: string;
  /** @property 본문 */
  content?: string;
  /** @property 첨부파일명 */
  attachFileNames?: string;
  /** @property 첨부파일 내용 */
  attachFileContent?: string;
  /** @property 보존연한 */
  docsYear?: string;
  fromDate: string;
  toDate: string;
}

export class DocsFilterCreator extends SearchFilterCreator<
  DocsFilterValueType,
  DocsSearchParams
> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const folderId = this.source['FOLDER_ID'];
    const includeType = this.source['INCLUDE_TYPE'] || 'none';
    const creatorName = this.source['CREATOR'];
    const docNum = this.source['DOC_NO'];
    const docYear = this.__parsePreservedPeriodField();
    const periodFields = new PeriodFieldParser(this.source['PERIOD']).parse();
    const inKeywordFields = new IncludedKeywordFieldParser(
      this.source['INCLUDED_KEYWORD'],
    ).parse();
    return {
      folderId,
      includeType,
      creatorName,
      docNum,
      docYear,
      ...periodFields,
      ...inKeywordFields,
    };
  }

  private __parsePreservedPeriodField() {
    const field = this.source['RESERVED_PERIOD'];

    const periodField: number = {
      PERMANENT: 0,
      '1Y': 1,
      '3Y': 3,
      '5Y': 5,
      '10Y': 10,
    }[field];

    return `${periodField}`;
  }
  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('CREATOR', 2, 64)
      .addLengthRule('DOC_NO', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('CREATOR', this.source['CREATOR'])
      .setFieldValue('DOC_NO', this.source['DOC_NO'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useDocsFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new DocsFilterCreator(searchKeyword, value);
}
