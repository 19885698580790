import * as React from 'react';
import type {
  AppSearchResultProps,
  IBoardTypeSearchResultItem,
} from '../types';
import { SearchResultBoardTemplate } from '../result-templates/board-type';

export function SearchResultBoard({ data, onClickLink }: AppSearchResultProps) {
  return (
    <SearchResultBoardTemplate
      data={data}
      linkResolver={(item: IBoardTypeSearchResultItem) =>
        `/gw/app/board/${item.boardId}/post/${item.id}`
      }
      onClickLink={onClickLink}
    />
  );
}

export default SearchResultBoard;
