'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import {
  Primitives as FilterTemplates,
  SelectField,
} from '../../../../filter-templates';
import { SelectCommunity } from './my-community-list';
import { SelectCommunityBoardMenu } from './community-board-menu';

const FIELD_NAME = 'BOARD_IDS';
export const OWNER_TYPE = {
  ALL: '',
  COMPANY: 'Company',
  DEPARTMENT: 'Department',
} as const;
export type OwnerType = (typeof OWNER_TYPE)[keyof typeof OWNER_TYPE];

interface FilterValueType {
  /** @property 커뮤니티 ID */
  ownerId: string;
  boardIds?: string[];
}

export function SelectCommuntyBoardField({
  className,
}: {
  className?: string;
}) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } =
    FilterTemplates.useSearchFilterContext();
  const currentFilterValue = filterValue[FIELD_NAME] as
    | FilterValueType
    | undefined;
  const { ownerId = '', boardIds = [] } = currentFilterValue || {};

  const changeOwnerIdHandler = (value: string) => {
    if (value === '') {
      setFilterValue({ [FIELD_NAME]: null });
    } else {
      setFilterValue({
        [FIELD_NAME]: {
          ownerId: value,
        },
      });
    }
  };
  const changeBoardIdHandler = (value: string) => {
    setFilterValue({
      [FIELD_NAME]: {
        ownerId,
        boardIds: [value],
      },
    });
  };

  return (
    <SelectField.Layout
      label={t(`search.filter.community.field.BOARD_IDS.label`)}
      className={className}
    >
      <SelectCommunity onChange={changeOwnerIdHandler} value={ownerId} />
      {ownerId && (
        <SelectCommunityBoardMenu
          communityId={ownerId}
          onChange={changeBoardIdHandler}
          value={boardIds[0]}
        />
      )}
    </SelectField.Layout>
  );
}

export default SelectCommuntyBoardField;
