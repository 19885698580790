import { PropsWithChildren } from 'react';
import { Provider } from './context';

export interface Props {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export function Root({ children, ...props }: PropsWithChildren<Props>) {
  return (
    <Provider {...props} radioDisabled={props.disabled}>
      {children}
    </Provider>
  );
}
