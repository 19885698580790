'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import { useSearchFilterContext } from './context';
import styles from './styles.module.css';

export interface Props {
  className?: string;
}

export function SearchFilterContainer({
  children,
  className,
}: React.PropsWithChildren<Props>) {
  const { open } = useSearchFilterContext();

  if (!open) {
    return null;
  }

  return (
    <div className={clsx(styles.SearchFilter__Container, className)}>
      {children}
    </div>
  );
}

export default SearchFilterContainer;
