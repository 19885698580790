import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectField } from '../../../filter-templates';

export function SelectStatusField() {
  const { t } = useTranslation('common');
  const options = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.result.legalEducation.status.WAITING'),
      value: 'WAITING',
    },
    {
      label: t('search.result.legalEducation.status.IN_PROGRESS'),
      value: 'IN_PROGRESS',
    },
    {
      label: t('search.result.legalEducation.status.COMPLETED'),
      value: 'COMPLETED',
    },
  ];

  return (
    <SelectField.Simple
      label={t('search.filter.legalEducation.field.STATUS.label')}
      name="STATUS"
      options={options}
    />
  );
}

export default SelectStatusField;
