import type {
  TFilterValue,
  IRequestFilter,
  IRequestFilterError,
  TSearchParams,
} from '../types';

export class SearchFilterCreator<
  S extends TFilterValue,
  P extends TSearchParams,
> {
  constructor(
    protected _keyword: string,
    protected _source: S,
  ) {}

  get keyword() {
    return this._keyword;
  }

  get source() {
    return this._source;
  }

  create(): IRequestFilter {
    return {
      source: this.source,
      params: this.__parseParams({
        ...this._createParams(),
        keyword: this.keyword,
      }),
    };
  }

  validate(): IRequestFilterError | null {
    return null;
  }

  protected _createParams(): P {
    return;
  }

  private __parseParams(params: P): P {
    return Object.keys(params).reduce((acc: TSearchParams, cur: string) => {
      if (typeof params[cur] !== 'undefined') {
        acc[cur] = params[cur];
      }
      return acc;
    }, {} as TSearchParams) as P;
  }
}
