import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';

export const OWNER_TYPE = {
  USER: 'USER',
  COMPANY: 'COMPANY',
  DEPARTMENT: 'DEPARTMENT',
} as const;
export type OwnerType = (typeof OWNER_TYPE)[keyof typeof OWNER_TYPE];

export interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export function SelectOwnerType({ value = '', onChange }: Props) {
  const { t } = useTranslation('common');
  const [valueState, setValueState] = React.useState<string>(value);

  React.useEffect(() => {
    setValueState(value);
  }, [value]);

  const changeHandler = (value: string) => {
    onChange && onChange(value);
    setValueState(value);
  };

  const options = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.filter.contact.field.OWNER_TYPE.option.USER'),
      value: OWNER_TYPE.USER,
    },
    {
      label: t('search.filter.contact.field.OWNER_TYPE.option.COMPANY'),
      value: OWNER_TYPE.COMPANY,
    },
    {
      label: t('search.filter.contact.field.OWNER_TYPE.option.DEPARTMENT'),
      value: OWNER_TYPE.DEPARTMENT,
    },
  ];

  const defaultOwnerType = options.find((item) => item.value === valueState);

  return (
    <SelectPrimitives.Root
      value={valueState}
      defaultLabel={defaultOwnerType.label}
      onChange={changeHandler}
    >
      <SelectPrimitives.Trigger />
      <SelectPrimitives.Content className="z-[999]">
        {options.map((item, idx) => (
          <SelectPrimitives.Option key={idx} value={`${item.value}`}>
            {item.label}
          </SelectPrimitives.Option>
        ))}
      </SelectPrimitives.Content>
    </SelectPrimitives.Root>
  );
}

export default SelectOwnerType;
