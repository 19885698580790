'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectField } from '../../../../filter-templates';
import { SelectReportDept } from './select-report-dept';

export function SelectFolderIdField({ className }: { className?: string }) {
  const { t } = useTranslation('common');

  return (
    <SelectField.Layout
      label={t('search.filter.report.filed.FOLDER_ID.label')}
      className={className}
    >
      <SelectReportDept />
    </SelectField.Layout>
  );
}

export default SelectFolderIdField;
