/* eslint-disable import/no-named-as-default-member */

import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { FormatDateProps } from '.';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const locales = {
  ko: () => import('dayjs/locale/ko'),
  en: () => import('dayjs/locale/en'),
  ja: () => import('dayjs/locale/ja'),
  zh_CN: () => import('dayjs/locale/zh-cn'),
  zh_TW: () => import('dayjs/locale/zh-tw'),
  vi: () => import('dayjs/locale/vi'),
};

export interface FormatDateWithLocaleProps extends FormatDateProps {
  locale: string;
}

export interface RelativeFormatProps {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  locale: string;
  type?: 'NOTIFICATION' | 'CHAT';
}

const isValidLocale = (locale: string): locale is keyof typeof locales => {
  return locale in locales;
};

const loadLocale = async (locale: keyof typeof locales) => {
  try {
    await locales[locale]();
    const language = locale.toLowerCase().replace('_', '-');
    if (language === dayjs.locale()) return;
    dayjs.locale(language);
  } catch (e) {
    console.log('date > relativeTime err', e);
    return;
  }
};

export const formatDateWithLocale = async ({
  date,
  format,
  locale,
}: FormatDateWithLocaleProps) => {
  const LOCALE = isValidLocale(locale) ? locale : 'ko';

  await loadLocale(LOCALE);

  const parsedDate = dayjs(date);
  if (!dayjs.isDayjs(parsedDate)) return '';

  return parsedDate.format(format);
};

// TODO: 통합 알림 > 알림 표기가 아직 미확정인 상태. 추후 확정되면 알맞게 수정 필요
export const formatRelativeTime = async ({
  date,
  locale,
  type = 'NOTIFICATION',
}: RelativeFormatProps): Promise<string> => {
  const LOCALE = isValidLocale(locale) ? locale : 'ko';

  await loadLocale(LOCALE);

  const now = dayjs();
  const parsedDate = dayjs(date);
  // relative time이 노출되는 케이스가 다름. 유형별로 정의
  if (type === 'NOTIFICATION') {
    if (!dayjs.isDayjs(parsedDate)) return '';
    if (parsedDate.isSame(now, 'day')) {
      return parsedDate.fromNow();
    } else {
      if (parsedDate.isSame(now, 'year')) {
        return parsedDate.format('MM-DD(ddd) HH:mm');
      } else {
        return parsedDate.format('YYYY-MM-DD(ddd) HH:mm');
      }
    }
  } else if (type === 'CHAT') {
    if (!dayjs.isDayjs(parsedDate)) return '';
    if (parsedDate.isSame(now, 'day')) {
      return parsedDate.fromNow();
    } else {
      if (parsedDate.isSame(now, 'year')) {
        return parsedDate.format('MM-DD');
      } else {
        return parsedDate.format('YYYY-MM-DD');
      }
    }
  } else return '';
};

export const formatFileNameWithDate = (
  fileName: string,
  date: Date,
  fileExtension?: string,
) => {
  return `${fileName}${dayjs(date).format(
    `_YYYY-MM-DD_HH-mm`,
  )}.${fileExtension ?? ''}`;
};
