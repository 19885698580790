import { I18nText } from '@dop-ui/react/shared/lib/i18n/client';
import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type {
  TFilterValue,
  TSearchParams,
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

interface IAppletFilterValue {
  type: 'SELECT' | 'INPUT';
  value: string;
}

export interface TWorksFilterValue extends TFilterValue {
  APPLET?: IAppletFilterValue;
  CREATOR?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface IWorksSearchParams extends TSearchParams {
  /** @property 애플릿 ID */
  appletId?: string;
  /** @property 등록자 */
  authorName?: string;
  /** @property 포함하는 단어 > 텍스트 ('true' | 'false') */
  content?: string;
  /** @property 포함하는 단어 > 댓글 ('true' | 'false') */
  comments?: string;
  /** @property 포함하는 단어 > 첨부파일명 ('true' | 'false') */
  attachFileNames?: string;
  /** @property 포함하는 단어 > 첨부파일내용 ('true' | 'false') */
  attachFileContents?: string;
  /** @property 포함하는 단어 > 활동기록 ('true' | 'false') */
  activityContents?: string;
  /** @property 포함하는 단어 > 키워드 */
  keyword?: string;
  /** @property 검색 시작 일자 */
  fromDate?: string;
  /** @property 검색 종료 일자 */
  toDate?: string;
}

export class WorksFilterCreator extends SearchFilterCreator<
  TWorksFilterValue,
  IWorksSearchParams
> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const applet = this._parseAppletField();
    const authorName = this.source['CREATOR'];
    const keyword = this.keyword;
    const inKeywordFields = this._parseIncludedKeywordField();
    const period = new PeriodFieldParser(this.source['PERIOD']).parse();

    return {
      authorName,
      keyword,
      ...applet,
      ...period,
      ...inKeywordFields,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();

    const appletFieldValue = this.source['APPLET'];
    if (appletFieldValue && appletFieldValue.type === 'INPUT') {
      fieldValidator
        .addRules('APPLET', [
          {
            ruleFn: (value: IAppletFilterValue) =>
              !(
                value &&
                value.type === 'INPUT' &&
                (value.value || '').length === 0
              ),
            errorMessage: (
              <I18nText i18nKey="search.filter.works.error.requiredApplet" />
            ),
          },
        ])
        .setFieldValue('APPLET', appletFieldValue);
    }

    fieldValidator
      .addLengthRule('CREATOR', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('CREATOR', this.source['CREATOR'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD']);

    return fieldValidator.validate();
  }

  protected _parseAppletField() {
    const applet = this.source['APPLET'];

    return {
      appletId: applet && applet.type === 'SELECT' ? applet.value : undefined,
      appletName: applet && applet.type === 'INPUT' ? applet.value : undefined,
    };
  }

  protected _parseIncludedKeywordField() {
    const sourceData = this.source['INCLUDED_KEYWORD'];
    if (!sourceData) return '';

    const reducedData = sourceData.checked.reduce(
      (acc: Record<string, string>, cur: string) => {
        if (cur === 'TEXT') return { ...acc, content: 'true' };
        if (cur === 'COMMENT') return { ...acc, comments: 'true' };
        if (cur === 'ACTIVITY_LOG') return { ...acc, activityContents: 'true' };
        if (cur === 'FILE_NAME') return { ...acc, attachFileNames: 'true' };
        if (cur === 'FILE_CONTENT')
          return { ...acc, attachFileContents: 'true' };

        return acc;
      },
      {},
    );

    return {
      content: 'false',
      activityContents: 'false',
      attachFileNames: 'false',
      attachFileContents: 'false',
      ...reducedData,
    };
  }
}

export function useWorksFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new WorksFilterCreator(searchKeyword, value);
}
