import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import * as basicManagementPages from '../../pages/basic-management';
import { MenuInfo } from '../../types';
import { codes, paths } from '../main';
import * as basicManagementPaths from './paths';

const i18nNamespace = 'component';

export const menuKeyPath = paths.BASIC_MANAGEMENT;

export const menuInfo: MenuInfo[] = [
  {
    menuCode: codes.BASIC_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.basicManagement.service"
      />
    ),
    menuPath: basicManagementPaths.SERVICE_PATH,
    sortOrder: 0,
    children: [
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.service.info"
          />
        ),
        menuPath: basicManagementPaths.SERVICE_INFO_PATH,
        sortOrder: 0,
        page: <basicManagementPages.ServiceInfoPage />,
      },
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.service.business"
          />
        ),
        menuPath: basicManagementPaths.SERVICE_BUSINESS_PATH,
        sortOrder: 0,
        page: <basicManagementPages.BusineesInfoPage />,
      },
    ],
  },
  {
    menuCode: codes.BASIC_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.basicManagement.serviceApp"
      />
    ),
    menuPath: basicManagementPaths.SERVICE_APP_PATH,
    sortOrder: 1,
    children: [
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.serviceApp.employee"
          />
        ),
        menuPath: basicManagementPaths.SERVICE_APP_EMPLOYEE_PATH,
        sortOrder: 0,
        page: <basicManagementPages.EmpPortalAppManagementPage />,
      },
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.serviceApp.business"
          />
        ),
        menuPath: basicManagementPaths.SERVICE_APP_BUSINESS_PATH,
        sortOrder: 1,
        page: <basicManagementPages.BusinessPortalAppManagementPage />,
      },
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.serviceApp.serviceMap"
          />
        ),
        menuPath: basicManagementPaths.SERVICE_APP_MAP_PATH,
        sortOrder: 2,
        page: <basicManagementPages.ServiceMapPage />,
      },
    ],
  },
  {
    menuCode: codes.BASIC_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.basicManagement.manage"
      />
    ),
    menuPath: basicManagementPaths.MANAGE_PATH,
    sortOrder: 2,
    children: [
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.manage.schedule"
          />
        ),
        menuPath: basicManagementPaths.MANAGE_SCHEDULE_PATH,
        sortOrder: 0,
        page: <basicManagementPages.CompanyScheduleManagementPage />,
      },
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.manage.popup"
          />
        ),
        menuPath: basicManagementPaths.MANAGE_POPUP_PATH,
        page: <basicManagementPages.PopupAnnouncementPage />,
        sortOrder: 1,
      },
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.manage.profileCard"
          />
        ),
        menuPath: basicManagementPaths.MANAGE_PROFILE_PATH,
        sortOrder: 2,
        page: <basicManagementPages.ProfileManagementPage />,
      },
      {
        menuCode: codes.BASIC_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.basicManagement.manage.logoAndTheme"
          />
        ),
        menuPath: basicManagementPaths.MANAGE_LOGO_PATH,
        sortOrder: 3,
        page: <basicManagementPages.LogoThemeManagementPage />,
      },
    ],
  },
];
