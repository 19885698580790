import type {
  TSearchType,
  TSearchableAppType,
  ISearchResultRespose,
  ISearchResultData,
  IMailIntegratedSearchRequestParams,
  IMailSearchResultItem,
  IMailTypePageInfo,
  IRequestFilter,
} from '../types';

/**
 * Mail 응답 인터페이스
 */
export interface MailSearchResultResponse extends ISearchResultRespose {
  data: {
    pageInfo: IMailTypePageInfo;
    messageList: IMailSearchResultItem[];
  };
}

/**
 * 메일 검색 path resolver
 * @param path
 * @param type
 * @param params
 * @returns
 */
export function resolvePath(path: string) {
  return path;
}

/**
 * 메일 응답 resolver
 * @param appInfo
 * @param resp
 * @returns
 */
export function resolveResponse(appInfo: TSearchableAppType, resp: unknown) {
  const respData = resp as MailSearchResultResponse;

  return {
    code: appInfo.code,
    appName: appInfo.name,
    data: {
      list: (respData.data && respData.data.messageList) || [],
      page: (respData.data &&
        respData.data.pageInfo && {
          page: respData.data.pageInfo.page,
          offset: respData.data.pageInfo.pageSize,
          total: respData.data.pageInfo.total,
          totalPage: respData.data.pageInfo.pages.length,
        }) || {
        page: 0,
        offset: 15,
        total: 0,
        totalPage: 0,
      },
    },
  } as ISearchResultData;
}

export function resolveFetchOption(type: TSearchType, filter: IRequestFilter) {
  // console.log(filter);
  const { source, params } = filter;
  const hasFilter = Object.keys(source).length > 0;
  // console.log('hasFilter: ', hasFilter);

  let bodyJson: Record<string, string | number | boolean> = {};
  // 상세 검색이거나, 필터를 가지고 있지 않으면 통합 검색으로 처리
  if (type === 'integrated' || !hasFilter) {
    const typedParams = params as unknown as IMailIntegratedSearchRequestParams;
    bodyJson = {
      stype: 'simple',
      fromaddr: typedParams.keyword,
      toaddr: params.keyword,
      keyword: params.keyword,
      keyWord: params.keyword,
      operation: 'or',
      offset: 5,
      page: 1,
      fromDate: params.fromDate,
      toDate: params.toDate,
      searchTermAll: 'all',
      folder: 'all',
      sharedFlag: 'user',
      sharedUserSeq: 0,
      sharedFolderName: '',
      /** @property 검색할 메일 필드 구분 (s: 제목, b:본문, a:첨부파일명, f:첨부파일내용) */
      category: 'sbaf',
      sdate: params.fromDate,
      edate: params.fromDate,
      adv: 'on',
    };
  } else {
    bodyJson = params;
  }

  return {
    method: 'POST',
    body: JSON.stringify(bodyJson),
    headers: {
      'Content-Type': 'application/json',
    },
  } as RequestInit;
}

/*
simple 타입 검색 요청 예시
{
  "stype":"simple",
  "keyword":"Daouoffice",
  "offset":"5",
  "page":1,
  "fromDate":"1970-01-01T00:00:00.000+09:00",
  "toDate":"2025-01-06T10:33:01.287+09:00",
  "searchTerm":"all",
  "folder":"all",
  "keyWord":"Daouoffice",
  "fromaddr":"Daouoffice",
  "toaddr":"Daouoffice",
  "operation":"or",
  "sharedFlag":"user",
  "sharedUserSeq":0,
  "sharedFolderName":"",
  "category":"sbaf",
  "sdate":"1969-12-31T15:00:00+00:00",
  "edate":"2025-01-06T14:59:59+00:00",
  "adv":"on"
}

detail 타입 검색 요청 예시
{
  "adv":"on",
  "listType":"mail",
  "keyWord":"",
  "folder":"all",
  "fromaddr":"백금철",
  "toaddr":"",
  "category":"s",
  "sdate":"",
  "edate":"",
  "flag":"",
  "searchExtFolder":"off"
}
 */
