'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import type { AppSearchResultProps, ITodoSearchResultItem } from '../types';
import * as Template from '../result-templates/primitives';

export function SearchResultTodo({ data, onClickLink }: AppSearchResultProps) {
  const { t } = useTranslation('common');
  const {
    data: { list = [] },
  } = data;

  return (
    <Template.Container>
      {list.map((item: ITodoSearchResultItem) => {
        const attachFileName = item.attachFileNames ? (
          <Template.AttachFileInfo filename={item.attachFileNames} />
        ) : null;
        const targetUrl = `/gw/app/todo/${item.todoId}/card/${item.id}`;
        const clickHandler = () => {
          onClickLink && onClickLink(targetUrl);
        };

        return (
          <Template.Article key={item.id}>
            <Template.Title
              href={targetUrl}
              title={item.title}
              onClick={clickHandler}
            />
            <Template.Content
              href={targetUrl}
              content={item.contents}
              onClick={clickHandler}
            />
            {attachFileName}

            <Template.Footer>
              <Template.FooterItem>
                <span>{t('search.result.todo.boardName')}</span>{' '}
                <span dangerouslySetInnerHTML={{ __html: item.todoTitle }} />
              </Template.FooterItem>
              <Template.FooterItem>
                <span>{t('search.result.todo.columnName')}</span>{' '}
                <span
                  dangerouslySetInnerHTML={{ __html: item.categoryTitle }}
                ></span>
              </Template.FooterItem>
              {item.dueDate && (
                <Template.FormatDate
                  label={t('search.result.todo.dueDate')}
                  date={item.dueDate}
                />
              )}
            </Template.Footer>
          </Template.Article>
        );
      })}
    </Template.Container>
  );
}

export default SearchResultTodo;
