import {
  IconArrowLeft,
  IconArrowRight,
  IconChevronDown,
  IconChevronUp,
} from '@tabler/icons-react';
import DOMPurify from 'dompurify';
import { Button } from '../../../../../shared/ui/button';
import { formatDate } from '../../../../../shared/lib/date';
import { FileItem } from '../../../../../shared/ui/file-item';
import { useStackMethod } from '../../../../../shared/ui/stacker';
import { useTranslation } from '../../../../../shared/lib/i18n/client/use-translation';
import { useNotificationDetail } from '../../../model/use-notification-detail';

import styles from './styles.module.css';
import containerStyles from '../styles.module.css';

export function NotificationDetail() {
  const { t } = useTranslation('component');
  const { pop } = useStackMethod();

  const {
    notificationItem,
    isLoading,
    setCurrentDetailId,
    onClickDownloadFile,
  } = useNotificationDetail();

  if (isLoading) {
    return (
      <div className={containerStyles.NotificationContainer}>
        <div className={containerStyles.NotificationContentContainer}>
          <div className={styles.AlertContainer}>
            <p className={styles.AlertMessage}>...Loading</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={containerStyles.NotificationContainer}>
      <div className={containerStyles.NotificationContentContainer}>
        <div className={styles.DetailContainer}>
          <div className={styles.DetailHeader}>
            <div className={styles.DetailTitle}>
              <Button onClick={() => pop()} variant="ghost">
                <IconArrowLeft size={24} />
              </Button>
              {notificationItem?.title}
            </div>
            <p className={styles.DetailNotificationDate}>
              {`${t(`customerNoti.category.${notificationItem?.categoryType}`)} | ${t('customerNoti.startDate')} ${formatDate({ date: notificationItem?.startDate, format: 'YYYY-MM-DD' })} | ${t('customerNoti.updateDate')}  ${formatDate({ date: notificationItem?.updatedAt, format: 'YYYY-MM-DD' })}`}
            </p>
          </div>
          <div
            className={styles.NotificationContent}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(notificationItem?.content ?? ''),
            }}
          ></div>
          <div className={styles.FileAndLink}>
            {notificationItem?.filePathList &&
              notificationItem.filePathList.length > 0 && (
                <div className={styles.FileContainer}>
                  {notificationItem.filePathList.map((file) => (
                    <FileItem
                      key={file.id}
                      fileName={file.name}
                      fileType={file.extension}
                      fileSize={file.size}
                      onClick={() => {
                        onClickDownloadFile(notificationItem.id, file);
                      }}
                    />
                  ))}
                </div>
              )}
            {notificationItem?.linkName && notificationItem.linkUrl && (
              <a
                className={styles.Link}
                href={notificationItem.linkUrl}
                target="_blank"
                rel="noreferrer"
              >
                <IconArrowRight
                  className="text-[--dop-color-icon-basic-level3]"
                  size={16}
                />
                {notificationItem?.linkName}
              </a>
            )}
          </div>
          {notificationItem?.customerAnnouncementNeighborDto.existPrevious && (
            <Button
              className={styles.ForwardBackwardButton}
              variant="ghost"
              onClick={() => {
                setCurrentDetailId(
                  notificationItem?.customerAnnouncementNeighborDto.previousId,
                );
              }}
            >
              <p className={styles.ForwardBackwardTitle}>
                {t('customerNoti.prev')}
              </p>
              <p className={styles.ForwardBackwardPreviewTitle}>
                {notificationItem?.customerAnnouncementNeighborDto.previousName}
              </p>
              <div className="flex-grow" />
              <IconChevronUp
                size={16}
                className={styles.ForwardBackwardChevron}
              />
            </Button>
          )}
          {notificationItem?.customerAnnouncementNeighborDto.existNext && (
            <Button
              className={styles.ForwardBackwardButton}
              variant="ghost"
              onClick={() =>
                setCurrentDetailId(
                  notificationItem?.customerAnnouncementNeighborDto.nextId,
                )
              }
            >
              <p className={styles.ForwardBackwardTitle}>
                {t('customerNoti.next')}
              </p>
              <p className={styles.ForwardBackwardPreviewTitle}>
                {notificationItem?.customerAnnouncementNeighborDto.nextName}
              </p>
              <div className="flex-grow" />
              <IconChevronDown
                size={16}
                className={styles.ForwardBackwardChevron}
              />
            </Button>
          )}
        </div>
      </div>
      <div className={styles.ListButtonContainer}>
        <Button
          variant="outline"
          colorset="level1"
          size="md"
          shape="rect"
          onClick={() => pop()}
        >
          {t('customerNoti.list')}
        </Button>
      </div>
    </div>
  );
}
