import { FooterItem } from '../primitives/footer-item';
import styles from './styles.module.css';

export interface Props {
  label?: string;
  value: string;
}

export function ApprovalItemFooterItem({ label, value }: Props) {
  return (
    <FooterItem className={styles.info}>
      {label && <span className={styles.label}>{label}</span>}
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </FooterItem>
  );
}

export default ApprovalItemFooterItem;
