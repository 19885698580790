import { PropsWithChildren } from 'react';
import { Root as PopoverRoot } from '../../popover';
import { useSelectContext } from './context';

export function Wrapper({ children }: PropsWithChildren) {
  const { open, setOpen } = useSelectContext();

  return (
    <PopoverRoot open={open} onOpenChange={setOpen}>
      {children}
    </PopoverRoot>
  );
}
