import { useSuspenseQuery } from '@tanstack/react-query';
import * as DateUtil from '@dop-ui/react/shared/lib/date';
import * as SearchSimpleFetcher from '../api/integrated-search-fetcher';
import { useSearchableAppList } from './use-search-app-list';

import { SEARCHAGLE_APPS } from '../model/searchable-apps';
import type {
  TSearchType,
  TSearchableAppType,
  IRequestFilter,
} from '../model/types';
import { useSearchFilter, useSearchKeyword } from '../model/store';

export function useIntegratedSearchRequest(type: TSearchType) {
  const [keyword] = useSearchKeyword();
  const { filter, hasFilter } = useSearchFilter();
  const searchableApps = useUserSeachableApps(SEARCHAGLE_APPS);
  let parsedFilter: IRequestFilter = {
    source: {},
    params: {},
  };
  if (hasFilter()) {
    parsedFilter = { ...filter };
  } else {
    parsedFilter = {
      ...parsedFilter,
      params: { keyword },
    };
  }

  // 통합 검색에서 page 속성이 필수로 보내져야 해서, 없으면 주입한다.
  if (typeof parsedFilter.params.page === 'undefined') {
    parsedFilter.params.page = '0';
  }

  // fromDate, toDate는 필수이기 때문에 없으면 채워준다.
  if (!parsedFilter.params.fromDate) {
    parsedFilter.params.fromDate = DateUtil.date('1971-01-01')
      .startOf('day')
      .format();
  }
  if (!parsedFilter.params.toDate) {
    parsedFilter.params.toDate = DateUtil.date().endOf('day').format();
  }

  const queryString = new URLSearchParams(parsedFilter.params).toString();

  return useSuspenseQuery({
    queryKey: [`search:${type}`, queryString],
    queryFn: async () => {
      const result = await SearchSimpleFetcher.fetch(
        searchableApps,
        parsedFilter,
      );
      return result.filter(Boolean);
    },
    // 통합 검색은 많은 앱을 한꺼번에 조회해야 하므로, 전송 오류가 발생하더라도 retry를 하지 않는다
    // (기본값 -> client component: 3, server component: 1)
    retry: 0,
  });
}

function useUserSeachableApps(apps: TSearchableAppType[]) {
  const seachableAppList = useSearchableAppList();
  const appCodeMap = new Map(seachableAppList.map((item) => [item.code, item]));

  return apps.map((app) => {
    const appCode = app.code;
    if (appCodeMap.has(appCode)) {
      return {
        ...app,
        name: appCodeMap.get(appCode).appName,
      };
    }
  });
}
