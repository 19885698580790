import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useReportFilterCreator } from '@/components/search/model/filters/report-filter-creator';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  IncludedKeywordField,
  TextField,
} from '../../filter-templates';
import { SelectFolderIdField } from './fields/report-dept-list';
import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

const PERIOD_FIELD_NAME = 'PERIOD';
const CREATOR_FIELD_NAME = 'CREATOR';

export const ReportSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useReportFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        <SelectFolderIdField />
        <TextField
          label={t('search.filter.report.field.CREATOR.label')}
          placeholder={t('search.filter.report.field.CREATOR.placeholder')}
          name={CREATOR_FIELD_NAME}
        />
        <IncludedKeywordField.ContentType />
        <PeriodField.RadioType name={PERIOD_FIELD_NAME} />
        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

export default ReportSearchFilter;
