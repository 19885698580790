import { getFileIcon } from '@dop-ui/react/shared/lib/utils/file-icon';

import styles from './styles.module.css';

export function AttachFileInfo({ filename }: { filename: string }) {
  const FileIcon = getFileIcon(filename.split('.')[1]);

  return (
    <div className={styles.SearchResult__Article__AttachFile}>
      <FileIcon size={16} />
      <span>{filename}</span>
    </div>
  );
}

export default AttachFileInfo;
