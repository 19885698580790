import { ExternalInterWorkingCenterIcon } from '@dop-ui/icons/react/dop/24';
import { Button } from '@dop-ui/react/shared/ui/button';

export function ExternalInterWorkingCenter() {
  const handleOnClick = () => {
    window.dispatchEvent(new CustomEvent('eacc:ui:InterworkingCenter:open'));
  };

  return (
    <Button onClick={handleOnClick} as="div">
      <ExternalInterWorkingCenterIcon />
    </Button>
  );
}

export default ExternalInterWorkingCenter;
