import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { PageStatus404 } from '@dop-ui/react/entities/page-status';

export interface Props {
  error?: Error;
}

export function SystemErrorTemplate() {
  const { t } = useTranslation('common');

  // TODO: telemetry (ex: sentry 등) 연동

  // 우선 404 오류를 사용한다.
  return (
    <PageStatus404
      className="py-12 mt-8"
      message={t('search.result.error.pages.500.message')}
    />
  );
}

export default SystemErrorTemplate;
