import { SEARCH_TYPES } from '../constants';
import type {
  TSearchType,
  ISearchResultRespose,
  ISearchResultData,
  TSearchableAppType,
  IRequestFilter,
} from '../types';

export interface GroupwarePagination {
  page: number;
  offset: number;
  total: number;
  totalPage: number;
  lastPage: boolean;
}

/**
 * 그룹웨어 응답 인터페이스
 */
export interface GroupwareSearchResultResponse extends ISearchResultRespose {
  page: GroupwarePagination;
}

/**
 * 그룹웨어 공통 검색 path resolver
 * @param path
 * @param type
 * @param params
 * @returns
 */
export function resolvePath(
  path: string,
  type: TSearchType,
  filter: IRequestFilter,
) {
  const { params } = filter;
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  const offset = type === SEARCH_TYPES.INTEGRATED ? '5' : '15';
  // 기본 stype, 이 값은 filter에서 보내 준 params 값에 의해 오버라이드 될 수 있음.
  const stype = type === SEARCH_TYPES.INTEGRATED ? 'simple' : 'detail';

  const queryString = new URLSearchParams({
    stype,
    offset,
    ...params,
  }).toString();
  return `${normalizedPath}?${queryString}`;
}

/**
 * 그룹웨어 공통 응답 resolver
 * @param appInfo
 * @param resp
 * @returns
 */
export function resolveResponse(appInfo: TSearchableAppType, resp: unknown) {
  const respData = resp as GroupwareSearchResultResponse;
  return {
    code: appInfo.code,
    appName: appInfo.name,
    data: {
      list: respData.data,
      page: respData.page && {
        page: respData.page.page,
        offset: respData.page.offset,
        total: respData.page.total,
        totalPage: respData.page.totalPage,
      },
    },
  } as ISearchResultData;
}
