'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SearchFilterIncludedKeyword as IncludedKeywordField } from './included-keyword';

export const CONTENT_TYPES = {
  MAIL: 'mail',
  WORKS: 'works',
  BOARD: 'board',
  DOCS: 'docs',
  APPROVAL: 'approval',
} as const;
export type ContentType = (typeof CONTENT_TYPES)[keyof typeof CONTENT_TYPES];

export interface Props {
  type?: ContentType;
  name?: string;
}

export function BoardTypeSearchFilter({ type, name }: Props) {
  const options = useCheckboxOptions(type);

  return <IncludedKeywordField name={name} options={options} />;
}

function useCheckboxOptions(type: ContentType) {
  const { t } = useTranslation('common');

  return [
    type === CONTENT_TYPES.DOCS || type === CONTENT_TYPES.APPROVAL
      ? {
          value: 'SUBJECT',
          label: t(
            'search.filter.includedKeyword.template.contentType.option.SUBJECT',
          ),
        }
      : undefined,
    type === CONTENT_TYPES.WORKS
      ? {
          value: 'TEXT',
          label: t(
            'search.filter.includedKeyword.template.contentType.option.TEXT',
          ),
        }
      : undefined,
    type === CONTENT_TYPES.WORKS
      ? {
          value: 'ACTIVITY_LOG',
          label: t(
            'search.filter.includedKeyword.template.contentType.option.ACTIVITY_LOG',
          ),
        }
      : undefined,
    type !== CONTENT_TYPES.WORKS
      ? {
          value: 'CONTENT',
          label: t(
            'search.filter.includedKeyword.template.contentType.option.CONTENT',
          ),
        }
      : undefined,
    type === CONTENT_TYPES.MAIL
      ? undefined
      : {
          value: 'COMMENT',
          label: t(
            'search.filter.includedKeyword.template.contentType.option.COMMENT',
          ),
        },
    {
      value: 'FILE_NAME',
      label: t(
        'search.filter.includedKeyword.template.contentType.option.FILE_NAME',
      ),
    },
    {
      value: 'FILE_CONTENT',
      label: t(
        'search.filter.includedKeyword.template.contentType.option.FILE_CONTENT',
      ),
    },
  ].filter(Boolean);
}

export default BoardTypeSearchFilter;
