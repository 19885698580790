import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectField } from '../../../filter-templates';

export function SelectCarTypeField() {
  const { t } = useTranslation('common');
  const options = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.filter.vihicleLog.field.TYPE.option.CORPORATE'),
      value: 'CORPORATE',
    },
    {
      label: t('search.filter.vihicleLog.field.TYPE.option.PERSONAL'),
      value: 'PERSONAL',
    },
  ];

  return (
    <SelectField.Simple
      label={t('search.filter.vihicleLog.field.TYPE.label')}
      name="TYPE"
      options={options}
    />
  );
}

export default SelectCarTypeField;
