import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useEContractFilterCreator } from '@/components/search/model/filters/econtract-filter-creator';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  TextField,
  SelectField,
} from '../../filter-templates';
import { EContractIncludedKeywordField } from './fileds/included-keyword-field';
import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export const EContractSearchFilter: React.ComponentType<
  SearchFilterCommonProps
> = ({ onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useEContractFilterCreator();
  const options = useSelectStatusOptions();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        <SelectField.Simple
          label={t('search.filter.econtract.field.STATUS.label')}
          name="STATUS"
          options={options}
        />
        <TextField
          label={t('search.filter.econtract.field.NAME.label')}
          placeholder={t('search.filter.econtract.field.NAME.placeholder')}
          name="NAME"
        />
        <EContractIncludedKeywordField />
        <PeriodField.CustomType
          name="REQUEST_DATE"
          label={t('search.filter.econtract.field.REQUEST_DATE.label')}
        />
        <PeriodField.CustomType
          name="DUE_DATE"
          label={t('search.filter.econtract.field.DUE_DATE.label')}
        />
        <PeriodField.CustomType
          name="COMPLETED_DATE"
          label={t('search.filter.econtract.field.COMPLETED_DATE.label')}
        />
        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

function useSelectStatusOptions() {
  const { t } = useTranslation('common');

  return [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.result.econtract.status.WAITING'),
      value: 'WAITING',
    },
    {
      label: t('search.result.econtract.status.COMPLETED'),
      value: 'COMPLETED',
    },
    {
      label: t('search.result.econtract.status.CANCELED'),
      value: 'CANCELED',
    },
  ];
}

export default EContractSearchFilter;
