'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import {
  Primitives as FilterTemplates,
  SelectField,
} from '../../../../filter-templates';
import { SelectMailBox } from './select-mail-box';

const FIELD_FOLDER_NAME = 'FOLDER';
const FIELD_SEARCH_EXT_FOLDER = 'SEARCH_EXT_FOLDER';
const FIELD_NAME = 'FOLDER_OPTIONS';

export interface FolderOptions {
  FOLDER: string;
  SEARCH_EXT_FOLDER?: boolean;
}

export function SelectMailBoxField({ className }: { className?: string }) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } =
    FilterTemplates.useSearchFilterContext();
  const curFilterValue = filterValue[FIELD_NAME] as
    | FolderOptions
    | null
    | undefined;

  const changeFilterValue = (value: FolderOptions) => {
    setFilterValue({
      [FIELD_NAME]: value,
    });
  };

  const createChangeHandler = (value: string) => {
    const newValue = {
      [FIELD_FOLDER_NAME]: value,
    };
    if (
      value !== '' &&
      typeof filterValue[FIELD_SEARCH_EXT_FOLDER] !== 'undefined'
    ) {
      newValue[FIELD_SEARCH_EXT_FOLDER] = false;
    }
    changeFilterValue(newValue);
  };

  const changeIncludeTypeHandler = (checked: boolean) => {
    const newValue = {
      [FIELD_FOLDER_NAME]: '', // 이 옵션은 전체메일함에서만 사용할 수 있으므로...
      [FIELD_SEARCH_EXT_FOLDER]: !!checked,
    };
    changeFilterValue(newValue);
  };

  const folderFilterValue = curFilterValue?.FOLDER || '';
  const searchExtFolderFilterValue = curFilterValue?.SEARCH_EXT_FOLDER || false;
  const isAllMailFolder = !folderFilterValue || folderFilterValue === '';

  return (
    <SelectField.Layout
      label={t('search.filter.mail.field.FOLDER.label')}
      className={className}
    >
      <SelectMailBox value={folderFilterValue} onChange={createChangeHandler} />
      {isAllMailFolder && (
        <CheckBox
          id={FIELD_SEARCH_EXT_FOLDER}
          defaultChecked={!!searchExtFolderFilterValue}
          label={t('search.filter.mail.field.SEARCH_EXT_FOLDER.label')}
          onChange={changeIncludeTypeHandler}
        />
      )}
    </SelectField.Layout>
  );
}

export default SelectMailBoxField;
