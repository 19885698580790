import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser, IncludedKeywordFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams,
  IIncludedKeywordTemplateFieldValues,
  IPeriodTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface BoardFilterValueType extends TFilterValue {
  BOARD_IDS?: { boardIds: string[] };
  CREATOR?: string;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
  PERIOD?: IPeriodTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface BoardSearchParams extends TSearchParams {
  /** @property as-is: true 의미는 모름 */
  allSearch: string;
  /** @property 게시판 ID, 2개 이상일 때 콤마(,)로 구분 */
  boardIds?: string;
  /** @property 작성자 */
  userName?: string;
  content?: string;
  comment?: string;
  attachFileNames?: string;
  attachFileContents?: string;
  fromDate: string;
  toDate: string;
}

export class BoardFilterCreator 
  extends SearchFilterCreator<BoardFilterValueType, BoardSearchParams> {
  constructor(
    protected _type: 'board' | 'community',
    protected _keyword: string, 
    protected _source: TFilterValue
  ) {
    super(_keyword, _source);
  }

  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const boardIdsField = this.source['BOARD_IDS'];
    const boardIdStr = boardIdsField ? boardIdsField.boardIds.join(',') : undefined;
    const periodFiels = new PeriodFieldParser(this.source['PERIOD']).parse();
    const inKeywordFields = new IncludedKeywordFieldParser(this.source['INCLUDED_KEYWORD']).parse();
    return {
      allSearch: this._type === 'board' ? 'true' : undefined,
      boardIds: boardIdStr,
      userName: this.source['CREATOR'],
      ...inKeywordFields,
      ...periodFiels
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('CREATOR', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('CREATOR', this.source['CREATOR'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useBoardFilterCreator(type: 'board' | 'community') {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new BoardFilterCreator(
    type, 
    searchKeyword, 
    value
  );
}
