import * as React from 'react';
import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { PopoverContentProps } from '@radix-ui/react-popover';
import { Content as PopoverContent } from '../../popover';

import styles from './style.module.css';

interface Props extends PopoverContentProps {
  className?: string;
  isPortal?: boolean;
}

export function Content({
  className,
  children,
  isPortal = true,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <PopoverContent
      className={clsx(styles.OptionContent, className)}
      align="start"
      isPortal={isPortal}
      {...props}
    >
      {children}
    </PopoverContent>
  );
}
