/**
 * 통합 검색 대상 앱 코드 목록
 */
export const SEARCHABLE_APP_CODES = {
  INTEGRATED: '@integrated', /** 통합검색 가상 코드 */
  MAIL: 'dop-employee-mail',
  CONTACT: 'dop-employee-contact',
  CALENDAR: 'dop-employee-calendar',
  APPROVAL: 'dop-employee-approval',
  APPR_COMPANY_FOLDER: 'dop-employee-appr-company-folder',
  BOARD: 'dop-employee-board',
  DOCS: 'dop-employee-docs',
  COMMUNITY: 'dop-employee-community',
  REPORT: 'dop-employee-report',
  WORKS: 'dop-employee-works',
  TODO: 'dop-employee-todo',
  WEBFOLDER: 'dop-employee-webfolder',
  HR: 'dop-employee-hr',
  ECONTRACT: 'dop-employee-econtract',
  LEGAL_EDUCATION: 'dop-employee-legal-education',
  EXPENSES: 'dop-employee-expenses',
  VEHICLE_LOG: 'dop-employee-vehicle-log',
}

export const PERIOD_TYPES = {
  NONE: 'NONE',
  LAST_WEEK: 'LAST_WEEK',
  LAST_2_WEEKS: 'LAST_2_WEEKS',
  LAST_MONTH: 'LAST_MONTH',
  CUSTOM: 'CUSTOM',
} as const;

export const SEARCH_TYPES = {
  APP: 'app',
  INTEGRATED: 'integrated',
} as const;

export type SearchType = typeof SEARCH_TYPES[keyof typeof SEARCH_TYPES];
export type PathResolverFn = (path: string, type: SearchType, params: Record<string, string>) => string;
