import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import type { ISearchResultRespose } from '../model/types';

/**
 * 내가 접근 가능한 게시판 메뉴 목록 조회
 * @returns 
 */
export async function fetchMyBoardMenus() {
  const response = await fetch('/gw/api/board/menu/target/owners');
  return await response.json() as unknown as ISearchResultRespose;
}

/**
 * 부서 게시판 목록 조회
 * @param deptId 
 * @returns 
 */
export async function fetchDeptBoardMenus(deptId: string) {
  const response = await fetch(`/gw/api/board/menu/department/${deptId}/all`);
  return await response.json() as unknown as ISearchResultRespose;
}

/**
 * 전사 게시판 목록 조회
 */
export async function fetchCompanyBoardMenus() {
  const response = await fetch(`/gw/api/board/menu/target/company/all`);
  return await response.json() as unknown as ISearchResultRespose;
}

/**
 * 커뮤니티 목록 조회
 * @returns 
 */
export async function fetchMyCommunityList() {
  const response = await fetch('/gw/api/board/menu/communities');
  return await response.json() as unknown as ISearchResultRespose;
}

/**
 * 커뮤니티 게시판 목록 조회
 */
export async function fetchCommunityBoardMenus(commId: string) {
  const response = await fetch(`/gw/api/board/menu/community/${commId}/all`);
  return await response.json() as unknown as ISearchResultRespose;
}
