'use client';

import * as TemplatePrimitives from '../primitives';
import styles from './styles.module.css';

export function ApprovalItemFooter({ children }: React.PropsWithChildren) {
  return (
    <TemplatePrimitives.Footer
      className={styles.SearchResult__ApprovalItemType_Footer}
    >
      {children}
    </TemplatePrimitives.Footer>
  );
}

export default ApprovalItemFooter;
