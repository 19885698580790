'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { ArrowLeftIcon } from '@dop-ui/icons/react/dop/24/ArrowLeft';
import { Button } from '@dop-ui/react/shared/ui/button';
import { getInhouseAppIcon } from '@dop-ui/react/entities/inhouse-apps';
import { useCurrentAppCode } from '@/components/search/model/store';
import { SEARCHABLE_APP_CODES } from '@/components/search/model/constants';
import { useSearchFilterContext } from './context';
import type { PrimitivesCommonProps } from './types';

import styles from './styles.module.css';

export interface Props extends PrimitivesCommonProps {
  title: string;
}

export function SearchFieldHeader({ title, className }: Props) {
  const [appCode, setAppCode] = useCurrentAppCode();
  const { open, isFilterSaved, hasSavedFilter, getFilterCount, setOpen } =
    useSearchFilterContext();
  const isIntegratedSearch = appCode === SEARCHABLE_APP_CODES.INTEGRATED;
  const AppIcon = getInhouseAppIcon(appCode);
  const hasFilterState = isFilterSaved && hasSavedFilter;

  return (
    <div className={clsx(styles.SearchFilter__Header, className)}>
      <div className={styles.heading}>
        {!isIntegratedSearch && (
          <button onClick={() => setAppCode(SEARCHABLE_APP_CODES.INTEGRATED)}>
            <ArrowLeftIcon />
          </button>
        )}

        <span
          className={clsx(styles.title, {
            [styles.medium]: !isIntegratedSearch,
            [styles.large]: isIntegratedSearch,
          })}
        >
          {!isIntegratedSearch && <AppIcon />}
          {title}
        </span>
      </div>
      <Button
        className={clsx(styles.button)}
        onClick={() => setOpen(!open)}
        data-open={open ? 'true' : undefined}
        data-has-filter={hasFilterState ? 'true' : undefined}
      >
        <IconAdjustmentsHorizontal stroke={1.5} size={24} />
        {hasFilterState && (
          <span className={styles.count}>{getFilterCount()}</span>
        )}
      </Button>
    </div>
  );
}

export default SearchFieldHeader;
