import { fetch } from '../../../shared/lib/fetch/client';
import { BadgeInfo } from '../types';

export const QUERY_KEY = 'get-app-badge-list';

interface Response {
  data: {
    elements: BadgeInfo[];
  };
}

export async function getBadgeList(params: string[]) {
  const queryString = params
    .map((code) => `appCodes=${encodeURIComponent(code)}`)
    .join('&');
  const query = new URLSearchParams();
  query.append('appCodes', params.toString());
  const resp = await fetch(`/api/agg/badge/apps?${queryString}`);

  const respJson = (await resp.json()) as Response;

  return respJson.data;
}
