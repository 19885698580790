import * as React from 'react';
import Image from 'next/image';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { formatDate, createDate } from '@dop-ui/react/shared/lib/date';
import { highlightText } from '@dop-ui/react/shared/lib/utils/string';
import { useSearchKeyword } from '@/components/search/model/store';
import {
  Primitives as TemplatePrimitives,
  NotFoundErrorTemplate,
} from '../../result-templates';
import {
  FILTER_FIELD_TYPES,
  WORKS_FIELD_TYPES,
  WORKS_VALUE_TYPES,
} from './constants';
import type {
  AppSearchResultProps,
  IWorksSearchResultItem,
  IWorksAppData,
  IWorksAppletSummary,
  IWorksDocContent,
  IWorksAppletField,
  IWorksContentUpsertInfo,
} from '../../types';

export function SearchSummaryWorks({
  data,
  onClickLink,
}: AppSearchResultProps) {
  const getDisplayText = useGetDisplayText();
  const highlightText = useHightlightText();

  const {
    data: { list = [] },
  } = data;
  const [{ appletSummaries, docs }] = list as [IWorksAppData];
  const { content } = docs;

  const toMapArgs = appletSummaries.reduce(
    (
      acc: Array<[number, IWorksAppletSummary]>,
      applet: IWorksAppletSummary,
    ) => {
      return [...acc, [applet.id, applet]] as Iterable<
        [number, IWorksAppletSummary]
      >;
    },
    [] as Array<[number, IWorksAppletSummary]>,
  );
  const appletSummaryMap = new Map(toMapArgs);
  const resovedList = content.reduce(
    (acc: IWorksSearchResultItem[], doc: IWorksDocContent) => {
      const { appletId, values: fieldValues } = doc;
      const summary = appletSummaryMap.get(appletId);

      if (!summary) {
        // summary가 없는 경우는 데이터가 이상한 경우이므로 오류를 일으킨다.
        throw new Error(`applet summary not found. appletId: ${appletId}`);
      }

      const {
        name: appletName,
        thumbSmall: appletIcon = '',
        titleCid,
        fields,
      } = summary;

      // 주의: title 값이 숫자인 경우가 있어서 강제로 문자열로 변환해야한다.
      const displayTitle = highlightText(
        `${fieldValues[titleCid] as number | string}` || '',
      );

      // 제목, 등록자 등 content 외에서 표시된 것을 필터링한다.
      const filteredFields = fields.filter(
        (field: IWorksAppletField) =>
          !(
            field.cid === titleCid ||
            field.cid === 'create_date' ||
            field.cid === 'creator'
          ) &&
          FILTER_FIELD_TYPES.includes(field.fieldType) &&
          !field.multiple,
      );
      const contentMap = filteredFields.reduce(
        (acc: React.ReactElement[], field: IWorksAppletField, idx: number) => {
          const displayText = getDisplayText(field, doc);
          const parsedValue = highlightText(displayText);
          const { label } = field;
          const seperator =
            idx === 0 ? null : <span key={`s-${idx}`}>{', '}</span>;
          const content = `${label}: ${parsedValue}`;
          return [
            ...acc,
            seperator,
            <span
              key={`v-${idx}`}
              dangerouslySetInnerHTML={{ __html: content }}
            />,
          ].filter(Boolean);
        },
        [],
      );

      const content = (
        <React.Fragment key={appletId}>
          {contentMap.map((v: React.ReactElement) => v)}
        </React.Fragment>
      );

      const currentDoc = {
        id: doc.id,
        appletName,
        appletIcon,
        appletId: doc.appletId,
        title: displayTitle,
        content,
        createdAt: fieldValues.create_date,
        creator: fieldValues.creator,
      };

      acc.push(currentDoc);
      return acc;
    },
    [],
  );

  if (resovedList.length === 0) {
    return <NotFoundErrorTemplate />;
  }

  return (
    <TemplatePrimitives.Container>
      {resovedList.map((item: IWorksSearchResultItem) => {
        const linkUrl = `/gw/app/docs/detail/${item.id}`;
        const clickHandler = () => {
          onClickLink && onClickLink(linkUrl);
        };
        const userName = highlightText(
          `${item.creator.name} ${item.creator.position}`,
        );

        return (
          <TemplatePrimitives.Article key={item.id}>
            <TemplatePrimitives.Title
              href={linkUrl}
              title={item.title}
              onClick={clickHandler}
            />
            <TemplatePrimitives.Content
              href={linkUrl}
              content={item.content}
              onClick={clickHandler}
              useHtml={false}
            />
            <TemplatePrimitives.Footer>
              <TemplatePrimitives.UserInfo
                uid={item.creator.id}
                name={userName}
                profileUrl={item.appletIcon}
              />
              <TemplatePrimitives.FormatDate
                date={item.createdAt}
                format="YYYY-MM-DD HH:MM"
              />
              <TemplatePrimitives.FooterItem className="flex items-center">
                <Image
                  src={item.appletIcon}
                  width={24}
                  height={24}
                  alt={``}
                  className="mr-1"
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightText(item.appletName),
                  }}
                />
              </TemplatePrimitives.FooterItem>
            </TemplatePrimitives.Footer>
          </TemplatePrimitives.Article>
        );
      })}
    </TemplatePrimitives.Container>
  );
}

export function useGetDisplayText() {
  const toFormattedTime = useConvertFormattedTime();

  return (field: IWorksAppletField, doc: IWorksDocContent): string => {
    const fieldValues = doc.values;
    if (!fieldValues) {
      return '-';
    }

    if (field.fieldType === WORKS_FIELD_TYPES.STATUS) {
      return doc.status ? doc.status.name : '-';
    }

    if (field.fieldType === WORKS_FIELD_TYPES.DOCNO) {
      return doc.docNo ? (doc.docNo as string) : '-';
    }

    const targetValue = fieldValues[field.cid];

    if (
      !targetValue ||
      (Array.isArray(targetValue) && targetValue.length === 0)
    ) {
      return '-';
    }

    if (
      field.valueType === WORKS_VALUE_TYPES.SELECT ||
      field.valueType === WORKS_VALUE_TYPES.SELECTS
    ) {
      const { options } = field;
      let selectedOptIds = targetValue as number | number[];
      selectedOptIds = Array.isArray(selectedOptIds)
        ? selectedOptIds
        : [selectedOptIds];
      const label = selectedOptIds.map((v) => {
        const option = options.find((o) => o.value === v);
        return option ? option.displayText : '';
      });

      return label.join(', ');
    }

    if (
      field.valueType === WORKS_VALUE_TYPES.USER ||
      field.valueType === WORKS_VALUE_TYPES.USERS ||
      field.valueType === WORKS_VALUE_TYPES.DEPTS
    ) {
      let userOrDepts = targetValue as
        | IWorksContentUpsertInfo
        | IWorksContentUpsertInfo[];
      userOrDepts = Array.isArray(userOrDepts) ? userOrDepts : [userOrDepts];

      const strArr = userOrDepts.map((v) => {
        return [v.name, v.position].join(' ');
      });

      return strArr.join(', ');
    }

    if (field.valueType === WORKS_VALUE_TYPES.DATE) {
      const dateValues = targetValue as string;
      return formatDate({
        date: createDate(dateValues, 'YYYYMMDD'),
        format: 'YYYY-MM-DD',
      });
    }

    if (field.valueType === WORKS_VALUE_TYPES.DATETIME) {
      const typedValue = targetValue as string;
      return formatDate({
        date: createDate(typedValue),
        format: 'YYYY-MM-DD HH:mm',
      });
    }

    // 숫자 타입에 대한 처리
    if (field.valueType === WORKS_VALUE_TYPES.NUMBER) {
      const { properties } = field;
      if (!properties) {
        return `${targetValue as number}`;
      }

      // 날짜 타입 중 시간과 관련된 값 처리.
      if (properties.expressionType === 'time') {
        const typedValue = targetValue as number;
        const unixTimestamp =
          properties.dateValueType === WORKS_VALUE_TYPES.TIME
            ? typedValue * 60 * 1000
            : typedValue;
        return toFormattedTime(unixTimestamp, false);
      }

      if (properties.expressionType === 'day') {
        const typedValue = targetValue as number;
        return properties.dateValueType === WORKS_VALUE_TYPES.DATE ||
          properties.dateValueType === WORKS_VALUE_TYPES.DATETIME
          ? toFormattedTime(typedValue, true)
          : `${typedValue}`;
      }

      // 그외 숫자 타입에 대한 처리
      const { dataType = 'NUMBER' } = properties;
      const typedValue = targetValue as number;

      // 퍼센티지 타입 처리
      if (dataType === 'PERCENT') {
        return `${typedValue} %`;
      }

      // 포인트 타입 처리
      if (dataType === 'POINT') {
        return `${typedValue}`;
      }

      // 그외 나머지 숫자 타입 처리
      return [
        properties.fixType === 'prefix' ? properties.unitText : undefined,
        properties.thousandComma
          ? `${typedValue}`.toLocaleString()
          : typedValue,
        properties.fixType === 'postfix' ? properties.unitText : undefined,
      ]
        .filter(Boolean)
        .join(' ');
    }

    if (field.valueType === WORKS_VALUE_TYPES.APPLETDOCS) {
      const { properties } = field;
      const typedValue = Array.isArray(targetValue)
        ? doc.values[field.cid]
        : [doc.values[field.cid]];

      const returnValue = (typedValue as { text: unknown }[])
        .map((value) => {
          const values = value.text;
          const { dataType = 'NUMBER' } = properties;

          if (
            dataType !== 'NUMBER' &&
            (typeof values === 'undefined' || values === null)
          ) {
            return '';
          }

          if (dataType === 'PERCENT') {
            return `${values as number} %`;
          }

          if (dataType === 'POINT') {
            return `${values as number}`;
          }

          return [
            properties.fixType === 'prefix' ? properties.unitText : undefined,
            properties.thousandComma
              ? `${values as number}`.toLocaleString()
              : values,
            properties.fixType === 'postfix' ? properties.unitText : undefined,
          ]
            .filter(Boolean)
            .join(' ');
        })
        .join('');

      return returnValue.length > 0 ? returnValue : '-';
    }

    // Text 타입에 대한 처리
    if (
      field.valueType === WORKS_VALUE_TYPES.TEXT ||
      field.valueType === WORKS_VALUE_TYPES.STEXT
    ) {
      const typedValue = targetValue as string | string[];
      return Array.isArray(typedValue)
        ? [typedValue].filter(Boolean).join(', ')
        : typedValue;
    }

    // 그외 나머지 문자열에 대한 처리
    return typeof targetValue === 'string' ? targetValue : '-';
  };
}

function useConvertFormattedTime() {
  const { t } = useTranslation('common');

  return (timestamp: number, includeDays = false): string => {
    const unixTimestamp = Math.abs(+timestamp);

    // 경과 시간을 일, 시간, 분 단위로 계산합니다.
    const negative = timestamp < 0 ? '-' : '';
    const daysFactor = 1000 * 60 * 60 * 24;
    const hoursFactor = 1000 * 60 * 60;
    const minutesFactor = 1000 * 60;
    const days = includeDays ? Math.floor(unixTimestamp / daysFactor) : 0;
    const hours = includeDays
      ? Math.floor((unixTimestamp % daysFactor) / hoursFactor)
      : Math.floor(unixTimestamp / hoursFactor);
    const minutes = Math.floor((unixTimestamp % hoursFactor) / minutesFactor);

    const i18nKey: string = [
      days > 0 ? 'DD' : undefined,
      hours > 0 ? 'HH' : undefined,
      minutes > 0 ? 'MM' : undefined,
    ]
      .filter(Boolean)
      .join('');

    return t(`search.result.works.content.time.format.${i18nKey}`, {
      days,
      hours,
      minutes,
      negative,
    });
  };
}

function useHightlightText() {
  const [keyword] = useSearchKeyword();

  return (value: string) => highlightText(value, keyword);
}

export default SearchSummaryWorks;
