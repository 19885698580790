import Link from 'next/link';

import * as Popover from '@dop-ui/react/shared/ui/popover';
import { EllipsisHorizontalIcon } from '@daouoffice/ui/lib/icons/dop/24/EllipsisHorizontal';
import { useScreenLock } from '@dop-ui/react/widgets/screen-lock';
import { FuncButton } from '../../FuncButton';
import type { IUserMenuInfo } from '../../types';
import type { BadgeInfo } from '@dop-ui/react/entities/badge';

export interface Props {
  data: IUserMenuInfo[];
  getBadgeInfo: (appCode: string) => BadgeInfo;
}

export function AppMoreMenuLayer({ data, getBadgeInfo }: Props) {
  const { locked } = useScreenLock();

  const onClickHandler = (item: IUserMenuInfo) => {
    locked({
      appCode: item.appCode,
      appName: item.name,
      appUrl: item.url,
      useLock: item.useLock,
    });
  };

  const renderBadge = (appCode: string) => {
    const badge = getBadgeInfo(appCode);
    if (!badge) return <></>;
    if (badge.badgeType === 'NONE') return <></>;
    if (badge.badgeType === 'NUMBER' && badge.badgeValue === '0') return <></>;
    if (badge.badgeType === 'NEW' && !badge.badgeValue) return <></>;

    const content =
      badge.badgeType === 'DOT'
        ? ''
        : badge.badgeType === 'NEW'
          ? 'N'
          : badge.badgeValue;

    return (
      <span className="badge !flex !p-[1px_4px] !items-center !justify-center">
        {content}
      </span>
    );
  };

  return (
    <div className="group_more menu_open open">
      <Popover.Root>
        <Popover.Trigger>
          <FuncButton icon={EllipsisHorizontalIcon} />
        </Popover.Trigger>
        <Popover.Content side="right" sideOffset={10} className="select_list">
          <ul>
            {data.map((item) => (
              <li key={item.uid}>
                <Link
                  href={item.url}
                  onClick={() => onClickHandler(item)}
                  className="py-[3px] px-[12px] gap-[13px]"
                >
                  <i
                    className="icon ic_medium"
                    dangerouslySetInnerHTML={{ __html: item.icon as string }}
                  />
                  <span className="txt">{item.name}</span>
                  {renderBadge(item.appCode)}
                </Link>
              </li>
            ))}
          </ul>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}

export default AppMoreMenuLayer;
