import type {
  TSearchableAppType,
  ISearchResultRespose,
  ISearchResultData,
  IWorksAppData,
  IGroupwarePagination
} from '../types';

/**
 * Works 응답 인터페이스
 */
export interface WorksSearchResultResponse extends ISearchResultRespose {
  data: IWorksAppData;
}

export interface WorksSearchResultData extends ISearchResultData {
  data: {
    list: [IWorksAppData];
    page: IGroupwarePagination;
  };
}

// Works 응답 데이터 resolver
export function resolveResponse(appInfo: TSearchableAppType, resp: unknown) {
  const respData = resp as WorksSearchResultResponse;
  const pageInfo = respData.data.docs.pageInfo;

  return {
    code: appInfo.code,
    appName: appInfo.name,
    data: {
      // works는 data 객체 타입으로 내부에 appletSummaries와 values를 결합해서 조합해야 해야 하므로,
      // 요소 하나짜리 배열로 감싸서 내려보내주고, view에서 처리하도록 한다.
      list: [respData.data],
      page: pageInfo && {
        page: pageInfo.page,
        offset: pageInfo.offset,
        total: pageInfo.total,
        totalPage: pageInfo.totalPage,
        lastPage: pageInfo.lastPage,
      },
    },
  } as ISearchResultData;
}
