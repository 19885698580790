import { useSearchKeyword } from '../store';
import { SearchFilterCreator } from './filter-creator';
import { PeriodFieldParser, IncludedKeywordFieldParser } from './field-parser';
import { FieldValidator } from './field-validator';
import type { 
  TFilterValue, 
  TSearchParams,
  IPeriodTemplateFieldValues,
  IIncludedKeywordTemplateFieldValues,
  IRequestFilterError,
} from '../types';

export interface ReportFilterValueType extends TFilterValue {
  FOLDER_INFO?: {
    deptId: string;
    folderName: string;
  };
  CREATOR?: string;
  PERIOD?: IPeriodTemplateFieldValues;
  INCLUDED_KEYWORD?: IIncludedKeywordTemplateFieldValues;
}

/**
 * 통합 검색 파라메터 인터페이스
 */
export interface ReportSearchParams extends TSearchParams {
  /** @property 보고 폴터 IDS */
  folderIds?: string;
  /** @property 보고 폴터 이름*/
  folderNames?: string;
  /** @property 보고자명 */
  reporterName?: string;
  content?: string;
  comment?: string;
  attachFileNames?: string;
  attachFileContents?: string;
  fromDate?: string;
  toDate?: string;
  /** @property ?? (as-is 고정값: submittedAt) */
  properties: string;
}

export class ReportFilterCreator 
  extends SearchFilterCreator<ReportFilterValueType, ReportSearchParams> {
  /**
   * @override
   */
  _createParams() {
    // console.log('this.source: ', this.source);
    const folderInfo = this.source['FOLDER_INFO'];
    const periodFiels = new PeriodFieldParser(this.source['PERIOD']).parse();
    const reporterName = this.source['CREATOR'];
    const inKeywordFields = new IncludedKeywordFieldParser(this.source['INCLUDED_KEYWORD']).parse();
    return {
      folderIds: folderInfo && folderInfo.deptId,
      folderNames: folderInfo && folderInfo.folderName,
      reporterName,
      properties: 'submittedAt',
      ...inKeywordFields,
      ...periodFiels,
    };
  }

  /**
   * @override
   */
  validate(): IRequestFilterError | null {
    const fieldValidator = new FieldValidator();
    return fieldValidator
      .addLengthRule('CREATOR', 2, 64)
      .addIncludedKeywordRule('INCLUDED_KEYWORD', 2, 64)
      .setFieldValue('CREATOR', this.source['CREATOR'])
      .setFieldValue('INCLUDED_KEYWORD', this.source['INCLUDED_KEYWORD'])
      .validate();
  }
}

export function useReportFilterCreator() {
  const [searchKeyword] = useSearchKeyword();
  return (value: TFilterValue) => new ReportFilterCreator(searchKeyword, value);
}
