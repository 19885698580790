import * as React from 'react';
import { clsx } from 'clsx';
import {
  TextFieldPrimitives,
  type TextFieldShapeType,
  type TextFieldSizeType,
} from '@dop-ui/react/shared/ui/text-field';

import { useSearchKeyword } from '../../model/store';
import { SearchButton } from './search-button';
import styles from './styles.module.css';

export interface Props {
  className?: string;
  shape?: TextFieldShapeType;
  size?: TextFieldSizeType;
  onChangeValue?: (value: string) => void;
  onEnter?: () => void;
  onClear?: () => void;
}

export function SearchTextField({
  className,
  shape,
  size,
  onChangeValue,
  onEnter,
  onClear,
}: Props) {
  const [searchKeyword, setSearchKeyword] = useSearchKeyword();
  // 사용자가 Enter 키를 눌렀을 때의 결과를 저장하기 위해 로컬 상태를 사용하여 값의 변경을 관리한다.
  const [keywordLocalState, setKeywordLocalState] =
    React.useState(searchKeyword);
  const changeValueHandler = (value: string) => {
    onChangeValue && onChangeValue(value);
    setKeywordLocalState(value);
  };
  const submitHandler = () => {
    onEnter && onEnter();
    setSearchKeyword(keywordLocalState);
  };
  const clearHandler = () => {
    onClear && onClear();
    setKeywordLocalState('');
  };

  return (
    <TextFieldPrimitives.Root
      placeholder="검색어를 입력하세요"
      value={keywordLocalState}
      size={size}
      shape={shape}
      onChangeValue={changeValueHandler}
      onEnter={submitHandler}
      onClear={clearHandler}
    >
      <TextFieldPrimitives.InputContainer
        className={clsx(styles.SearchTextField, className)}
      >
        <SearchButton className={styles.icon} />
        <TextFieldPrimitives.TextInput />
        <TextFieldPrimitives.Meta>
          <TextFieldPrimitives.ClearButton />
        </TextFieldPrimitives.Meta>
      </TextFieldPrimitives.InputContainer>
    </TextFieldPrimitives.Root>
  );
}

export default SearchTextField;
