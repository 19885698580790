'use client';

import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useDocsFilterCreator } from '@/components/search/model/filters/docs-filter-creator';
import { useCurrentAppCode } from '../../../../../model/store';
import { useSearchableAppInfo } from '../../../../../hooks/use-search-app-info';
import {
  Primitives as SearchFieldPrimitives,
  PeriodField,
  IncludedKeywordField,
  TextField,
  RadioGroupField,
} from '../../filter-templates';
import { SelectFolderIdField } from './fields/docs-folder';
import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {}

export const DocsSearchFilter: React.ComponentType<SearchFilterCommonProps> = ({
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useDocsFilterCreator();
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFieldPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFieldPrimitives.Header title={appInfo.name} />
      <SearchFieldPrimitives.Container>
        <SelectFolderIdField />
        <TextField
          label={t('search.filter.common.field.CREATOR.label')}
          placeholder={t('search.filter.common.field.CREATOR.placeholder')}
          name="CREATOR"
        />
        <TextField
          label={t('search.filter.docs.field.DOC_NO.label')}
          placeholder={t('search.filter.docs.field.DOC_NO.placeholder')}
          name="DOC_NO"
        />
        <IncludedKeywordField.ContentType type="docs" />
        <RadioGroupField
          name="RESERVED_PERIOD"
          label={t('search.filter.docs.field.RESERVED_PERIOD.label')}
          options={[
            {
              label: t('search.filter.docs.field.RESERVED_PERIOD.option.ALL'),
              value: '',
            },
            {
              label: t(
                'search.filter.docs.field.RESERVED_PERIOD.option.PERMANENT',
              ),
              value: 'PERMANENT',
            },
            {
              label: t('search.filter.docs.field.RESERVED_PERIOD.option.1Y'),
              value: '1Y',
            },
            {
              label: t('search.filter.docs.field.RESERVED_PERIOD.option.3Y'),
              value: '3Y',
            },
            {
              label: t('search.filter.docs.field.RESERVED_PERIOD.option.5Y'),
              value: '5Y',
            },
            {
              label: t('search.filter.docs.field.RESERVED_PERIOD.option.10Y'),
              value: '10Y',
            },
          ]}
        />
        <PeriodField.RadioType name="PERIOD" />
        <SearchFieldPrimitives.Footer useFilterReset />
      </SearchFieldPrimitives.Container>
    </SearchFieldPrimitives.Root>
  );
};

export default DocsSearchFilter;
