import * as React from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { useBoardFilterCreator } from '@/components/search/model/filters/board-filter-creator';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useSearchableAppInfo } from '@/components/search/hooks/use-search-app-info';
import {
  Primitives as SearchFilterPrimitives,
  PeriodField,
  IncludedKeywordField,
  TextField,
} from '../../filter-templates';
import { SelectBoardField, SelectCommunityField } from './fields';

import type { SearchFilterCommonProps, TFilterValue } from '../../types';

export interface Props extends SearchFilterCommonProps {
  type?: 'board' | 'community';
}

const PERIOD_FIELD_NAME = 'PERIOD';
const CREATOR_FIELD_NAME = 'CREATOR';

export const BoardTypeSearchFilter: React.ComponentType<Props> = ({
  type = 'board',
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation('common');
  const createFilter = useBoardFilterCreator(type);
  const [appCode] = useCurrentAppCode();
  const appInfo = useSearchableAppInfo(appCode);

  if (!appInfo) {
    return null;
  }

  const submitHandler = (value: TFilterValue) => {
    const filter = createFilter(value);
    onSubmit && onSubmit(filter.create());
  };
  const cancelHandler = () => {
    onCancel && onCancel();
  };
  const validator = (value: TFilterValue) => {
    const filter = createFilter(value);
    return filter.validate();
  };

  return (
    <SearchFilterPrimitives.Root
      validator={validator}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    >
      <SearchFilterPrimitives.Header title={appInfo.name} />
      <SearchFilterPrimitives.Container>
        {type === 'board' && <SelectBoardField />}
        {type === 'community' && <SelectCommunityField />}
        <TextField
          label={t('search.filter.board.field.creator.label')}
          placeholder={t('search.filter.board.field.creator.placeholder')}
          name={CREATOR_FIELD_NAME}
        />
        <IncludedKeywordField.ContentType />
        <PeriodField.RadioType name={PERIOD_FIELD_NAME} />
        <SearchFilterPrimitives.Footer useFilterReset />
      </SearchFilterPrimitives.Container>
    </SearchFilterPrimitives.Root>
  );
};

export default BoardTypeSearchFilter;
