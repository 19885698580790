import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';
import { SelectField } from '../../../filter-templates';

export function SelectGradeField() {
  const { t } = useTranslation('common');
  const options = [
    {
      label: t('search.common.words.ALL'),
      value: '',
    },
    {
      label: t('search.filter.legalEducation.field.GRADE.option.Y'),
      value: 'Y',
    },
    {
      label: t('search.filter.legalEducation.field.GRADE.option.N'),
      value: 'N',
    },
  ];

  return (
    <SelectField.Simple
      label={t('search.filter.legalEducation.field.GRADE.label')}
      name="grade"
      options={options}
    />
  );
}

export default SelectGradeField;
