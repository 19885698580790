import {
  useSessionContext,
  type AccessibleAppCodeData,
} from '@dop-ui/react/features/authenticate';

/**
 * 통합 검색 가능한 앱 목록 반환(hook)
 * @returns
 */
export function useSearchableAppList() {
  const { me } = useSessionContext();
  const { accessibleAppCodeList } = me;
  return getSearchableAppList(accessibleAppCodeList);
}

/**
 * 통합 검색 가능한 앱 목록 반환
 * 현재 관련 속성이 없어서 인터페이스에 추가 예정이며, 이를 수정하기 용이하도록 별도의 함수로 분리하여 관리
 * @param accessibleAppCodeList
 * @returns
 */
function getSearchableAppList(accessibleAppCodeList: AccessibleAppCodeData[]) {
  return accessibleAppCodeList.filter(
    (appCode) =>
      appCode.portalType === 'EMPLOYEE' && 
      appCode.appCategory === 'PURCHASED' && 
      appCode.isSupportSearch,
  );
}
