/**
 * 임직원 포털 인하우스 앱 코드
 */
export const INHOUSE_EMP_APP_CODES = {
  MAIL: 'dop-employee-mail',
  CONTACT: 'dop-employee-contact',
  CALENDAR: 'dop-employee-calendar',
  APPROVAL: 'dop-employee-approval',
  /** @property 전사문서함 */
  APPR_COMPANY_FOLDER: 'dop-employee-appr-company-folder',
  BOARD: 'dop-employee-board',
  /** @property 문서관리 */
  DOCS: 'dop-employee-docs',
  COMMUNITY: 'dop-employee-community',
  REPORT: 'dop-employee-report',
  WORKS: 'dop-employee-works',
  TODO: 'dop-employee-todo',
  WEBFOLDER: 'dop-employee-webfolder',
  /** @property 예약 */
  ASSET: 'dop-employee-asset',
  /** @property 설문 */
  SURVEY: 'dop-employee-survey',
  /** @property 인사 */
  HR: 'dop-employee-hr',
  /** @property 급여 */
  PAYROLL: 'dop-employee-payroll',
  /** @property 고용전자계약 */
  ECONTRACT: 'dop-employee-econtract',
  /** @property 법정의무교육 */
  LEGAL_EDUCATION: 'dop-employee-legal-education',
  /** @property 경비 */
  EXPENSES: 'dop-employee-expenses',
  /** @property 차량운행일지 */
  VEHICLE_LOG: 'dop-employee-vehicle-log',
  /** @property 근태 */
  ATTEND: 'dop-employee-attend',
  /** @property 휴가 */
  LEAVE: 'dop-employee-leave',
  /** @property 복지 */
  WELFARE: 'dop-employee-welfare',
  /** @property Link+ */
  LINKPLUS: 'dop-employee-link-plus',
}

/**
 * 경영업무포털 인하우스 앱 코드
 */
export const INHOUSE_MNG_APP_CODES = {}
