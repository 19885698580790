import * as React from 'react';
import { clsx } from 'clsx';

import styles from './styles.module.css';

export interface Props {
  className?: string;
  label: string;
}

export function SearchFilterField({
  children,
  label,
  className,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={clsx(styles.SearchFilter__Field, className)}>
      <div className="label">{label}</div>
      <div className="field">{children}</div>
    </div>
  );
}

export default SearchFilterField;
