'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client';

import { Primitives as FilterTemplates } from '../../../../filter-templates';
import { SelectOwnerType } from './select-owner-type';
import { SelectDepartmentList } from './select-dept-list';
import styles from './styles.module.css';

const FIELD_NAME = 'ADDRESS_BOOK';
export const OWNER_TYPE = {
  USER: 'USER',
  COMPANY: 'COMPANY',
  DEPARTMENT: 'DEPARTMENT',
} as const;
export type OwnerType = (typeof OWNER_TYPE)[keyof typeof OWNER_TYPE];

interface FilterValueType {
  ownerType: OwnerType;
  deptId?: string;
}

export function SelectAddressBookField({ className }: { className?: string }) {
  const { t } = useTranslation('common');
  const { filterValue, setFilterValue } =
    FilterTemplates.useSearchFilterContext();
  const currentFilterValue = filterValue[FIELD_NAME] as
    | FilterValueType
    | undefined;
  const { ownerType = '', deptId = '' } = currentFilterValue || {};
  const changeOwnerTypeHandler = (value: string) => {
    if (value === '') {
      setFilterValue({ [FIELD_NAME]: null });
    } else {
      setFilterValue({
        [FIELD_NAME]: {
          ownerType: value,
        },
      });
    }
  };
  const changeDeptIdHandler = (value: string) => {
    setFilterValue({
      [FIELD_NAME]: {
        ownerType,
        deptId: value,
      },
    });
  };

  return (
    <FilterTemplates.Field
      label={t('search.filter.contact.field.OWNER_TYPE.label')}
      className={clsx(styles.SearchFilter__Contact__AddressBook, className)}
    >
      <div className={styles.selectgroup}>
        <SelectOwnerType value={ownerType} onChange={changeOwnerTypeHandler} />

        {ownerType === OWNER_TYPE.DEPARTMENT && (
          <SelectDepartmentList value={deptId} onChange={changeDeptIdHandler} />
        )}
      </div>
    </FilterTemplates.Field>
  );
}

export default SelectAddressBookField;
