import { Avatar, type AvatarProps } from '../../../../shared/ui/avatar';
import { getRandomColor } from '../../../../shared/lib/utils/color';

export interface Props
  extends Omit<AvatarProps, 'src' | 'alt' | 'backgroundColor'> {
  user: {
    id?: number;
    name: string;
    profileUrl?: string;
  };
}

export function UserAvatar({ user, ...props }: Props) {
  return (
    <Avatar
      src={user.profileUrl}
      alt={user.name}
      backgroundColor={user.id ? getRandomColor(user.id) : undefined}
      {...props}
    />
  );
}

export default UserAvatar;
