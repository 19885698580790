import { SEARCH_TYPES } from '../constants';
import type {
  TSearchType,
  TSearchableAppType,
  ISearchResultRespose,
  ISearchResultData,
  IRequestFilter,
} from '../types';

/**
 * 임직원 포털 > 경영서비스 공통 path resolver
 * @param path
 * @param type
 * @param params
 * @returns
 */
export function resolvePath(
  path: string,
  type: TSearchType,
  filter: IRequestFilter,
) {
  const { params = {} } = filter;
  const { stype, ...restParams } = params;
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  // 상세 검색이라 하더라도 초기에는 통합 검색 키워드 기반으로 결과를 보여줘야 하므로,
  // stype=simple이라고 명시적으로 들어오면 통합 검색으로 취급한다.
  const isIntegratdSearch =
    stype === 'simple' || type === SEARCH_TYPES.INTEGRATED;
  const searchTypePath = isIntegratdSearch ? '' : `/detail`;
  // offset은 style이 명시적으로 들어오더라도 상세검색으로 취급한다.
  const offset = type === SEARCH_TYPES.INTEGRATED ? '5' : '15';

  const queryString = new URLSearchParams({
    offset,
    ...restParams,
  }).toString();
  return `${normalizedPath}${searchTypePath}?${queryString}`;
}

/**
 * 임직원 포털 > 경영서비스 공통 response resolver
 * @param appInfo
 * @param resp
 * @returns
 */
export function resolveReponse(appInfo: TSearchableAppType, resp: unknown) {
  const respData = resp as ISearchResultRespose;
  // console.log('eaccResponseResolver: ', respData);
  return {
    code: appInfo.code,
    appName: appInfo.name,
    data: respData.data,
  } as ISearchResultData;
}
