import * as React from 'react';
import { clsx } from 'clsx';
import { Pagination } from '@dop-ui/react/shared/ui/pagination';
import { useCurrentAppCode } from '@/components/search/model/store';
import { useAppSearchRequest } from '@/components/search/hooks/use-app-search-request';
import { getSearchResultElement } from '../result-views';
import {
  NotFoundErrorTemplate,
  SystemErrorTemplate,
} from '../result-templates';

import styles from '../../styles.module.css';

export interface Props {
  onClickLink?: (url: string) => void;
}

export function AppSearchResult({ onClickLink }: Props) {
  const [appCode] = useCurrentAppCode();
  const [pageState, setPageState] = React.useState(0);
  const { data } = useAppSearchRequest({
    code: appCode,
    page: pageState,
  });

  const { data: resData, error } = data;

  if (error || !resData) {
    // 에러 페이지 처리 필요
    return <SystemErrorTemplate />;
  }

  const ResultView = getSearchResultElement(appCode);

  if (!ResultView) {
    return <SystemErrorTemplate />;
  }

  const {
    list,
    page: { total, totalPage },
  } = resData;

  // 검색 결과 없는 것에 대한 공통 처리 수행
  if (list.length === 0) {
    return <NotFoundErrorTemplate />;
  }

  return (
    <div className={clsx(styles.SearchResultView)}>
      <main className={styles.contents}>
        <ResultView data={data} onClickLink={onClickLink} />
      </main>
      {total > 0 && (
        <footer className={styles.footer}>
          <Pagination
            currentPage={pageState}
            onNextPage={setPageState}
            onPreviousPage={setPageState}
            onClickPage={setPageState}
            onFirstPage={() => setPageState(0)}
            onLastPage={() => {
              setPageState(totalPage - 1);
            }}
            totalPage={totalPage}
          />
        </footer>
      )}
    </div>
  );
}

export default AppSearchResult;
